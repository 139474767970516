/**
 * Ryan O'Dowd
 * 2022-03-13
 * © Copyright 2022 Oakwood Software Consulting, Inc. All Rights Reserved.
 */
import {
  ADD_COMPLETED_LESSON,
  ADD_TO_READING_HISTORY,
  ADD_TO_SUPER_SEARCH_HISTORY,
  BLOCK_USER,
  CLEAR_ALL_NEW_BADGES,
  CLEAR_MASQUERADE_AS,
  CLEAR_READING_HISTORY,
  CLEAR_SUPER_SEARCH_HISTORY,
  CLEAR_TEST_DRAFT,
  CLEAR_VERSES_CACHE,
  FOLLOW_USER,
  PUBLIC_USERS_SEARCHED,
  REMOVE_COMPLETED_LESSON,
  REMOVE_FROM_READING_HISTORY,
  REMOVE_FROM_SUPER_SEARCH_HISTORY,
  SET_ACTIVE_GOAL_ID,
  SET_ADMIN_ANALYTICS,
  SET_ALL_ASSIGNMENT_PEANUTS,
  SET_ALL_CLASSROOM_GOAL_METADATA,
  SET_ALL_PASSAGES_OF_THE_DAY,
  SET_ASSIGNMENT_PEANUTS,
  SET_BADGES,
  SET_BOOKS,
  SET_BOOK_NAMES,
  SET_BOOK_SORT_METHOD,
  SET_CHAPTERS,
  SET_CLASSROOMS,
  SET_CLASSROOM_GOAL_METADATA,
  SET_CLASSROOM_STUDENTS,
  SET_DAILY_REMINDER_TIME,
  SET_FRIENDS,
  SET_GOAL,
  SET_GOALS,
  SET_GOAL_PREVIEW,
  SET_GOAL_PROGRESS,
  SET_I18N,
  SET_I18N_NOTES,
  SET_I18N_UPDATED,
  SET_LAST_FETCH_VERSES_TIMESTAMP,
  SET_LAST_VIEWED_FOLLOWERS_TIMESTAMP,
  SET_LOCALES,
  SET_MASQUERADE_AS,
  SET_MINIGAMES,
  SET_MS_SPENT,
  SET_NEW_BADGES,
  SET_NEW_TRANSLATIONS_RETURN_VALUE,
  SET_NOTIFICATION_PREFERENCE,
  SET_NUM_LESSONS_TODAY,
  SET_ORGANIZATION,
  SET_ORGANIZATIONS,
  SET_ORGANIZATION_CAMPAIGNS,
  SET_PARTICIPANTS,
  SET_PARTICIPANT_CAMPAIGNS,
  SET_PASSAGE,
  SET_PASSAGE_OF_THE_DAY,
  SET_PASSAGE_OF_THE_DAY_NOTIFICATION_TIME,
  SET_PLEDGE,
  SET_PLEDGES,
  SET_PREFERRED_SCALE,
  SET_PREFERRED_TRANSLATION_ID,
  SET_PREFERRED_VERSE_SELECTION_METHOD,
  SET_PUBLIC_GOALS,
  SET_READING_BOOKMARK_CHAPTER_ID,
  SET_SCHEDULED_DAILY_GOALS_REMINDER_IDS,
  SET_SCHEDULED_PASSAGE_OF_THE_DAY_NOTIFICATION_IDS,
  SET_SCHEDULED_STREAK_REMINDER_IDS,
  SET_SCHOOLS,
  SET_SEVEN_DAYS_PEANUTS,
  SET_SHOW_BADGES_IN_READ,
  SET_STREAKS,
  SET_SUB_GOAL_REVIEW_PERCENTAGE,
  SET_SUB_GOAL_SELECTION,
  SET_TAGS,
  SET_TEST_DRAFT,
  SET_TRANSLATIONS,
  SET_USER_SETTINGS,
  SET_VERSES,
  SET_VERSES_LAST_UPDATED,
  SET_VERSES_REVIEWED,
  SET_VERSION_SUPPORT,
  SET_WRINKLES,
  SET_YEAR_IN_REVIEW_STATS,
  TOGGLE_MINIGAME_ACTIVE,
  UNBLOCK_USER,
  UNFOLLOW_USER,
  UPDATE_GOAL_SETTINGS,
  UPDATE_I18N_NOTES,
  UPDATE_VERSES,
} from './actions';
import {
  LOGOUT,
  SET_OAK_USER,
} from './common/actions';
import {
  chatMessages,
  feedbackChatDraftText,
  fetches,
  loadingStates,
  oakUser,
  settingsLightDarkMode,
  signInInfo,
  whatsNewLastReadTimestamp,
  whatsNewLastReadVersion,
  whatsNewNotes,
} from './common/reducers';
import Globals from './Globals';
import PlatformGlobals from './PlatformGlobals';
import {
  combineReducers,
} from 'redux';
import {
  format as dateFnsFormat,
} from 'date-fns';

function userSettings(state = {}, action) {
  switch (action.type) {
  case SET_OAK_USER: {
    if (!action.payload.user.settings) {
      return state;
    }
    return action.payload.user.settings;
  }
  case SET_PREFERRED_TRANSLATION_ID:
    return {...state, preferred_translation_id: action.preferredTranslationId};
  case SET_USER_SETTINGS:
    return action.payload.settings;
  default:
    return state;
  }
}

function peanuts(state = {}, action) {
  switch (action.type) {
  case SET_ALL_ASSIGNMENT_PEANUTS: {
    const peanutsByAssignment = {};
    Object.entries(action.payload.peanuts).forEach(([assignmentId, numPeanuts]) => {
      peanutsByAssignment[assignmentId] = +numPeanuts;
    });

    return peanutsByAssignment;
  }
  case SET_ASSIGNMENT_PEANUTS: {
    return {...state, [action.payload.assignment_id]: +action.payload.total_peanuts_earned};
  }
  default:
    return state;
  }
}

function peanutsSevenDays(state = {}, action) {
  switch (action.type) {
  case SET_SEVEN_DAYS_PEANUTS: {
    return {
      ...state,
      [action.payload.user_id]: {
        peanuts: action.payload.peanuts_by_day,
        passedVerses: action.payload.passed_verses_by_day,
      },
    };
  }
  default:
    return state;
  }
}

function allPassagesOfTheDay(state = {}, action) {
  switch (action.type) {
  case SET_ALL_PASSAGES_OF_THE_DAY: {
    const newState = {};
    action.payload.passages_of_the_day.forEach((p) => {
      newState[p.date_active] = p;
    });
    return newState;
  }
  default:
    return state;
  }
}

function passageOfTheDay(state = {}, action) {
  switch (action.type) {
  case SET_PASSAGE_OF_THE_DAY: {
    return {
      passage: action.payload.passage_of_the_day,
      isComplete: action.payload.complete,
    };
  }
  default:
    return state;
  }
}

/* @TODO:
// @NOTE: nested settings reducer has been flattened to several reducers.
// users will get white screen if a component (mapStateToProps) is assuming
// that a key in initialSettings is in redux; the old store is persisted, so
// changing dailyReminder to periodicReminder for example, will cause .enabled
// to be called on undefined in mapStateToProps...very sneaky bug!! nested
// reducers is probably not a great idea...
function settingsPeriodicReminderIsEnabled(state = true, action) {
  switch (action.type) {
  case SET_PERIODIC_REMINDER_ENABLED:
    return action.isEnabled;
  default:
    return state;
  }
}
*/

function settingsActiveGoalId(state = null, action) {
  switch (action.type) {
  case SET_ACTIVE_GOAL_ID:
    return action.goalId;
  default:
    return state;
  }
}

function versesLastUpdatedTimestamp(state = Date.now(), action) {
  switch (action.type) {
  case SET_VERSES_LAST_UPDATED:
    return Date.now();
  default:
    return state;
  }
}

// @NOTE: because channelIds are available only on mobile, need the null check
function settingsNotificationPreferences(state = {
  [PlatformGlobals.channelIds?.STREAK_REMINDER]: 1,
  [PlatformGlobals.channelIds?.DAILY_GOALS_REMINDER]: 1,
  [PlatformGlobals.channelIds?.WHATS_NEW]: 1,
  [PlatformGlobals.channelIds?.PASSAGE_OF_THE_DAY]: 1,
}, action) {
  switch (action.type) {
  case SET_NOTIFICATION_PREFERENCE:
    return {...state, [action.channelId]: action.isOn};
  default:
    return state;
  }
}

function scheduledStreakReminderIds(state = [], action) {
  switch (action.type) {
  case SET_SCHEDULED_STREAK_REMINDER_IDS:
    return [...action.ids];
  default:
    return state;
  }
}

function scheduledDailyGoalsReminderIds(state = [], action) {
  switch (action.type) {
  case SET_SCHEDULED_DAILY_GOALS_REMINDER_IDS:
    return [...action.ids];
  default:
    return state;
  }
}

function scheduledPassageOfTheDayNotificationIds(state = [], action) {
  switch (action.type) {
  case SET_SCHEDULED_PASSAGE_OF_THE_DAY_NOTIFICATION_IDS:
    return [...action.ids];
  default:
    return state;
  }
}

function settingsDailyReminderTime(state = dateFnsFormat(new Date(), 'HH:mm'), action) { // @TODO: am/pm?
  switch (action.type) {
  case SET_DAILY_REMINDER_TIME:
    return action.formattedTime;
  default:
    return state;
  }
}

function settingsPassageOfTheDayNotificationTime(state = '08:00', action) { // @TODO: am/pm?
  switch (action.type) {
  case SET_PASSAGE_OF_THE_DAY_NOTIFICATION_TIME:
    return action.formattedTime;
  default:
    return state;
  }
}

function books(state = [], action) { // @TODO: this should probably be an object instead?
  switch (action.type) {
  case SET_BOOKS: {
    return action.payload.books;
  }
  default:
    return state;
  }
}

function minigames(state = {}, action) { // @TODO: this should probably be an object instead?
  switch (action.type) {
  case SET_MINIGAMES: {
    const newState = {};
    action.payload.minigames.forEach((m) => {
      newState[m.id] = m;
    });
    return newState;
  }
  default:
    return state;
  }
}

function bookNames(state = {}, action) {
  switch (action.type) {
  case SET_BOOK_NAMES: {
    const newState = {};
    action.payload.book_names.forEach((b) => {
      if (!Object.keys(newState).includes(`${b.translation_id}`)) {
        newState[b.translation_id] = {};
      }
      newState[b.translation_id][b.book_id] = {
        name: b.name,
      };
    });

    return newState;
  }
  default:
    return state;
  }
}

function chapters(state = [], action) {
  switch (action.type) {
  case SET_CHAPTERS: {
    return action.payload.chapters;
  }
  default:
    return state;
  }
}

// @TODO: draw out flatter reducers for translations/books/chapters/verses
// @TODO: reducer for translations...
// @TODO: reducer for chapters...
// @TODO: reducer for verses keyed by verse_id?

function completedLessons(state = {}, action) {
  switch (action.type) {
  case ADD_COMPLETED_LESSON: {
    return {...state, [action.lesson.uuid]: action.lesson};
  }
  case REMOVE_COMPLETED_LESSON: {
    const newState = {...state};
    delete newState[action.uuid];
    return newState;
  }
  default:
    return state;
  }
}

function translations(state = [], action) {
  switch (action.type) {
  case SET_TRANSLATIONS: {
    return action.payload.translations;
  }
  default:
    return state;
  }
}

function goals(state = {}, action) {
  switch (action.type) {
  case SET_CLASSROOMS: {
    if (!action.payload.goals) {
      return state;
    }
  }
  // eslint-disable-next-line no-fallthrough
  case SET_ALL_CLASSROOM_GOAL_METADATA:
  case SET_GOALS: {
    const newState = {};
    action.payload.goals.forEach((g) => {
      newState[g.id] = g;
    });
    return newState;
  }
  case SET_CLASSROOM_GOAL_METADATA:
  case SET_GOAL: {
    return {...state, [action.payload.goal.id]: action.payload.goal};
  }
  default:
    return state;
  }
}

function goalPreviews(state = {}, action) {
  switch (action.type) {
  case SET_GOAL_PREVIEW: {
    const newState = {...state};
    newState[action.payload.goal_preview_id] = action.payload.goal_preview;
    return newState;
  }
  default:
    return state;
  }
}

function subGoals(state = {}, action) { // @TODO: remove in this version?
  switch (action.type) {
  case SET_GOALS: {
    const newState = {};
    action.payload.sub_goals.forEach((sg) => {
      newState[sg.goal_id] = newState[sg.goal_id] ?? [];
      newState[sg.goal_id].push(sg);
    });
    return newState;
  }
  default:
    return state;
  }
}

function subGoalSelections(state = {}, action) {
  switch (action.type) {
  case SET_SUB_GOAL_SELECTION: {
    const newState = {...state};
    newState[action.goalId] = newState[action.goalId] || {};
    newState[action.goalId][action.dataType] = action.selectedSubGoalIds;
    return newState;
  }
  case SET_SUB_GOAL_REVIEW_PERCENTAGE: {
    const newState = {...state};
    newState[action.goalId] = newState[action.goalId] || {};
    newState[action.goalId][action.dataType] = action.reviewPercentage;
    return newState;
  }
  default:
    return state;
  }
}

function locales(state = {}, action) {
  switch (action.type) {
  case SET_LOCALES: {
    const newState = {};
    action.payload.locales.forEach((l) => {
      newState[l.code] = l;
    });
    return newState;
  }
  default:
    return state;
  }
}

function goalSettings(state = {
  difficulty: 'easy',
  dailyPeanuts: 25,
  dailyLessons: 4,
}, action) {
  switch (action.type) {
  case UPDATE_GOAL_SETTINGS:
    return {...state, ...action.updatedSettings};
  default:
    return state;
  }
}

function inactiveMinigames(state = [1], action) {
  switch (action.type) {
  case TOGGLE_MINIGAME_ACTIVE: {
    if (state.includes(action.minigameId)) {
      return state.filter((mid) => mid !== action.minigameId);
    }
    return [...state, action.minigameId];
  }
  default:
    return state;
  }
}

function settingsBookSortMethod(state = 'bibleOrder', action) {
  switch (action.type) {
  case SET_BOOK_SORT_METHOD:
    return action.sortMethod;
  default:
    return state;
  }
}

function settingsShowBadgesInRead(state = false, action) {
  switch (action.type) {
  case SET_SHOW_BADGES_IN_READ:
    return action.showBadgesInRead;
  default:
    return state;
  }
}

function settingsPreferredVerseSelectionMethod(state = 'text', action) { // @TODO: replace with db table
  switch (action.type) {
  case SET_PREFERRED_VERSE_SELECTION_METHOD:
    return action.preferredVerseSelectionMethod;
  default:
    return state;
  }
}

function settingsPreferredScale(state = Globals.isMobile ? 'medium' : 'large', action) { // @TODO: can also use `const {fontScale} = useWindowDimensions();` to get default // @TODO: repace with db table
  switch (action.type) {
  case SET_PREFERRED_SCALE:
    return action.preferredScale;
  default:
    return state;
  }
}

function streaks(state = {data: [], current_streak_num: 0}, action) { // @TODO: does current_streak_end_date need to have a default value?
  switch (action.type) {
  case SET_STREAKS:
    return {
      data: action.payload.streaks,
      current_streak_num: action.payload.current_streak_num,
      current_streak_end_date: action.payload.current_streak_end_date,
    };
  default:
    return state;
  }
}

function badges(state = [], action) {
  switch (action.type) {
  case SET_BADGES:
    return action.payload.badges;
  default:
    return state;
  }
}

function newBadges(state = [], action) {
  switch (action.type) {
  case SET_NEW_BADGES:
    return action.payload.new_badges;
  case CLEAR_ALL_NEW_BADGES:
    return [];
  default:
    return state;
  }
}

function participantCampaigns(state = [], action) {
  switch (action.type) {
  case SET_PARTICIPANT_CAMPAIGNS:
    return action.payload.campaigns;
  default:
    return state;
  }
}

function organizationCampaigns(state = [], action) {
  switch (action.type) {
  case SET_ORGANIZATION_CAMPAIGNS:
    return action.payload.campaigns;
  default:
    return state;
  }
}

function numLessonsToday(state = 0, action) {
  switch (action.type) {
  case SET_NUM_LESSONS_TODAY: {
    const ret = +action.payload.num_lessons;
    return isNaN(ret) ? 0 : ret;
  }
  default:
    return state;
  }
}

// @MARK: web/landing page
function webStatsNumVersesReviewed(state = 794975, action) { // @TODO: update this default state every...few months? just so that if the network call fails, the numbers won't look as bad (and will be more accurate)
  switch (action.type) {
  case SET_VERSES_REVIEWED:
    return action.payload.num_verses_reviewed;
  default:
    return state;
  }
}

function webStatsMsSpent(state = 14962582652, action) { // @TODO: update this default state every...few months? just so that if the network call fails, the numbers won't look as bad (and will be more accurate)
  switch (action.type) {
  case SET_MS_SPENT:
    return action.payload.ms_spent;
  default:
    return state;
  }
}

function adminAnalytics(state = {}, action) {
  switch (action.type) {
  case SET_ADMIN_ANALYTICS:
    return action.payload.admin_analytics;
  default:
    return state;
  }
}

function organizations(state = {}, action) {
  switch (action.type) {
  case SET_ORGANIZATION: {
    return {...state, [action.payload.organization.slug]: action.payload.organization};
  }
  case SET_ORGANIZATIONS: {
    const newState = {...state};
    action.payload.organizations.forEach((o) => {
      newState[o.slug] = o;
    });
    return newState;
  }
  default:
    return state;
  }
}

function pledges(state = {}, action) {
  switch (action.type) {
  case SET_PLEDGES: {
    return {...state, [action.payload.campaign_id]: action.payload.pledges};
  }
  case SET_PLEDGE: {
    return {...state, [action.payload.pledge.campaign_id]: [action.payload.pledge]};
  }
  default:
    return state;
  }
}

function participants(state = {}, action) {
  switch (action.type) {
  case SET_PARTICIPANTS: {
    return {...state, [action.payload.campaign_id]: action.payload.participants};
  }
  default:
    return state;
  }
}

function usersFollowing(state = [], action) {
  switch (action.type) {
  case SET_FRIENDS:
    return action.payload.following;
  case FOLLOW_USER:
    return [...state, action.payload.user || action.payload.student]; // @TODO: shouldn't be student, but this is what the back end is returning right now
  case UNFOLLOW_USER:
    return state.filter((u) => u.id !== action.payload.user_id);
  default:
    return state;
  }
}

function publicUsersSearched(state = [], action) {
  switch (action.type) {
  case PUBLIC_USERS_SEARCHED:
    return [...state.filter((u) => u.id !== (action.payload.user || action.payload.student).id), action.payload.user || action.payload.student]; // @TODO: shouldn't be student, but this is what the back end is returning right now // @TODO: can't imagine this reducer will ever get too big, but it would technically be better to ignore the filter and just put the new user at the beginning of the list since `find` is O(n) in the worst case and starts at the beginning of the list (this is O(n) + O(n))
  default:
    return state;
  }
}

function userFollowers(state = [], action) {
  switch (action.type) {
  case SET_FRIENDS:
    return action.payload.followers;
  default:
    return state;
  }
}

function blockedUserIds(state = [], action) {
  switch (action.type) {
  case SET_FRIENDS:
    return action.payload.blocked_users;
  case BLOCK_USER:
    return [...state, action.payload.user_id];
  case UNBLOCK_USER:
    return state.filter((uid) => uid !== action.payload.user_id);
  default:
    return state;
  }
}

function lastViewedFollowersTimestamp(state = 0, action) {
  switch (action.type) {
  case SET_LAST_VIEWED_FOLLOWERS_TIMESTAMP:
    return action.timestamp;
  default:
    return state;
  }
}

function wrinkles(state = {}, action) {
  switch (action.type) {
  case SET_WRINKLES: {
    const newState = {};
    action.payload.wrinkles.forEach((w) => {
      if (!newState[w.verse_api_id]) {
        newState[w.verse_api_id] = [];
      }
      newState[w.verse_api_id].push(w); // @NOTE: this is to allow one wrinkle per translation...it may be the case that three passages with three different translations are here...one wrinkled, one in progress, and one unstarted...need to make sure all of these cases are accounted for
    });

    return newState;
  }
  default:
    return state;
  }
}

function testDrafts(state = {}, action) {
  switch (action.type) {
  case SET_TEST_DRAFT: {
    return {...state, [action.passageId]: {text: action.text, msSpent: action.msSpent}};
  }
  case CLEAR_TEST_DRAFT: {
    const {[action.passageId]: _, ...newState} = state; /* eslint-disable-line no-unused-vars */
    return newState;
  }
  default:
    return state;
  }
}

// @MARK: discover goals
function publicGoals(state = [], action) {
  switch (action.type) {
  case SET_PUBLIC_GOALS:
    return action.payload.public_goals;
  default:
    return state;
  }
}

function verses(state = {}, action) {
  switch (action.type) {
  case SET_VERSES:
  case UPDATE_VERSES: {
    const newState = {...state};
    action.payload.verses.forEach((verse) => { // @TODO: simplify this...merge currState with payload without having to loop over return value
      const translationId = verse.translation_id;
      const bookId = verse.book_id;
      const chapterNumber = verse.chapter_number;
      const verseNumber = verse.verse_number;

      if (!(action.type === UPDATE_VERSES && [undefined, null].includes(newState?.[translationId]?.books?.[bookId]?.chapters?.[chapterNumber]?.verses?.[verseNumber]))) {
        // only set verse if this is a new one fetched from the back end or an update of an existing verse
        newState[translationId] = {...(newState[translationId] || {books: {}})};
        newState[translationId].books[bookId] = {...(newState[translationId].books[bookId] || {chapters: {}})};
        newState[translationId].books[bookId].chapters[chapterNumber] = {
          verses: {
            ...(newState[translationId].books[bookId].chapters[chapterNumber]?.verses || {}),
            [verseNumber]: {
              verseId: verse.verse_id,
              verseApiId: verse.verse_api_id,
              verseText: verse.verse_text,
              heading: verse.heading,
              footnotes: verse.footnotes,
            },
          },
        };
      }
    });

    return newState;
  }
  case CLEAR_VERSES_CACHE: {
    const {[action.translationId]: _, ...newState} = {...state};

    return newState;
  }
  default:
    return state;
  }
}

function i18n(state = {}, action) {
  switch (action.type) {
  case SET_I18N:
    return {
      ...state,
      [action.payload.locale_code]: {
        lastUpdated: Date.now(),
        i18n: action.payload.i18n,
      },
    };
  default:
    return state;
  }
}

function i18nNotes(state = {}, action) {
  switch (action.type) {
  case SET_I18N_NOTES: {
    const newState = {};
    action.payload.i18n_notes.forEach((n) => {
      if (!newState[n.i18n_id]) {
        newState[n.i18n_id] = [];
      }
      newState[n.i18n_id].push(n);
    });

    return newState;
  }
  case UPDATE_I18N_NOTES: {
    const newState = {...state};
    const newNote = action.payload.new_note;
    if (!newState[newNote.i18n_id]) {
      newState[newNote.i18n_id] = [];
    }
    newState[newNote.i18n_id].push(newNote);

    return newState;
  }
  default:
    return state;
  }
}

function newTranslationsReturnValue(state = '', action) { // @TODO: probably doesn't need to be in redux
  switch (action.type) {
  case SET_NEW_TRANSLATIONS_RETURN_VALUE:
    return action.payload.message;
  default:
    return state;
  }
}

function i18nUpdatedTimestamp(state = Date.now(), action) { // @TODO: probably doesn't need to be in redux
  switch (action.type) {
  case SET_I18N_UPDATED:
    return Date.now();
  default:
    return state;
  }
}

function readingBookmarkChapterId(state = 1, action) {
  switch (action.type) {
  case SET_READING_BOOKMARK_CHAPTER_ID:
    return action.chapterId;
  default:
    return state;
  }
}

function yearInReviewStats(state = {}, action) {
  switch (action.type) {
  case SET_YEAR_IN_REVIEW_STATS: {
    return {...state, [action.payload.year]: action.payload.stats};
  }
  default:
    return state;
  }
}

function lastFetchVersesTimestamp(state = 0, action) {
  switch (action.type) {
  case SET_LAST_FETCH_VERSES_TIMESTAMP: {
    return action.timestamp;
  }
  default:
    return state;
  }
}

function readingHistory(state = [], action) {
  switch (action.type) {
  case ADD_TO_READING_HISTORY: {
    return [{chapterId: action.chapterId, lastViewedTimestamp: Date.now()}, ...state.filter((c) => c.chapterId !== action.chapterId)];// @TODO: trim to be no more than 10 most-recent results (or based on time?)
  }
  case REMOVE_FROM_READING_HISTORY: {
    return state.filter((c) => c.chapterId !== action.chapterId);
  }
  case CLEAR_READING_HISTORY: {
    return [];
  }
  default:
    return state;
  }
}

function superSearchHistory(state = [], action) {
  switch (action.type) {
  case ADD_TO_SUPER_SEARCH_HISTORY: {
    return [{searchText: action.searchText, lastSearchedTimestamp: Date.now()}, ...state.filter((c) => c.searchText !== action.searchText)];// @TODO: trim to be no more than 10 most-recent results (or based on time?)
  }
  case REMOVE_FROM_SUPER_SEARCH_HISTORY: {
    return state.filter((c) => c.searchText !== action.searchText);
  }
  case CLEAR_SUPER_SEARCH_HISTORY: {
    return [];
  }
  default:
    return state;
  }
}

function classrooms(state = {}, action) {
  switch (action.type) {
  case SET_CLASSROOMS: {
    const newState = {};
    action.payload.classrooms.forEach((c) => {
      newState[c.id] = c;
    });
    return newState;
  }
  /* @TODO:
  case ADD_CLASSROOMS: {
    const newState = {...state};
    action.payload.classrooms.forEach((c) => {
      newState[c.id] = c;
    });
    return newState;
  }
  */
  default:
    return state;
  }
}

function classroomGoalMetadata(state = {}, action) {
  switch (action.type) {
  case SET_ALL_CLASSROOM_GOAL_METADATA: {
    const newState = {};
    action.payload.all_classroom_goal_metadata.forEach((cg) => {
      newState[cg.id] = cg;
    });
    return newState;
  }
  case SET_CLASSROOM_GOAL_METADATA: {
    return {
      ...state,
      [action.payload.classroom_goal_metadata.id]: action.payload.classroom_goal_metadata,
    };
  }
  default:
    return state;
  }
}

function classroomStudents(state = {}, action) {
  switch (action.type) {
  case SET_CLASSROOM_STUDENTS: {
    return {
      ...state,
      [action.payload.classroom_id]: action.payload.classroom_students,
    };
  }
  default:
    return state;
  }
}

function passages(state = {}, action) {
  switch (action.type) {
  case SET_ALL_CLASSROOM_GOAL_METADATA:
  case SET_GOALS: {
    const newState = {...state};
    action.payload.goals.forEach((g) => {
      g.modules.forEach((m) => {
        m.assignments.forEach((a) => {
          const p = a.assignment_object?.passage;
          if (p) {
            newState[p.id] = p;
          }
        });
      });
    });
    return newState;
  }
  case SET_GOAL: {
    const newState = {...state};
    action.payload.goal.modules.forEach((m) => {
      m.assignments.forEach((a) => {
        const p = a.assignment_object?.passage;
        if (p) {
          newState[p.id] = p;
        }
      });
    });
    return newState;
  }
  case SET_PASSAGE: {
    return {
      ...state,
      [action.payload.passage.id]: action.payload.passage,
    };
  }
  default:
    return state;
  }
}

function versionSupport(state = {minimum_supported_version: Globals.appVersionNumber}, action) {
  switch (action.type) {
  case SET_VERSION_SUPPORT: {
    return action.payload.version_support;
  }
  default:
    return state;
  }
}

function tags(state = {}, action) {
  switch (action.type) {
  case SET_CLASSROOM_GOAL_METADATA:
  case SET_TAGS: {
    if (!action.payload.tags) {
      // SET_CLASSROOM_GOAL_METADATA only sometimes has tags
      return state;
    }
    const newState = {};
    action.payload.tags.forEach((tag) => {
      newState[tag.id] = tag;
    });
    return newState;
  }
  default:
    return state;
  }
}

function schools(state = [], action) {
  switch (action.type) {
  case SET_SCHOOLS: {
    return action.payload.schools;
  }
  default:
    return state;
  }
}

function goalProgress(state = {}, action) {
  switch (action.type) {
  case SET_GOAL_PROGRESS: {
    return {
      ...state,
      [action.payload.goal_id]: action.payload.progress,
    };
  }
  default:
    return state;
  }
}

function userMasqueradingAs(state = null, action) {
  switch (action.type) {
  case SET_MASQUERADE_AS: {
    if (Globals.platform === 'web') {
      sessionStorage.setItem('masqueradeAsUserId', action.payload.masquerade_as_user.id);
    }
    return action.payload.masquerade_as_user;
  }
  case CLEAR_MASQUERADE_AS: {
    if (Globals.platform === 'web') {
      sessionStorage.removeItem('masqueradeAsUserId');
    }
    return null;
  }
  default:
    return state;
  }
}

const appReducer = combineReducers({
  adminAnalytics,
  allPassagesOfTheDay,
  badges,
  blockedUserIds,
  bookNames,
  books,
  chapters,
  chatMessages,
  classroomGoalMetadata,
  classroomStudents,
  classrooms,
  completedLessons,
  feedbackChatDraftText,
  fetches,
  goalPreviews,
  goalProgress,
  goalSettings,
  goals,
  i18n,
  i18nNotes,
  i18nUpdatedTimestamp,
  inactiveMinigames,
  lastFetchVersesTimestamp,
  lastViewedFollowersTimestamp,
  loadingStates,
  locales,
  minigames,
  newBadges,
  newTranslationsReturnValue,
  numLessonsToday,
  oakUser,
  organizationCampaigns,
  organizations,
  participantCampaigns,
  participants,
  passageOfTheDay,
  passages,
  peanuts,
  peanutsSevenDays,
  pledges,
  publicGoals,
  readingBookmarkChapterId,
  readingHistory,
  scheduledDailyGoalsReminderIds,
  scheduledPassageOfTheDayNotificationIds,
  scheduledStreakReminderIds,
  schools,
  settingsActiveGoalId,
  settingsBookSortMethod,
  settingsDailyReminderTime,
  settingsLightDarkMode,
  settingsNotificationPreferences,
  settingsPassageOfTheDayNotificationTime,
  settingsPreferredScale,
  settingsPreferredVerseSelectionMethod,
  settingsShowBadgesInRead,
  signInInfo,
  streaks,
  subGoalSelections,
  subGoals,
  superSearchHistory,
  tags,
  testDrafts,
  translations,
  userFollowers,
  usersFollowing,
  userMasqueradingAs,
  userSettings,
  publicUsersSearched,
  verses,
  versesLastUpdatedTimestamp,
  versionSupport,
  webStatsMsSpent,
  webStatsNumVersesReviewed,
  whatsNewLastReadTimestamp,
  whatsNewLastReadVersion,
  whatsNewNotes,
  wrinkles,
  yearInReviewStats,
});

const rootReducer = (state, action) => {
  if (action.type === LOGOUT) {
    if (Globals.platform === 'web') {
      window.localStorage.removeItem('emailForSignIn');
      window.localStorage.removeItem('firstNameForSignIn');
      window.localStorage.removeItem('lastNameForSignIn');
    }
    state = {
      i18n: state.i18n,
      locales: state.locales,
      webStatsMsSpent: state.webStatsMsSpent,
      webStatsNumVersesReviewed: state.webStatsNumVersesReviewed,
      whatsNewNotes: state.whatsNewNotes,
    };
  }

  return appReducer(state, action);
};

export default rootReducer;
