// @TODO: i18n
/* @TODO: {
  add section for quizzing
  add section for spritz (but rename it)
} */
/**
 * Ryan O'Dowd
 * 2021-07-05
 * © Copyright 2021 Oakwood Software Consulting, Inc. All Rights Reserved.
 */
import {
  DISCOVER_ROUTE,
  FEEDBACK_CHAT_ROUTE,
  FRIENDS_ROUTE,
  HELP_ROUTE,
  LINK_ACCOUNT_ROUTE,
  PROFILE_ROUTE,
  SETTINGS_ROUTE,
} from '../../actions';
import Globals from '../../Globals';
import React from 'react';
import getStyles from './styles';
import oakGlobalObject from '../../common/utilities/oakGlobalObject';

const styles = Globals.platform === 'web' ? getStyles : getStyles();

// @TODO: images can be added to a lot of these (animated gifs) to make it easier to understand (host these on wrink.ly, though, so that the bundle size of the app doesn't bloat...and make sure there are two sets--one for android, and one for ios
// @TODO: add help docs for partners (only visible if they're logged in, and put at the top of the docs...actually, make them tab nav)
// @TODO: add a attribute on questions that should be available on the tips widgets
const faqs = (navigation, isAnonymousUser, oakUser, Linking, Platform, View, PText, SpanText, TouchableOpacity) => oakGlobalObject({// @TODO: does isAnonymousUser need to be passed in?
  sections: [
    {
      title: 'Getting Started',
      data: [
        {
          question: 'The basics',
          answer: (
            <View>
              <PText style={styles.paragraph}><SpanText style={styles.bold}>Goals</SpanText> are collections of <SpanText style={styles.bold}>Passages</SpanText>.  Complete <SpanText style={styles.bold}>Lessons</SpanText> to learn verses and earn <SpanText style={styles.bold}>Peanuts</SpanText>.  Keep your <SpanText style={styles.bold}>Streak</SpanText> alive by earning <SpanText style={styles.bold}>Peanuts</SpanText> everyday.  When you have learned a verse, take a <SpanText style={styles.bold}>Test</SpanText> to earn <SpanText style={styles.bold}>Wrinkles</SpanText>.</PText>
            </View>
          ),
        },
        {
          question: 'Terminology', // @TODO: this could/should probably be combined with the "what are X?" questions?
          answer: (
            <View>
              <PText style={styles.paragraph}><SpanText style={styles.bold}>Goals: </SpanText>{"Comprised of one or more passages that you'd like to memorize, Goals are created to keep your verses organized."}</PText>
              <PText style={styles.paragraph}><SpanText style={styles.bold}>Passages: </SpanText>A passage is one or more verses (up to one chapter of the Bible).  Passages can be added to Goals.</PText>
              <PText style={styles.paragraph}><SpanText style={styles.bold}>Peanuts: </SpanText>{"Peanuts are awarded at the end of most lessons based on how many questions you answered correctly, the difficulty settings, and other variables.  They indicate how much you've been practicing."}</PText>
              <PText style={styles.paragraph}><SpanText style={styles.bold}>Wrinkles: </SpanText>{"A Wrinkle is another name for a memorized verse.  While Peanuts indicate how much practice you're getting, Wrinkles reflect the level of mastery of the Passages.  Wrinkles are earned after three successful tests and can continue to be leveled up after they are earned.  Each level up requires a longer waiting period between successful attempts to aid in long-term retention of the Passages."}</PText>
            </View>
          ),
          // @TODO: images
        },
        {
          question: 'Screens',
          answer: (
            <View>
              <PText style={styles.paragraph}><SpanText style={styles.bold}>Home: </SpanText>{"The main screen when you open Wrinkly has widgets for tracking progress and managing your goals.  Any tools that aren't on the Home screen should be accessible in the Side Menu."}</PText>
              <PText style={styles.paragraph}><SpanText style={styles.bold}>Lesson: </SpanText>Practice verses by playing games on the Lessons page.  Lessons can be started from any Passage.</PText>
              <PText style={styles.paragraph}><SpanText style={styles.bold}>Test: </SpanText>When you have learned a verse, take tests to earn and level up wrinkles.</PText>
              <PText style={styles.paragraph}><SpanText style={styles.bold}>Read: </SpanText>See memory verses in context, read any Bible passage, compare translations, and more on the Read page.</PText>
              <PText style={styles.paragraph}><SpanText style={styles.bold}>Discover: </SpanText>In addition to creating your own goals, you can also search for goals by topic or group.</PText>
              <PText style={styles.paragraph}><SpanText style={styles.bold}>Feedback Chat: </SpanText>Have a question, a feature request, or other feedback?  Messages can be sent and received here.</PText>
              <PText style={styles.paragraph}><SpanText style={styles.bold}>Settings: </SpanText>Your notification preferences and other settings like preferred translation and light/dark mode can be managed on this screen.</PText>
              <PText style={styles.paragraph}><SpanText style={styles.bold}>Fundraising and Groups: </SpanText>Part of a school, church, or organization that uses Wrinkly?  Fundraising campaigns and classrooms can be joined on these screens.</PText>
              <PText style={styles.paragraph}><SpanText style={styles.bold}>{"What's New: "}</SpanText>Learn about recent updates to Wrinkly.  New features and enhancements will be listed here.</PText>
              <PText style={styles.paragraph}><SpanText style={styles.bold}>Help: </SpanText>{"This is the page you're currently on.  Find information about common features and learn tips and tricks to make the most out of your experience with Wrinkly."}</PText>
              {isAnonymousUser && <PText style={styles.paragraph}><SpanText style={styles.bold}>Link Account: </SpanText>If you signed up with a guest account, your data will not be synced across devices.  To keep your progress, you can link your Wrinkly account to another account (Apple, Google, Facebook, or your email address).  This will allow you to recover your data if you lose your phone or want to sign in to Wrinkly on a new device.</PText>}
            </View>
          ),
          // @TODO: images
        },
      ],
    },
    {
      title: 'Goals',
      data: [
        {
          question: 'How do I create Goals?',
          answer: (
            <View>
              <PText style={styles.paragraph}>{'From the Side Drawer, press "All goals" and then the plus button in the bottom right to create a new goal.  Add a name and passages to get started!'}</PText>
              <PText style={styles.paragraph}>Goals can also be added from the Discover page.  Here you can find topical or group goals.</PText>
            </View>
          ),
          // @TODO: images
        },
        {
          question: 'How many goals should I create?  And how many passages should they have?',
          answer: (
            <View>
              <PText style={styles.paragraph}>{"How many active Goals you have or how many Passages to add is totally up to you and what you're hoping to get out of Wrinkly.  We recommend grouping similar Passages into the same Goal so that you're learning all of them at the same time, but you can create them however you learn best."}</PText>
              <PText style={styles.paragraph}>{"We recommend creating Goals with 1-5 Passages depending on how many verses each Passage has.  There's no magic number here, but smaller Goals tend to be more approachable, and when they're completed, you'll still be prompted to review what you've mastered while you move on to new Goals."}</PText>
            </View>
          ),
          // @TODO: images
        },
      ],
    },
    {
      title: 'Practice',
      data: [
        {
          question: 'What are the different types of practice?',
          answer: (
            <View>
              <PText style={styles.paragraph}>Changing the difficulty settings may change the number of verses displayed, how many clues are provide, etc.</PText>
              <PText style={styles.paragraph}><SpanText style={styles.bold}>Fill in the Blanks: </SpanText>Some of the words have been removed from the verse.  The missing words are scrambled in a word bank below and need to be put back in the correct order.</PText>
              <PText style={styles.paragraph}><SpanText style={styles.bold}>Order the Verses: </SpanText>Several verses are displayed on the screen in a random order.  Your goal is to rearrange the verses to put them back in the proper order.  (This game is available only for Passages with multiple verses.)</PText>
              <PText style={styles.paragraph}><SpanText style={styles.bold}>Quotes: </SpanText>Select the reference that matches the given verse.</PText>
              <PText style={styles.paragraph}><SpanText style={styles.bold}>First Letters: </SpanText>In this game, some of the words are missing all but the first few letters.  Finish the incomplete words to advance.</PText>
              {/* @TODO:
              <PText style={styles.paragraph}><SpanText style={styles.bold}>Attribution: </SpanText>{"If you're working on a Passage that contains a lot of dialog, this games helps you to remember who said what (and how).  Match the attribution (e.g. \"He said\", \"Jesus answered\", \"they replied\") with the dialog quote"}</PText>
              */}
            </View>
          ),
          // @TODO: images
        },
      ],
    },
    {
      title: 'Streaks',
      data: [
        {
          question: 'What are streaks?',
          answer: (
            <View>
              <PText style={styles.paragraph}>{"Your streak is a quick way to see on how many consecutive days you've made progress in Wrinkly.  Complete at least one lesson or test per day (at least one peanut must be earned) to continue your streak."}</PText>
            </View>
          ),
        },
        {
          question: 'What if I miss a day?  Will I lose my streak?',
          answer: (
            <View>
              <PText style={styles.paragraph}>{'We know how important keeping your streak can be to stay motivated and consistent to reach your goals.  Broken streaks can be repaired; if a day is missed, you have 10 days to make it up by earning additional peanuts.  From your Profile page, tap on the missing day in the streaks chart, and press the "Repair now" button to earn peanuts towards repair.'}</PText>
            </View>
          ),
          // @TODO: images
        },
      ],
    },
    /* @TODO:
    {
      title: 'Testing',
      data: [
        {
          question: 'TODO', // @TODO: explain srs
          answer: (
            <View>
              <PText style={styles.paragraph}>TODO</PText>
            </View>
          ),
          // @TODO: images
        },
      ],
    },
    */
    {
      title: 'Translations',
      data: [
        {
          question: 'Which translations are available in Wrinkly, and how do I change my preferred translation?',
          answer: (
            <View>
              <PText style={styles.paragraph}>{"The Settings screen lists all available translations, and you can pick your preferred translation here or from the Read scren.  If you don't see your preferred translation listed, please let us know via Feedback Chat."}</PText>
            </View>
          ),
        },
        {
          question: "Why does my Bible not match Wrinkly even though I'm using the same translation?",
          answer: (
            <View>
              <PText style={styles.paragraph}>{"Most major translators are constantly improving their Bible verses to create the most accurate texts they can, and most of the translations available within Wrinkly have gone through several revisions over the years, so if there's a difference between what you're seeing in Wrinkly and a print version, it's probably because the translation in Wrinkly is the most updated version.  However, if you find a mistake in the text, please let us know via Feedback Chat."}</PText>{/* @TODO: link to feedback chat */}
            </View>
          ),
        },
        // @TODO: why are some verses missing (e.g. John 5:4)
      ],
    },
    {
      title: 'Miscellaneous',
      data: [
        {
          question: 'Can I sync data between devices?',
          answer: (
            <View>
              <PText style={styles.paragraph}>{`${isAnonymousUser ? 'As long as you are not signed in as a guest' : 'Yes'}, data will be synced automatically between all devices you sign into with the same account.`}</PText>
              {isAnonymousUser && <PText style={styles.paragraph}>To link your guest account to an authentication provider, go to the Link Account screen (accessible via the Side Drawer) and link with Apple, Google, Facebook, or your email address.</PText>}
            </View>
          ),
        },
        {
          question: 'Why is it called "Wrinkly"?',
          answer: (
            <View>
              <PText style={styles.paragraph}>The initial idea came from the myth that your brain gets a new wrinkle every time you learn something new.  The elephant mascot was a natural fit since elephants are wrinkly, and they never forget!</PText>
            </View>
          ),
        },
        // @TODO: why is Scripture memory important -> add verses supporting this and redirect to Discover with the goal about the importance of memorizing in search
        {
          question: 'Do you have any study tips?',
          answer: (
            <View>
              <PText style={styles.paragraph}>{"The four most helpful tips we've discovered are the following:"}</PText>
              <PText style={styles.paragraph}><SpanText style={styles.bold}>1. </SpanText>Start your Wrinkly sessions by praying for God to teach you through His Word and asking Him to help you commit it to memory.</PText>
              <PText style={styles.paragraph}><SpanText style={styles.bold}>2. </SpanText>Consistency is key!  It is easier to retain long-term recall of something you work on for 5 minutes each day than it is to remember something that you work on for 35 minutes once a week.  Complete a lesson every day to keep your streak going and maximize your efforts.</PText>
              <PText style={styles.paragraph}><SpanText style={styles.bold}>3. </SpanText>When completing a lesson, it helps to say each word aloud.  Whether you are filling in blanks or typing incomplete words, speaking each word out loud as you practice helps to train your brain to remember the passages.</PText>
              <PText style={styles.paragraph}><SpanText style={styles.bold}>4. </SpanText>{"Keep in mind that spending time in God's Word is more important than points!  Earning peanuts and wrinkles are fun ways to stay motivated, but the Bible is infinitely more important!"}</PText>
            </View>
          ),
        },
      ],
    },
    // {
    //   title: 'Classrooms',
    //   data: [
    //     {
    //       question: 'TODO',
    //       answer: (
    //         <View>
    //           <PText style={styles.paragraph}>Donors sponsor participants by pledging an amount per wrinkle earned.  Each memorized verse earns a wrinkle and is achieved by reaching level three for the verse.</PText>{/* @TODO: update */}
    //           <PText style={styles.paragraph}>{"Each level requires successfully typing/reciting the verse with no mistakes.  (Small spelling mistakes don't count against you!)  At least 24 hours must pass between each successful level-up, so if the campaign deadline is coming up, be sure to leave a few days to finish earning your wrinkles!"}</PText>{/* @TODO: update */}
    //         </View>
    //       ),
    //     },
    //   ],
    // },
    {
      title: 'Fundraising',
      data: [
        // @TODO: how do funraisers work?
        // @TODO: how can i start a fundraiser for my organization?
        {
          question: 'As a participant, how do I earn money for my organization?',
          answer: (
            <View>
              <PText style={styles.paragraph}>Donors sponsor participants by pledging an amount per wrinkle earned.  Each memorized verse earns a wrinkle and is achieved by reaching level three for the verse.</PText>
              <PText style={styles.paragraph}>{"Each level requires successfully typing/reciting the verse with no mistakes.  (Small spelling mistakes don't count against you!)  At least 24 hours must pass between each successful level-up, so if the campaign deadline is coming up, be sure to leave a few days to finish earning your wrinkles!"}</PText>
            </View>
          ),
        },
        {
          question: 'As a participant, which verses should I memorize?',
          answer: (
            <View>
              <PText style={styles.paragraph}>Some organizations/campaigns have specific verses for participants to memorize.  If you are unsure, please check with your organization.</PText>
              <PText style={styles.paragraph}>If your organization does not have a specific list, you can create your own goals and select verses manually, or you can use the Discover feature to pick from a curated list of existing passages.</PText>
            </View>
          ),
        },
        {
          question: 'As a participant, how can I track my progress?',
          answer: (
            <View>
              <PText style={styles.paragraph}>Active campaigns are displayed on the Fundraising page in the mobile app.  From there you will be able to see how many wrinkles you have earned (level 3+) and how many are in-progress (level 1 or 2).</PText>
            </View>
          ),
        },
        {
          question: 'Can I be a sponsor and a participant?',
          answer: (
            <View>
              <PText style={styles.paragraph}>{"Yes!  Most campaigns are open to anyone, so if you would like to donate and also memorize verses, that's great!"}</PText>
            </View>
          ),
        },
        {
          question: 'As a sponsor, how do I know how much to pledge per verse?',
          answer: (
            <View>
              <PText style={styles.paragraph}>{"There's no \"right\" number, and average pledge amounts vary with the age and number of participants in the campaign.  If you don't know what to pick for your first campaign, we recommend $2 as a good starting point for the pledge and selecting a maximum donation amount (an amount that will not be exceeded, regardless of participation) that is right for you."}</PText>
            </View>
          ),
        },
        {
          question: 'As a sponsor, how do I follow a campaign?',
          answer: (
            <View>
              <PText style={styles.paragraph}>At the end of the campaign, sponsors will receive a summary of the participation and impact--how many lessons and verses were completed and how much of your pledge was met.  If you wish to add to your donation upon the conclusion of the campaign, you will have the opportunity to do so before final pledges are collected.</PText>
            </View>
          ),
        },
        //        {
        //          question: 'How does Wrinkly get verses to stick?',
        //          answer: (
        //            <View>
        //              <PText style={styles.paragraph}>{'To earn a wrinkle (i.e. for a verse to be considered "memorized"), users must complete 3 successful tests for the passage, and at least 24 hours must elapse between each test.  This prevents a user from reviewing a verse quickly and relying on short-term memory to pass the tests without committing anything to long-term memory.'}</PText>
        //            </View>
        //          ),
        //        },
        //        {
        //          question: 'As a sponsor, how do I know that participants are actually memorizing and not just typing in answers?',
        //          answer: (
        //            <View>
        //              <PText style={styles.paragraph}>{"Ultimately, without proctored tests, there is no way to verify if a participant is testing honestly or not.  However, entering the exact verse from the Bible during testing still requires time to be spent in the Word, so while it's something Wrinkly does not condone, it's still time well spent."}</PText>
        //            </View>
        //          ),
        //        },
      ],
    },
    /* @TODO:
      section about oakwood:
        why did you create this? -> send them to about page
    */
    {
      ignoreInFaq: true,
      title: 'Quick Tips',
      data: [
        {text: 'Create your own goals, or use the Discover feature to pick from a curated list of existing passages.', onPress: () => navigation.navigate(DISCOVER_ROUTE)},
        {text: 'Add friends and family to track progress and encourage each other towards your goals.', onPress: () => navigation.navigate(FRIENDS_ROUTE, {openTab: 'find'})},
        {text: 'Set daily goals and configure reminders to keep you on track.'},
        {text: 'Completing a lesson on the Passage of the Day earns bonus peanuts.'},
        {text: 'Wrinkly provides fundraising opportunities for schools, churches, summer camps, and other missional organizations.', onPress: () => Linking.openURL('https://wrink.ly/fundraising')},
        {text: 'Search for any passage, goal, or user by tapping the magnifying glass in the top right.'},
        {text: 'Weekly progress, streaks, and wrinkles can be tracked from your Profile page.', onPress: () => navigation.navigate(PROFILE_ROUTE)},
        {text: 'Have questions?  Search the Help page for answers to common inquiries.', onPress: () => navigation.navigate(HELP_ROUTE)},
        {text: 'Your default translation, notification preferences, and other account options can be modified from the Settings page.', onPress: () => navigation.navigate(SETTINGS_ROUTE)},
        {text: 'Miss a day?  Your streak can be repaired by completing extra lessons.  Tap on any repairable missed day in the streak graph on your Profile page.', onPress: () => navigation.navigate(PROFILE_ROUTE)},
        isAnonymousUser && {text: 'You can sync your progress across all of your devices by signing in with an aunthenication provider.', onPress: () => navigation.navigate(LINK_ACCOUNT_ROUTE)},
        !oakUser?.is_feedback_chat_disabled && {text: 'Have a question or an idea for a new feature?  Let us know in the Feedback Chat page.', onPress: () => navigation.navigate(FEEDBACK_CHAT_ROUTE)},
      ].filter(Boolean).map((data, number) => {
        return {
          question: `Tip #${number + 1}`,
          answer: (
            <TouchableOpacity
              disabled={!data.onPress}
              onPress={data.onPress}
            >
              <PText style={styles.paragraph}>{data.text}</PText>
            </TouchableOpacity>
          ),
          isQuickTip: true,
        };
      }),
    },
  ],
});

export default faqs;
