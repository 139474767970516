/*
 * Ryan O'Dowd
 * 2024-09-28
 * © Copyright 2024 Oakwood Software Consulting, Inc. All Rights Reserved.
 */
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tooltip,
} from '@mui/material';
import LinearProgress from '../../../common/components/OakLinearProgress';
import OakTable from '../../../common/components/OakTable';
import PropTypes from 'prop-types';
import React from 'react';
import {
  format as dateFnsFormat,
} from 'date-fns';
import styles from './styles';
import {
  useSelector,
} from 'react-redux';
import utilities from '../../../utilities';

const StudentAssignmentsDialog = (props) => {
  const allUserAssignmentProgress = useSelector((state) => state.goalProgress[props.goal.id])?.[props.studentId]?.assignments || {};

  const classroomStudents = useSelector((state) => state.classroomStudents[props.classroomGoal.classroom_id] || []);
  const student = classroomStudents.find((cs) => +(cs.user_id || cs.user?.id) === +props.studentId);
  const studentName = student?.display_name || student?.user?.display_name;

  const assignmentsTableData = props.goal.modules.flatMap((module) => module.assignments).map((assignment) => {
    const userAssignmentProgress = allUserAssignmentProgress[assignment.id] || {};
    const numLessonsCompleted = userAssignmentProgress.num_lessons_completed || 0;
    const numLessonsRequired = assignment.assignment_object.num_lessons_to_complete || 1;
    const progressPercent = Math.floor(numLessonsCompleted / numLessonsRequired * 100);
    /* @TODO: would be great to click on any value to see it plotted against rest of class to see outliers
      start/end date is a combined graph with ranges
    */
    return [ // @TODO: update ids
      {
        id: `${assignment.id}_name`,
        content: (
          <span>
            {/* @TODO:
            <Checkbox
              checked={!!_checkboxStatuses[cs.id]}// must be a bool to prevent uncontrolled warning
              onChange={() => {
                setCheckboxStatuses((curr) => {
                  const next = {...curr};
                  next[cs.id] = !curr[cs.id];
                  return next;
                });
              }}
              sx={{
                color: cs.color,
                '&.Mui-checked': {
                  color: cs.color,
                },
              }}
            />
            */}
            {assignment.name}
          </span>
        ),
      }, // @TODO: maybe surround with join date (or invitation date if pending)
      // @TODO: {id: `${assignment.id}_date_started`, content: userAssignmentProgress.date_started ? <Tooltip title={dateFnsFormat(userAssignmentProgress.date_started, 'MMM d, yyyy, h:mm a')}><span>{dateFnsFormat(userAssignmentProgress.date_started, 'MMM d')}</span></Tooltip> : '-'}, // @TODO: replace '-' with tooltip about joining via public code?
      // @TODO: {id: `${assignment.id}_date_completed`, content: userAssignmentProgress.date_completed ? <Tooltip title={dateFnsFormat(userAssignmentProgress.date_completed, 'MMM d, yyyy, h:mm a')}><span>{dateFnsFormat(userAssignmentProgress.date_completed, 'MMM d')}</span></Tooltip> : '-'},
      /* @TODO: combine questions answered/skiped and time into a "more info" button
        should show cell data as warning or okay flag based on...
          num skipped
          answer/skip ratio
          total time spent
          should "normal" be decided by class average or an objective metric? (e.g. half the time of the average vs. less than 1s per 10 words)
      */
      // @TODO: {id: `${assignment.id}_total_num_questions_answered`, content: userAssignmentProgress.total_num_questions_answered},
      // @TODO: {id: `${assignment.id}_total_num_questions_skipped`, content: userAssignmentProgress.total_num_questions_skipped},
      {id: `${assignment.id}_total_milliseconds_spent`, content: userAssignmentProgress.total_milliseconds_spent == null ? '-' : utilities.humanReadableTimeExact(userAssignmentProgress.total_milliseconds_spent)}, // @TODO: add sortBy attr to use userAssignmentProgress.total_milliseconds_spent instead of the pretty time// @TODO: color code for more than one standard deviation away from mean
      {
        id: `${assignment.id}_TODO_progress`,
        content: progressPercent === 0 ? '-' : (
          <Tooltip placement='left' title={`${numLessonsCompleted} of ${numLessonsRequired} lessons completed`}>{/* @TODO: if it's a reading, lecture, or memory only (no lessons requried) then don't show 0/1 or 1/1...just show "not started" or "completed"?  */}{/* @TODO: too much padding */}
            <span>
              <LinearProgress progressPercent={progressPercent} />
            </span>
          </Tooltip>
        ),
      },
    ];
  });

  return (
    <Dialog
      style={styles.container}
      maxWidth='lg'
      open={true}
      onClose={() => props.onClose()} // @TODO: ask about unsaved changes
      disableRestoreFocus={true}// https://github.com/mui/material-ui/issues/33004#issuecomment-1473299089
    >
      <DialogTitle id='alert-dialog-title'>
        <span style={styles.mainTitle}>{studentName}</span>
      </DialogTitle>
      <DialogContent style={styles.innerContainer}>
        <OakTable
          tableTitle='Assignment progress'
          open={true}
          disableAccordion={true}
          headerRowData={[
            'Assignment name',
            // @TODO: 'Date started',
            // @TODO: 'Date completed',
            // @TODO: 'Questions answered',
            // @TODO: 'Questions skipped',
            'Total time spent',
            'Progress',
          ]}// @TODO: some of these should be named better and/or have tooltips
          rowData={assignmentsTableData}
        />
      </DialogContent>
      <DialogActions>
        <Button
          color='primary'// @TODO: dangerous?
          onClick={() => props.onClose()}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

StudentAssignmentsDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  classroomGoal: PropTypes.object.isRequired,
  goal: PropTypes.object.isRequired, // @TODO: shape
  studentId: PropTypes.number.isRequired,
};

export default StudentAssignmentsDialog;
