/**
 * Ryan O'Dowd
 * 2024-09-28
 * © Copyright 2024 Oakwood Software Consulting, Inc. All Rights Reserved.
 */
import Globals from '../../../Globals';

const styles = {
  progressBar: (isComplete) => ({ // @TODO: combine with Assignment.styles (should probably make this its own compoennt for how much it's used)
    borderWidth: 2,
    borderStyle: 'solid',
    borderColor: isComplete ? Globals.colors.accentDark : Globals.colors.primary,
    borderRadius: 8,
  }),
};

export default styles;
