/**
 * Ryan O'Dowd
 * 2024-02-08
 * © Copyright 2024 Oakwood Software Consulting, Inc. All Rights Reserved.
 */
import PropTypes from 'prop-types';
import React, {
// @TODO: useRef,
} from 'react';
import Text from '../../../../common/components/OakText';
import styles from './styles';

const VideoAssignment = (props) => {
  // @TODO: const videoRef = useRef(null);
  // @TODO: const videoSource = require('./test.mp4');

  return (
    <div style={styles.container}>
      <Text>Watch this video</Text>
      <Text style={styles.lectureText}>{'TODO: embed video here: ' + props.url}</Text>
      {/* @TODO:
      <Video
        style={styles.video}
        source={videoSource}// @TODO: source={{uri: props.url}}// @TODO: doesn't work with youtube, vimeo, etc. i don't think, but i can't say for sure
        ref={videoRef}
        onBuffer={() => {
          // @TODO: show loading indicator
        }}
        onError={(e) => {
          console.error(e); // @TODO: better error handling
        }}
      />
      */}
    </div>
  );
};

VideoAssignment.propTypes = {
  id: PropTypes.number.isRequired,
  created_at: PropTypes.string.isRequired,
  updated_at: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

export default VideoAssignment;
