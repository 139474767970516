/*
 * Ryan O'Dowd
 * 2024-03-01
 * © Copyright 2024 Oakwood Software Consulting, Inc. All Rights Reserved.
 */
import {
  Accordion,
  AccordionSummary,
  Button,
  IconButton,
  Paper,
  Tooltip,
} from '@mui/material';
import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  apiUpdateGoal,
  fetchBookNames,
  fetchBooks,
  fetchChapters,
  fetchClassroomGoals,
  fetchGoalProgress,
  fetchMinigames,
  fetchTags,
  fetchTranslations,
} from '../../actions';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  useNavigate,
  useParams,
} from 'react-router-dom';
import Alert from '../../common/components/OakAlert';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ClassroomGoalConstants from './Globals';
import {
  DndProvider,
} from 'react-dnd';
import EditGoalDialog from './EditGoalDialog';
import EditIcon from '@mui/icons-material/Edit';
import EditModuleDialog from './EditModuleDialog';
import {
  HTML5Backend,
} from 'react-dnd-html5-backend';
import LinearProgress from '../../common/components/OakLinearProgress';
import Module from './Module';
import OakTable from '../../common/components/OakTable';
import PublishButton from './PublishButton';
import StudentAssignmentsDialog from './StudentAssignmentsDialog';
// @TODO: import StudentProgressTable from './StudentProgressTable';
import WebPageStub from '../WebPageStub';
import WebsiteSection from '../../components/WebsiteSection';
import styles from './styles';
import update from 'immutability-helper';
import utilities from '../../utilities';

const ClassroomGoal = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [_isEditGoalDialogOpen, setIsEditGoalDialogOpen] = useState(false);
  const [_isNewModuleDialogOpen, setIsNewModuleDialogOpen] = useState(false);
  const [_selectedStudentId, setSelectedStudentId] = useState(null);

  const {
    schoolId, // @TODO: maybe this should be an id instead
    classroomId,
    classroomGoalId,
  } = useParams();

  const classroomStudents = useSelector((state) => state.classroomStudents[classroomId] || []);

  const classroomGoal = useSelector((state) => {
    return state.classroomGoalMetadata[classroomGoalId];
  });
  const goal = useSelector((state) => state.goals[classroomGoal.goal_id]);

  const isOakwoodPublicGoal = classroomGoal.id === 1;

  const goalProgress = useSelector((state) => state.goalProgress[classroomGoal.goal_id]) || {};

  const isAdmin = utilities.useIsAdmin();

  useEffect(() => { // @TODO: this should go in App and be called coniditionally if user is logged in
    // @TODO: dispatch(fetchSchool(schoolId));
    dispatch(fetchBooks());
    dispatch(fetchChapters());
    dispatch(fetchTranslations());
    dispatch(fetchBookNames());
    dispatch(fetchClassroomGoals(classroomId));
    dispatch(fetchMinigames());
    if (isAdmin) {
      dispatch(fetchTags());
    }
  }, [dispatch, schoolId, classroomId, isAdmin]);

  useEffect(() => {
    if (!isOakwoodPublicGoal) {
      // oakwood classroom public goals shouldn't fetch student progress
      dispatch(fetchGoalProgress(classroomId, classroomGoal.goal_id));
    }
  }, [dispatch, classroomId, classroomGoal.goal_id, classroomGoal.id, isOakwoodPublicGoal]);

  const [_modules, setModules] = useState(goal.modules);
  useEffect(() => {
    setModules(goal.modules);
  }, [goal.modules]);

  const moveModule = useCallback((dragIndex, hoverIndex) => { // @TODO: duplicate this for modules
    setModules((prevModules) =>
      update(prevModules, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevModules[dragIndex]],
        ],
      }),
    );
  }, []);

  /* @TODO:
  const moveAssignment = useCallback((dragIndex, hoverIndex) => { // @TODO: duplicate this for modules
    setCards((prevCards) =>
      update(prevCards, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevCards[dragIndex]],
        ],
      }),
    )
  }, []);
  */

  // @TODO: can memoize these: {
  const goalAssignmentObjects = goal.modules.flatMap((module) => module.assignments).map((assignment) => assignment.assignment_object);
  const numLessonsRequired = goalAssignmentObjects.reduce((acc, ao) => acc + (ao.num_lessons_to_complete || 1), 0);
  // @TODO: }

  const studentTableRows = Object.entries(goalProgress).map(([studentId, {assignments}]) => { // @TODO: convert these to accordion? when expanded, show all info for just this student (and class average)
    const numCompleted = Object.values(assignments).reduce((acc, assignment) => acc + (Math.min(assignment.num_lessons_completed || 0), goalAssignmentObjects.find((ao) => ao.id === assignment.assignment_id)?.num_lessons_to_complete || 1), 0);
    const progressPercent = Math.floor(numCompleted / numLessonsRequired * 100);
    const classroomStudent = classroomStudents.find((cs) => +cs.user_id === +studentId || +cs.user?.id === +studentId);

    return [ // @TODO: update ids
      {
        id: `${studentId}_display_name`,
        content: (
          <Tooltip title={classroomStudent?.user?.display_name}>
            <span>
              {classroomStudent?.display_name || classroomStudent?.user?.display_name}
            </span>
          </Tooltip>
        ),
      }, // @TODO: maybe surround with join date (or invitation date if pending)
      {
        id: `${studentId}_progress`,
        content: (
          <Tooltip title={`${numCompleted} of ${numLessonsRequired} lessons completed`}>
            <span>
              <LinearProgress progressPercent={progressPercent} />
            </span>
          </Tooltip>
        ),
      },
      /* @TODO:
      {id: `${studentId}_date_started`, content: cs.join_code ? <Tooltip title={dateFnsFormat(cs.created_at, 'MMM d, yyyy, h:mm a')}><span>{dateFnsFormat(cs.created_at, 'MMM d')}</span></Tooltip> : '-'}, // @TODO: replace '-' with tooltip about joining via public code?
      {id: `${studentId}_date_completed`, content: cs.date_joined ? <Tooltip title={dateFnsFormat(cs.date_joined, 'MMM d, yyyy, h:mm a')}><span>{dateFnsFormat(cs.date_joined, 'MMM d')}</span></Tooltip> : '-'},
      */
    ];
  });

  // @TODO: instead of edit buttons opening new dialogs, maybe just toggle editability of each component in-line
  return (
    <WebPageStub
      hideTabs={true}// @TODO: instead of hiding tabs, should we show different tabs for teacher dashboard? i think we should consider it once this single page becomes too complex
    >

      <WebsiteSection
        style={styles.noVerticalPadding}
        isDarkBackground={false}
      >
        <Button
          color='primary'
          variant='text'
          startIcon={<ArrowBackIcon />}
          onClick={() => navigate(`/schools/${schoolId}/classrooms/${classroomId}`)}
        >
          Back to classroom
        </Button>
      </WebsiteSection>

      {/* @TODO:
      {classroomGoal.status === 'published' &&
        <WebsiteSection// @TODO: move this to its own component
          isDarkBackground={false}
          title={(
            <span style={styles.currentCampaignsTitleRow}>{/* @TODO: new name /}
              <span>Progress</span>
            </span>
          )}
        >
          <StudentProgressTable classroomId={classroomId} />
        </WebsiteSection>
      }
      */}

      <WebsiteSection
        isDarkBackground={false}
        title={(
          <React.Fragment>
            <span style={styles.publishedStatusWrapper}>
              <Alert
                type={classroomGoal.status === 'draft' ? 'warning' : 'success'}
                leftButton={classroomGoal.status === 'draft' ? <PublishButton classroomId={classroomGoal.classroom_id} goalId={goal.id} /> : null}// @TODO: this is the right button
                text={classroomGoal.status === 'draft' ? ClassroomGoalConstants.draftText : ClassroomGoalConstants.publishedText(classroomGoal.date_published)}
              />
            </span>
            <span style={styles.editRow}>
              <span style={styles.fullTitleWrapper}>
                <h1 style={styles.mainTitle}>{goal.title}</h1>
                <span style={styles.subTitle}>{utilities.prettyAvailablityDateRange(goal.date_available, goal.date_deadline)}</span>
              </span>
              <span style={styles.actionsWrapper}>
                <Tooltip title='Edit goal'>
                  <IconButton
                    variant='outlined'
                    size='small'
                    color='primary'
                    onClick={() => setIsEditGoalDialogOpen(true)}
                  >
                    <EditIcon />
                  </IconButton>
                </Tooltip>
              </span>
            </span>
          </React.Fragment>
        )}
      >
        {_modules.map((module, index) => { // @TODO: introduce accordion here (for modules and assignments)
          return (
            <DndProvider
              key={module.id}
              backend={HTML5Backend}
            >
              <Module
                goal={goal}
                classroomGoal={classroomGoal}
                module={module}
                moveModule={moveModule}
                index={index}
                onReorderModules={() => {
                  dispatch(apiUpdateGoal(goal.id, undefined, undefined, undefined, _modules.map((m) => m.id), undefined, undefined, undefined, undefined));
                }}
              />
            </DndProvider>
          );
        })}
        <Paper style={{...styles.accordionWrapper, ...styles.coloredAccordion}}>
          <Accordion
            style={styles.accordionInner}// @TODO: change
            defaultExpanded={false}
            // @TODO: disabled={true}
          >
            <AccordionSummary
              // @TODO: expandIcon={<ExpandMoreIcon />}
              onClick={() => setIsNewModuleDialogOpen(true)}
            >
              <h2 style={styles.moduleName}>+ Add new module</h2>
              {/* @TODO:
              <TextField// @TODO: make this white...label should match (or be close to) existing module names
                value={_newModuleName}
                onChange={(e) => setNewModuleName(e.target.value)}
                placeholder=''
                label='+ Add new module'// @TODO: improve ui
                variant='standard'
              />
              {_newModuleName &&
                <React.Fragment>
                  <IconButton
                    style={{...styles.editIconButton, ...styles.editIconButtonCheck}}
                    onClick={() => {
                      // @TODO: implement back-end call
                    }}
                    variant='contained'// @TODO:
                  >
                    <CheckIcon />
                  </IconButton>
                  <IconButton
                    style={{...styles.editIconButton, ...styles.editIconButtonClose}}
                    onClick={() => {
                      setNewModuleName('');
                    }}
                    variant='contained'
                  >
                    <CloseIcon />
                  </IconButton>
                </React.Fragment>
              }
              */}
            </AccordionSummary>
          </Accordion>
        </Paper>
      </WebsiteSection>

      {!isOakwoodPublicGoal &&
        <WebsiteSection
          isDarkBackground={true}
        >
          <OakTable
            tableTitle='Student progress'
            open={true}
            lightBackground={true}
            headerRowData={[
              'Student',
              'Progress',
            ]}// @TODO: some of these should be named better and/or have tooltips
            rowData={studentTableRows}
            // @TODO: this should be an accordion to show all assignments for the selected student
            onRowPress={(row) => setSelectedStudentId(+row[0].id.split('_')[0])}// @TODO: way too hacky
          />
        </WebsiteSection>
      }

      {_isEditGoalDialogOpen && <EditGoalDialog isAdmin={isAdmin} classroomGoal={classroomGoal} goal={goal} onClose={() => setIsEditGoalDialogOpen(false)} />}
      {_isNewModuleDialogOpen && <EditModuleDialog classroomGoal={classroomGoal} goal={goal} isNew={true} onClose={() => setIsNewModuleDialogOpen(false)} />}
      {_selectedStudentId && <StudentAssignmentsDialog classroomGoal={classroomGoal} goal={goal} studentId={_selectedStudentId} onClose={() => setSelectedStudentId(null)} />}
    </WebPageStub>
  );
};

ClassroomGoal.propTypes = {
  // @TODO: implement
};

export default ClassroomGoal;
