/*
 * Ryan O'Dowd
 * 2022-03-08
 *
 * © Copyright 2022 Oakwood Software Consulting, Inc.  All Rights Reserved.
 */
import './index.css';
import {
  ThemeProvider,
  createTheme,
} from '@mui/material/styles';
import App from './containers/App';
import Globals from './Globals';
import {
  PersistGate,
} from 'redux-persist/integration/react';
import React from 'react';
import {
  Provider as StoreProvider,
} from 'react-redux';
import configureStore from './Store';
import {
  createRoot,
} from 'react-dom/client';
// @TODO: import reportWebVitals from './reportWebVitals';

const reduxStuff = configureStore();

const theme = createTheme({
  palette: {
    mode: 'light', // @TODO: have a dark mode too
    common: {
      black: '#222',
      white: '#eee',
    },
    primary: {
      main: Globals.colors.primary,
      dark: Globals.colors.primaryDark,
      light: Globals.colors.primaryLight,
      contrastText: 'rgba(0, 0, 0, 0.87)',
    },
    secondary: {
      main: Globals.colors.accent,
      dark: Globals.colors.accentDark,
      light: Globals.colors.accentLight,
      contrastText: 'rgba(0, 0, 0, 0.87)',
    },
    error: { // @TODO: update
      main: '#f44336',
      light: '#e57373',
      dark: '#d32f2f',
      contrastText: '#fff',
    },
  },
  components: {
    MuiAccordion: {
      styleOverrides: {
        root: {
          backgroundColor: 'transparent !important',
        },
      },
    },
  },
});

const root = createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <StoreProvider store={reduxStuff.store}>
      <PersistGate
        loading={null}
        persistor={reduxStuff.persistor()}
      >
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </PersistGate>
    </StoreProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// @TODO: reportWebVitals();
