/*
 * Ryan O'Dowd
 * 2024-09-27
 * © Copyright 2024 Oakwood Software Consulting, Inc.  All Rights Reserved.
 */
import {
  Button,
  IconButton,
} from '@mui/material';
import React, {
  useState,
} from 'react';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import Globals from '../../../Globals';
import LaunchIcon from '@mui/icons-material/Launch';
import {
  Link,
} from 'react-router-dom';
import NewOrEditClassroomDialog from '../NewOrEditClassroomDialog';
import NewOrEditSchoolDialog from '../NewOrEditSchoolDialog';
import OakTable from '../../../common/components/OakTable';
import PropTypes from 'prop-types';
import TheaterComedyIcon from '@mui/icons-material/TheaterComedy';
import {
  masqueradeAs,
} from '../../../actions';
import styles from './styles';
import {
  useDispatch,
} from 'react-redux';

const SchoolPreview = (props) => {
  const dispatch = useDispatch();

  const [_isNewOrEditSchoolDialogOpen, setIsNewOrEditSchoolDialogOpen] = useState(false);
  const [_isNewOrEditClassroomDialogOpen, setIsNewOrEditClassroomDialogOpen] = useState(false);
  const [_selectedClassroom, setSelectedClassroom] = useState(null);

  const tableRows = props.school.classrooms.map((classroom) => {
    return [
      {id: `${classroom.id}_name`, content: classroom.name},
      {
        id: `${classroom.id}_teacher`,
        content: (
          <span style={styles.teacherNameAndActions}>
            {classroom.teacher.display_name}
            {/* @TODO:
            <IconButton
              variant='outlined'
              color='primary'
              onClick={(event) => {
                event.stopPropagation();
                // @TODO: implement: edit dialog for teacher
              }}
            >
              <EditIcon />
            </IconButton>
            */}
            <IconButton
              variant='outlined'
              color='primary'
              onClick={(event) => {
                event.stopPropagation();
                dispatch(masqueradeAs(classroom.teacher.user_id, () => {
                  window.location = `/schools/${props.school.id}/classrooms/${classroom.id}`;
                }));
              }}
            >
              <TheaterComedyIcon />
            </IconButton>
          </span>
        ),
      },
      {id: `${classroom.id}_students`, content: classroom.student_count},
      {id: `${classroom.id}_join_code`, content: classroom.join_code ?? ''},
      {id: `${classroom.id}_join_code_enabled`, content: +classroom.is_join_code_enabled ? <CheckIcon /> : <CloseIcon />},
      {
        id: `${classroom.id}_link`,
        content: (
          <span style={styles.actions}>
            <IconButton
              variant='outlined'
              color='primary'
              onClick={(event) => {
                event.stopPropagation();
                setSelectedClassroom(classroom);
                setIsNewOrEditClassroomDialogOpen(true);
              }}
            >
              <EditIcon />
            </IconButton>
            <IconButton
              variant='outlined'
              color='primary'
              component={Link}
              to={`/schools/${props.school.id}/classrooms/${classroom.id}`}
            >
              <LaunchIcon />
            </IconButton>
          </span>
        ),
      },
    ];
  });

  return (
    <div style={styles.container}>
      <div style={styles.sectionWrapper}>
        <OakTable
          tableTitle={
            <span style={styles.schoolNameRow}>
              <IconButton
                variant='outlined'
                sx={{color: Globals.colors.white}}
                onClick={(event) => {
                  event.stopPropagation(); // @TODO: not working?
                  setIsNewOrEditSchoolDialogOpen(true);
                  // @TODO: implement: edit dialog
                }}
              >
                <EditIcon />
              </IconButton>
              {props.school.name}
            </span>
          }
          open={false}
          headerRowData={[
            'Classroom name',
            'Teacher',
            'Number of active students',
            'Join code',
            'Join code enabled',
            'Actions',
          ]}
          rowData={tableRows}// @TODO: shouldn't be clickable (or if they are, make individually updatable)
          filtersJsx={
            <Button
              variant='contained'
              size='small'
              color='secondary'
              onClick={() => setIsNewOrEditClassroomDialogOpen(true)}
            >
              Add classroom
            </Button>
          }
        />
      </div>

      {_isNewOrEditSchoolDialogOpen && <NewOrEditSchoolDialog school={props.school} onClose={() => setIsNewOrEditSchoolDialogOpen(false)} />}
      {_isNewOrEditClassroomDialogOpen &&
        <NewOrEditClassroomDialog
          schoolId={props.school.id}
          classroom={_selectedClassroom}
          onClose={() => {
            setIsNewOrEditClassroomDialogOpen(false);
            setSelectedClassroom(null); // @TODO: hacky that i have to do this every time the dialog is closed...either listen for that in useeffect or get rid of this state
          }}
        />
      }
    </div>
  );
};

SchoolPreview.propTypes = {
  school: PropTypes.object.isRequired, // @TODO: shape
};

export default SchoolPreview;
