/*
 * Ryan O'Dowd
 * 2024-09-28
 * © Copyright 2024 Oakwood Software Consulting, Inc. All Rights Reserved.
 */
import {
  LinearProgress,
} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import styles from './styles';

const OakLinearProgress = (props) => {
  return (
    <LinearProgress
      // @TODO: this needs to look more complete
      color={props.progressPercent >= 100 ? 'secondary' : 'primary'}
      variant='determinate'
      style={styles.progressBar(props.progressPercent >= 100)}
      value={Math.min(props.progressPercent, 100)}
    />
  );
};

OakLinearProgress.propTypes = {
  progressPercent: PropTypes.number.isRequired,
};

export default OakLinearProgress;
