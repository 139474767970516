/**
 * Ryan O'Dowd
 * 2024-09-27
 * © Copyright 2024 Oakwood Software Consulting, Inc. All Rights Reserved.
 */
import Globals from '../../../../../Globals';

// @TODO: delete unused
const styles = {
  headerText: { // @TODO: abstract with edit dialogs
    display: 'flex',
    fontSize: 16,
    fontFamily: Globals.fontFamilies.secondary,
    fontWeight: 'bold',
    color: Globals.colors.primaryDark,
    marginTop: 32,
  },
  subHeaderWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    minWidth: 300,
    marginTop: 8,
    marginLeft: 32, // @TODO: abstract with edit dialogs
    marginRight: 32, // @TODO: abstract with edit dialogs
  },
  nameWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    minWidth: 300,
    marginTop: 8,
    marginLeft: 32, // @TODO: abstract with edit dialogs
    marginRight: 32, // @TODO: abstract with edit dialogs
  },
  passageWrapper: {
    display: 'flex',
    marginTop: 64,
  },
  lectureTextWrapper: {
    width: 10000, // @TODO: hardcoded
    maxWidth: 'calc(100% - 64px)', // @TODO: hardcoded
  },
  errorText: {
    color: Globals.colors.alert,
  },
};

export default styles;
