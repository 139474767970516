/*
 * Ryan O'Dowd
 * 2024-10-02
 * © Copyright 2024 Oakwood Software Consulting, Inc. All Rights Reserved.
 */
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Select,
  Switch,
  TextField,
} from '@mui/material';
import React, {
  useState,
} from 'react';
import {
  adminAddClassroom,
  adminUpdateClassroom,
} from '../../../actions';
import PropTypes from 'prop-types';
import styles from './styles';
import {
  useDispatch,
} from 'react-redux';

const NewOrEditClassroomDialog = (props) => {
  const dispatch = useDispatch();

  const [_classroomName, setClassroomName] = useState(props.classroom?.name || '');
  const [_joinCode, setJoinCode] = useState(props.classroom?.join_code || '');
  const [_isJoinCodeEnabled, setIsJoinCodeEnabled] = useState(props.classroom?.is_join_code_enabled ?? 1);
  const [_teacherDisplayName, setTeacherDisplayName] = useState('');
  const [_teacherUserId, setTeacherUserId] = useState('');
  const [_errorMessage, setErrorMessage] = useState('');

  return (
    <Dialog
      style={styles.container}
      maxWidth='lg'
      open={true}
      onClose={() => props.onClose()} // @TODO: ask about unsaved changes
      disableRestoreFocus={true}// https://github.com/mui/material-ui/issues/33004#issuecomment-1473299089
    >
      <DialogTitle id='alert-dialog-title'>
        <span style={styles.mainTitle}>Edit goal</span>
      </DialogTitle>
      <DialogContent style={styles.innerContainer}>
        <span style={styles.headerText}>Classroom</span>
        <span style={styles.fieldsWrapper}>
          <TextField
            value={_classroomName}
            autoFocus={true}
            fullWidth
            onChange={(e) => setClassroomName(e.target.value)}
            placeholder=''
            label='Classroom name'
            variant='standard'
          />
          {!!_joinCode &&
            <TextField
              value={_joinCode}
              fullWidth
              onChange={(e) => setJoinCode(e.target.value.toUpperCase())}
              placeholder=''
              inputProps={{maxLength: 7}}
              label='Join code'
              variant='standard'
            />
          }
          <FormControlLabel
            control={
              <Switch
                checked={!!+_isJoinCodeEnabled}
                onChange={(event) => setIsJoinCodeEnabled(+event.target.checked)}
              />
            }
            label='Join code is enabled'
          />
        </span>

        {!props.classroom && // @TODO: have separate ui for modifying teachers or reassigning teachers within a classroom
          <React.Fragment>
            <span style={styles.headerText}>Teacher</span>
            <p style={styles.errorMessage}>{"This is for new teachers only.  If they're already a classrom teacher, insert into DB manually (or build this functionaility)."}</p>
            <span style={styles.fieldsWrapper}>
              <TextField
                value={_teacherDisplayName}
                fullWidth
                onChange={(e) => setTeacherDisplayName(e.target.value)}
                placeholder=''
                label='Teacher display name (for students)'
                variant='standard'
              />

              <br />{/* @TODO: shouldn't need this */}

              <TextField
                value={_teacherUserId}
                fullWidth={true}
                type='number'
                onChange={(e) => setTeacherUserId(e.target.value)}
                placeholder=''
                label='Teacher user ID (existing Wrinkly account)'
                variant='standard'
              />
            </span>
          </React.Fragment>
        }
      </DialogContent>
      <DialogActions>
        <span style={styles.errorMessage}>{_errorMessage}</span>
        <Button
          color='primary'// @TODO: dangerous?
          onClick={() => props.onClose()}
        >
          Cancel
        </Button>
        <Button
          color='primary'
          variant='contained'
          onClick={() => {
            if (props.classroom) {
              dispatch(adminUpdateClassroom(
                props.classroom.id,
                {
                  name: _classroomName,
                  joinCode: _joinCode,
                  isJoinCodeEnabled: _isJoinCodeEnabled,
                },
                props.onClose,
              ));
            } else {
              if (!_classroomName || !_teacherDisplayName || !_teacherUserId) {
                setErrorMessage('Please fill out all fields.');
                return;
              }
              dispatch(adminAddClassroom(props.schoolId, _classroomName, _isJoinCodeEnabled, _teacherDisplayName, _teacherUserId, props.onClose));
            }
          }}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

NewOrEditClassroomDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  schoolId: PropTypes.number.isRequired,

  classroom: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    join_code: PropTypes.string.isRequired,
    is_join_code_enabled: PropTypes.number.isRequired,
  }),
  teacher: PropTypes.shape({
    id: PropTypes.number.isRequired,
    display_name: PropTypes.string.isRequired,
    user_id: PropTypes.number.isRequired,
  }),
};

export default NewOrEditClassroomDialog;
