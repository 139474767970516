// @TODO: combine this with ClassroomGoal/EditGoalDialog
/*
 * Ryan O'Dowd
 * 2024-05-17
 * © Copyright 2024 Oakwood Software Consulting, Inc.  All Rights Reserved.
 */
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Tooltip,
} from '@mui/material';
import React, {
  useState,
} from 'react';
import {
  apiAdminCreateClassroomGoal,
  apiCreateClassroomGoal,
} from '../../../actions';
import DateRange from '../../ClassroomGoal/DateRange';
import InfoIcon from '@mui/icons-material/Info';
import PropTypes from 'prop-types';
import {
  format as dateFnsFormat,
} from 'date-fns';
import styles from './styles';
import {
  useDispatch,
} from 'react-redux';
import {
  useNavigate,
} from 'react-router-dom';
import utilities from '../../../utilities';

const NewGoalDialog = (props) => { // @TODO: abstract this with MyCampaigns/CampaignPreview
  const i18n = utilities.useI18n();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [_newGoalTitle, setNewGoalTitle] = useState('');
  const [_pipeDelimitedText, setPipeDelimitedText] = useState('');
  const [_validationError, setValidationError] = useState('');
  const [_dateAvailable, setDateAvailable] = useState(null);
  const [_dateDeadline, setDateDeadline] = useState(null);

  const isAdmin = utilities.useIsAdmin();

  if (!props.isOpen) {
    return null;
  }

  return (
    <Dialog
      style={styles.container}
      maxWidth='lg'
      open={props.isOpen}
      onClose={props.onClose}
    >
      <DialogTitle id='alert-dialog-title'>
        <span style={styles.mainTitle}>Create new goal</span>
      </DialogTitle>
      <DialogContent style={styles.innerContainer}>
        <span style={styles.headerText}>Goal name</span>
        <span style={styles.nameWrapper}>
          <TextField
            value={_newGoalTitle}
            fullWidth
            onChange={(e) => setNewGoalTitle(e.target.value)}
            placeholder={`e.g. "5th Grade Verses, Fall ${dateFnsFormat(new Date(), 'yyyy', {locale: i18n('locale')})}"`}// @TODO: better placeholder
            variant='standard'
          />
        </span>

        <DateRange
          dateAvailable={_dateAvailable}
          dateDeadline={_dateDeadline}
          setDateAvailable={(newValue) => setDateAvailable(newValue)}
          setDateDeadline={(newValue) => setDateDeadline(newValue)}
          // @TODO: shouldn't be able to pick end date that's before start date
          hideClear={true}
          headerRight={
            <Tooltip title='Goal availability and due dates can be changed at any time'>
              <InfoIcon style={styles.infoButton} />
            </Tooltip>
          }
        />

        {isAdmin &&
          <div style={styles.adminOptionsWrapper}>
            <span style={styles.headerText}>Admin options</span>

            <div style={styles.adminOptionsInner}>
              <span style={styles.headerText}>Pipe-delimited text</span>
              <span style={styles.nameWrapper}>
                <TextField
                  value={_pipeDelimitedText}
                  fullWidth
                  onChange={(e) => setPipeDelimitedText(e.target.value)}
                  placeholder={`e.g. "name|description|tag1,tag2|passage1,passage2"`}
                  variant='standard'
                />
              </span>
            </div>

            <Button
              color='primary'
              variant='contained'
              onClick={() => {
                dispatch(apiAdminCreateClassroomGoal(props.classroomId, _pipeDelimitedText));
                // @TODO: show success/failure (and clear text input on success)
              }}
            >
              Save
            </Button>
          </div>
        }
      </DialogContent>
      <DialogActions>
        <p style={styles.errorText}>{_validationError}</p>
        <Button
          color='primary'// @TODO: dangerous?
          onClick={props.onClose}
        >
          Cancel
        </Button>
        <Button
          color='primary'
          variant='contained'
          onClick={() => {
            if (!_newGoalTitle) {
              setValidationError('Goal name is required');
            } else if (!isAdmin && (!_dateAvailable || !_dateDeadline)) {
              setValidationError('Availability and due dates are required');
            } else {
              setValidationError('');
              // @TODO: show loading indicator
              dispatch(apiCreateClassroomGoal(props.classroomId, _newGoalTitle, _dateAvailable, _dateDeadline, (newClassroomGoalId) => navigate(`/schools/${props.schoolId}/classrooms/${props.classroomId}/classroom_goals/${newClassroomGoalId}`)));
            }
          }}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

NewGoalDialog.propTypes = {
  classroomId: PropTypes.number.isRequired,
  schoolId: PropTypes.number.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default NewGoalDialog;
