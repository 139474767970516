/*
 * Ryan O'Dowd
 * 2024-09-27
 * © Copyright 2024 Oakwood Software Consulting, Inc.  All Rights Reserved.
 */
import Globals from '../../../Globals';

// @TODO: delete unused
const styles = {
  container: {
    flex: 1,
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    margin: 'auto',
    // @TODO: marginBottom: 64,
  },
  sectionWrapper: {
    flex: 1,
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: 8, // @TODO: why not working?
    marginBottom: 16,
  },
  schoolNameRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: 4,
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: 4,
  },
  teacherNameAndActions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: 4,
  },
  classroomWrapper: {
    flex: 1,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: Globals.colors.primaryLight,
    borderRadius: 8,
    padding: 16,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  classroomName: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  teacherName: {
    fontSize: 20,
  },
  numberOfStudents: {
    fontSize: 20,
  },
  goToClassroomButton: {
  },
  dialog: {
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    width: 500,
  },
  releaseNotesWrapper: {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: 200,
    overflow: 'auto',
  },
  note: {
    wordWrap: 'break-word',
    whiteSpace: 'pre-wrap',
    paddingLeft: 16,
    textIndent: -16,
    textAlign: 'left',
    marginTop: 0,
    marginBottom: 4,
  },
};

export default styles;
