/**
 * Ryan O'Dowd
 * 2024-02-12
 * © Copyright 2024 Oakwood Software Consulting, Inc. All Rights Reserved.
 */
import React, {
  useEffect,
} from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import CPassage from '../../../../classes/Passage';
import Passage from '../../../../components/Passage';
import PropTypes from 'prop-types';
import {
  fetchPassage,
} from '../../../../actions';
import styles from './styles';

// @TODO: needs more bottom padding if height is greater than device height
const Reading = (props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchPassage(props.passage_id));
  }, [props.passage_id, dispatch]);

  const passage = useSelector((state) => {
    const _p = state.passages[props.passage_id];
    if (!_p) {
      return {}; // @TODO: better loading state
    }
    const _pc = CPassage.createFromObject(_p);
    return _pc;
  }, (oldValue, newValue) => JSON.stringify(oldValue) === JSON.stringify(newValue)); // @TODO: is JSON.stringify sufficient and/or performant enough?

  return (
    <div style={styles.container}>
      {/* @TODO: option to display verses */}
      {/* @TODO: option to edit
        - translation
        - book
        - chapter
        - start and end verses
        - make sure it's a valid passage
      */}
      <Passage// @TODO: why are verses in here selectable?
        id={props.passage_id}
        passage={passage}
        goalId={props.goalId} // @TODO: need this?
        normalizedScore={0.8}// @TODO: update
        isExpanded={false}
        isPressDisabled={true}
      />
      {/* @TODO: too much white space after reading component */}
    </div>
  );
};

Reading.propTypes = {
  id: PropTypes.number.isRequired,
  // @TODO: created_at: PropTypes.string.isRequired,
  // @TODO: updated_at: PropTypes.string.isRequired,
  passage_id: PropTypes.number.isRequired,
  goalId: PropTypes.number.isRequired,
};

export default Reading;
