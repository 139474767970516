/*
 * Ryan O'Dowd
 * 2022-07-17
 * © Copyright 2022 Oakwood Software Consulting, Inc.  All Rights Reserved.
 */
import Globals from '../../../Globals';

const styles = (lightBackground) => ({
  container: {
    width: 'clamp(600px, 100%, 100%)',
    maxWidth: '100%',
    marginBottom: 8,
    color: Globals.colors.primaryExtraLight,
    backgroundImage: lightBackground
      ? `linear-gradient(
        ${Globals.colors.extraLightGray} 0%,
        ${Globals.colors.lightGray} 100%
      )`
      : `linear-gradient(
        ${Globals.colors.primary} 0%,
        ${Globals.colors.primaryDark} 100%
      )`,
  },
  containerInner: {
    color: lightBackground ? Globals.colors.primaryExtraDark : Globals.colors.primaryExtraLight,
  },
  accordionDetails: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  pagination: {
    color: lightBackground ? Globals.colors.primaryExtraDark : Globals.colors.primaryExtraLight,
  },
  notVisible: {
    visibility: 'hidden',
    height: 16,
  },
  titleAndSubtitle: {
    display: 'flex',
    flexDirection: 'column',
  },
  h3: {
    margin: 0,
  },
  h5: {
    margin: 0,
  },
  pressableRow: {
    cursor: 'pointer',
  },

  headerCell: {
    fontWeight: 'bold',
  },
  totalCell: {
    flex: 1,
    borderColor: Globals.colors.primary,
    borderTopWidth: 2,
    fontWeight: 'bold',
  },
  progressWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    alignSelf: 'flex-end',
  },
  progressBar: {
    width: 100,
  },
  infinityProgress: {
    fontSize: 20,
    color: Globals.colors.complete,
  },
  progressCell: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },

  filtersWrapper: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  filtersLeft: {
    flexDirection: 'column',
    paddingTop: 12,
    paddingBottom: 12,
  },
  filtersRight: {
  },
});

export default styles;
