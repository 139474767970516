// @TODO: may eventually want to break this out into multiple utility files...like a utilities/ folder with files inside
/**
 * Ryan O'Dowd
 * 2022-03-15
 * © Copyright 2022 Oakwood Software Consulting, Inc. All Rights Reserved.
 */
import {
  apiSaveLesson,
  fetchVerses,
  getRequestHeaders,
} from '../actions';
import {
  add as dateFnsAdd,
  format as dateFnsFormat,
} from 'date-fns';
import {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import Globals from '../Globals';
import commonUtilities from '../common/utilities';
import {
  i18nUnitsTime,
} from '../i18n';
import oakGlobalObject from '../common/utilities/oakGlobalObject';
import platformUtilities from './platformUtilities';
import {
  store,
} from '../Store';
import {
  useSelector,
} from 'react-redux';
import {
  v4 as uuidv4,
} from 'uuid';

function prettyPassageName(passage) { // @TODO: remove (replace with Passage.prettyPassageName())
  return `${getBookName(passage.translation_id, passage.book_id)} ${passage.chapter_number}:${passage.start_verse_number}${passage.start_verse_number !== passage.end_verse_number ? `-${passage.end_verse_number}` : ''}`; // @TODO: make sure this doesn't display `Infinity` for start or end verses (issue in passage selection on web) // @TODO: fix before prod
}

function _getLocaleCode() {
  return store.getState().oakUser?.locale_code || 'en';
}

function getBookName(translationId, bookId) { // @TODO: can this be memoized?
  return store.getState().bookNames[translationId]?.[bookId]?.name || ''; // @TODO: better default value
}

function getTranslation(translationId) { // @TODO: can this be memoized?
  return store.getState().translations.find((t) => t.id === translationId);
}

function getBookById(bookId) { // @TODO: can this be memoized?
  return store.getState().books.find((b) => b.id === bookId);
}

function getBookFromApiId(bookApiId) { // @TODO: can this be memoized?
  return store.getState().books.find((b) => b.api_id === bookApiId);
}

function getBookFromChapterId(chapterId) {
  return store.getState().books.find((b) => b.id === store.getState().chapters.find((c) => c.id === chapterId)?.book_id);
}

function getChapterById(chapterId) {
  return store.getState().chapters.find((c) => c.id === chapterId);
}

function getChapterByBookIdAndNumber(bookId, chapterNumber) {
  return store.getState().chapters.find((c) => c.book_id === bookId && c.chapter_number === chapterNumber);
}

/* @TODO:
function getVerseIdFromTranslationIdBookIdChapterNumberAndVerseId(translationId, bookId, chapterNumber, verseNumber) {
  return store.getState().verses?.[translationId]?.books?.[bookId]?.chapters?.[chapterNumber]?.verses?.[verseNumber]?.verseId;
}
*/

function getVerseApiId(bookApiId, chapterNumber, verseNumber) {
  return `${bookApiId}.${chapterNumber}.${verseNumber}`;
}

function capitalizeFirstLetter(s) {
  // https://stackoverflow.com/questions/1026069/how-do-i-make-the-first-letter-of-a-string-uppercase-in-javascript
  return s.charAt(0).toUpperCase() + s.slice(1);
}

function humanReadableScore(score) { // @TODO: are these suffixes different for different languages?
  let suffix = '';
  if (score > 1000000000) {
    score /= 1000000000;
    suffix = 'B';
  } else if (score > 1000000) {
    score /= 1000000;
    suffix = 'M';
  } else if (score > 1000) {
    score /= 1000;
    suffix = 'K';
  }

  score = Math.floor(score * 10) / 10;
  const scoreRet = `${score}${suffix}`;

  const localeCode = _getLocaleCode();
  if (localeCode !== 'en') {
    return scoreRet.replace('.', ','); // @TODO: is this true of all none en langs?
  }

  return scoreRet;
}

function humanReadableTime(ms) {
  let denomination = ms;
  let units = 'millisecond';
  if (denomination / 1000 >= 1) {
    denomination /= 1000;
    units = 'second';
    if (denomination / 60 >= 1) {
      denomination /= 60;
      units = 'minute';
      if (denomination / 60 >= 1) {
        denomination /= 60;
        units = 'hour';
      }
    }
  }

  const localeCode = _getLocaleCode();
  if (localeCode !== 'en') {
    units = i18nUnitsTime[localeCode][units];
  }

  denomination = Math.floor(denomination);
  const retVal = `${denomination} ${units}${denomination === 1 ? '' : 's'}`;

  if (localeCode !== 'en') {
    return retVal.replace('.', ','); // @TODO: is this true of all non-en langs?
  }

  return retVal;
}

function humanReadableTimeExact(ms) {
  const hours = Math.floor(ms / (60 * 60 * 1000));
  const minutes = Math.floor((ms - (hours * 60 * 60 * 1000)) / (60 * 1000));
  const seconds = Math.floor((ms - (hours * 60 * 60 * 1000) - (minutes * 60 * 1000)) / 1000);

  if (hours > 0) {
    return `${hours}h ${minutes}m ${seconds}s`;
  } else if (minutes > 0) {
    return `${minutes}m ${seconds}s`;
  }

  return `${seconds}s`;
}

function humanReadableTimeDashboard(ms) {
  let denomination = ms;
  let units = 'millisecond';
  if (denomination / 1000 >= 1) {
    denomination /= 1000;
    units = 'second';
    if (denomination / 60 >= 1) {
      denomination /= 60;
      units = 'minute';
      /* @TODO: wait until this is in the 100s/1000s of hours (and then just combine with humanReadableTime)
      if (denomination / 60 >= 1) {
        denomination /= 60;
        units = 'hour';
      }
      */
    }
  }

  denomination = Math.floor(denomination);
  return `${denomination} ${units}${denomination === 1 ? '' : 's'}`;
}

function humanReadableNumber(numVerses) { // @TODO: are these suffixes different for different languages?
  let denomination = numVerses; // @TODO: why is this called denomination?
  let units = '';
  if (denomination / 1000 >= 1) {
    denomination /= 1000;
    units = 'k';
    if (denomination / 1000 >= 1) {
      denomination /= 1000;
      units = 'm';
      if (denomination / 1000 >= 1) {
        denomination /= 1000;
        units = 'b';
      }
    }
  }

  if (['b'].includes(units)) {
    // 2 decimals places is better for larger numbers
    denomination = Math.floor(denomination * 100) / 100;
  } else {
    denomination = Math.floor(denomination * 10) / 10;
  }
  const retVal = `${denomination}${units}`;

  const localeCode = _getLocaleCode();
  if (localeCode !== 'en') {
    return retVal.replace('.', ','); // @TODO: is this true of all none en langs?
  }

  return retVal;
}

function humanReadableBytes(bytes) { // @TODO: are these suffixes different for different languages?
  let amount = bytes;
  let units = 'bytes'; // @TODO: i18n
  if (amount / 1000 >= 1) {
    amount /= 1000;
    units = 'kB';
    if (amount / 1000 >= 1) {
      amount /= 1000;
      units = 'MB';
      if (amount / 1000 >= 1) {
        amount /= 1000;
        units = 'GB';
      }
    }
  }

  amount = Math.floor(amount * 100) / 100;
  return `${amount} ${units}`;
}

function prettyCurrency(pCents) { // @TODO: can maybe replace with .toLocalString or something similar
  let [dollars, cents] = `${pCents / 100}`.split('.');
  if (dollars.length > 4) {
    dollars = dollars.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
  cents = cents ? `.${(cents || '0').padEnd(2, '0')}` : '';

  return `$${dollars || ''}${cents}`;
}

function usePreferredTranslation() {
  return useSelector((state) => state.translations.find((translation) => translation.id === (state.userSettings.preferred_translation_id || 3)));
}

function useIsAdmin() {
  return useSelector((state) => {
    if (Globals.platform === 'web' && sessionStorage.getItem('masqueradeAsUserId')) {
      return false;
    }

    return Globals.adminAuthIds.includes(state.oakUser?.auth_id);
  });
}

function useGetWrinkles(srsLevelThreshold = null, excludeWaiting = false) { // @TODO: this might not be performant
  return useSelector((state) => {
    const completed = [];
    const inProgress = [];
    Object.values(state.wrinkles).forEach((wrinkleVariants) => {
      wrinkleVariants.forEach((wv) => {
        if ((!srsLevelThreshold && !excludeWaiting) || ((!srsLevelThreshold || wv.level_number <= srsLevelThreshold) && (!excludeWaiting || (new Date(wv.date_next_srs_level).getTime() <= Date.now())))) {
          const wrinkleVariant = {...wv};

          let arrayOverride;
          if (srsLevelThreshold || excludeWaiting) {
            // @TODO: there's a better way to say "return only one list with in-progress and complete combined" than to do `(srsLevelThreshold || excludeWaiting)`
            arrayOverride = inProgress;
          }

          if (wrinkleVariant.level_number >= Globals.srsLevelWrinkleIsEarnedAt) {
            (arrayOverride || completed).push(wrinkleVariant);
          } else if (wrinkleVariant.level_number > 0) {
            (arrayOverride || inProgress).push(wrinkleVariant);
          }
        }
      });
    });

    const _sortWrinkles = (w1, w2) => {
      // do translations first, otherwise grouping into passages won't work
      return w1.translation_id - w2.translation_id || w1.verse_id - w2.verse_id;
    };

    completed.sort(_sortWrinkles);
    inProgress.sort(_sortWrinkles);

    const _getBookAndChatperFromApiId = (verseApiId) => {
      return verseApiId.split('.').slice(0, 2).join('.');
    };

    const _groupWrinkles = (wrinkles) => {
      const grouped = [];

      let currSequence = [];
      let prevWrinkle;
      for (let i = 0; i < wrinkles.length; i++) {
        const currWrinkle = wrinkles[i];

        if (!currSequence.length || ((prevWrinkle.verse_id === currWrinkle.verse_id - 1) && (prevWrinkle.translation_id === currWrinkle.translation_id && _getBookAndChatperFromApiId(prevWrinkle.verse_api_id) === _getBookAndChatperFromApiId(currWrinkle.verse_api_id)))) {
          // this is a sequential verse in the same translation, book, and chapter as the previous wrinkle (or it's the first verse in the sequence)
          currSequence.push(currWrinkle);
        } else {
          // this is a new passage...not a sequential verse
          grouped.push(currSequence);
          currSequence = [currWrinkle];
        }

        prevWrinkle = currWrinkle;
      }
      if (currSequence.length) {
        grouped.push(currSequence);
      }

      return grouped;
    };

    return [_groupWrinkles(completed), _groupWrinkles(inProgress)];
  }, (oldValue, newValue) => {
    // @TODO: would love to figure out why this doesn't work...oldValue is always out of order: const isEqual = JSON.stringify(oldValue) === JSON.stringify(newValue);
    const olds = JSON.stringify([
      oldValue[0].map((wg) => wg.map((w) => `${w.translation_id}-${w.verse_id}-${w.date_last_passed}`)).sort(),
      oldValue[1].map((wg) => wg.map((w) => `${w.translation_id}-${w.verse_id}-${w.date_last_passed}`)).sort(),
    ]);
    const news = JSON.stringify([
      newValue[0].map((wg) => wg.map((w) => `${w.translation_id}-${w.verse_id}-${w.date_last_passed}`)).sort(),
      newValue[1].map((wg) => wg.map((w) => `${w.translation_id}-${w.verse_id}-${w.date_last_passed}`)).sort(),
    ]);

    return olds === news;
  }); // @TODO: is JSON.stringify sufficient and/or performant enough?
}

function useGetVersesForPassages(passages, skipFetch) {
  const passagesString = JSON.stringify(passages);
  const globalStore = store.getState();
  useEffect(() => {
    if (skipFetch) {
      return;
    }

    passages.forEach((p) => {
      const translationId = p.translation_id || p.translationId;
      const bookId = p.book_id || p.bookId;
      const chapterNum = p.chapter_number || p.chapterNumber;
      const startVerseNumber = p.start_verse_number || p.startVerseNumber;
      const endVerseNumber = p.end_verse_number || p.endVerseNumber;

      if (translationId && bookId && chapterNum && startVerseNumber && endVerseNumber) {
        // it shouldn't be often that any of these are null/undefined, but when they are, don't do a fetch
        const globalStoreVerses = globalStore.verses?.[translationId]?.books?.[bookId]?.chapters?.[chapterNum]?.verses;
        const missingVerseNumbers = [];
        for (let verseNum = startVerseNumber; verseNum <= endVerseNumber; verseNum++) {
          const verse = globalStoreVerses?.[verseNum]; // @TODO: abstract this path with what's in useselector below
          if (verse === undefined || verse === null) { // @TODO: is this sufficient?
            missingVerseNumbers.push(verseNum);
          }
        }

        if (missingVerseNumbers.length) {
          store.dispatch(fetchVerses(translationId, bookId, chapterNum, missingVerseNumbers));
        }
      }
    });
  }, [passagesString, skipFetch]); // @TODO: wish eslint were okay with this...if `passages` is in the dep array, it's different on every re-render // @TODO: dep array should include translation.updated_at timestamp (do at the verse level?)

  const lastFetchVersesTimestamp = useSelector((state) => state.lastFetchVersesTimestamp);

  return useMemo(() => {
    const versesByPassageId = {};
    return passages.reduce((acc, p) => {
      const translationId = p.translation_id || p.translationId; // @TODO: can't read `translation_id` of `undefined`
      const bookId = p.book_id || p.bookId;
      const book = globalStore.books.find((b) => b.id === bookId);
      const chapterNum = p.chapter_number || p.chapterNumber;
      const startVerseNumber = p.start_verse_number || p.startVerseNumber;
      const endVerseNumber = p.end_verse_number || p.endVerseNumber;

      const verses = [];
      for (let verseNum = startVerseNumber; verseNum <= endVerseNumber; verseNum++) {
        const loadingVerse = {
          isLoading: true,
          verseNum,
          verseApiId: `${book.api_id}.${chapterNum}.${verseNum}`,
          translationId,
        }; // @TODO: maybe create a Verse class?
        const verseInRedux = globalStore.verses?.[translationId]?.books?.[bookId]?.chapters?.[chapterNum]?.verses?.[verseNum];
        verses.push(!verseInRedux ? loadingVerse : {...verseInRedux, translationId});
      }
      versesByPassageId[p.id] = verses;

      return {...acc, [p.id]: verses};
    }, {});
  }, [lastFetchVersesTimestamp, passagesString]); // @TODO: wish eslint were okay with this...if `passages` is in the dep array, it's different on every re-render // @TODO: dep array missing others?
}

function useGetWrinkleForVerseApiId(verseApiId, translationId) { // @TODO: abstract Passage and PassageDisplay (translation_id different) these component should use this hook
  return useSelector((state) => {
    let highestLevelWrinkle = null;
    state.wrinkles[verseApiId]?.forEach((w) => {
      if (!(translationId && w.translation_id !== translationId)) {
        if (!highestLevelWrinkle || w.level_number > highestLevelWrinkle.level_number) {
          highestLevelWrinkle = w;
        }
      }
    });

    return highestLevelWrinkle;
  });
}

async function createNewPassage(translationId, bookId, chapterNumber, startVerseNumber, endVerseNumber) {
  const response = await fetch(`${Globals.apiUrl}/passages`, {
    method: 'POST',
    headers: {
      ...await getRequestHeaders(), // @TODO: is this how we do auth for fetch api?
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      translation_id: translationId,
      book_id: bookId,
      chapter_number: chapterNumber,
      start_verse_number: startVerseNumber,
      end_verse_number: endVerseNumber,
    }),
  }); // @TODO: add auth headers
  if (response.status !== 200) {
    return null; // @TODO: throw error to user
  }
  const responseJson = await response.json();

  return await responseJson.passage;
}

function removeVerseFormatting(verseText) {
  return verseText?.trim().replace(/([ ]+)?\n([ ]+)?/g, ' ') || ''; // @TODO: don't replace with space if dash or elipsis
}

function useSelectVerses(defaultState) {
  const [_selectedVerseNums, setSelectedVerseNums] = useState(defaultState);

  const minSelectedVerseNum = Math.min(..._selectedVerseNums);
  const maxSelectedVerseNum = Math.max(..._selectedVerseNums);

  const onVersePress = (verseNum) => {
    if (_selectedVerseNums.includes(verseNum)) {
      if (![minSelectedVerseNum, maxSelectedVerseNum].includes(verseNum)) {
        return;
      }
      setSelectedVerseNums((curr) => curr.filter((vn) => vn !== verseNum));
    } else {
      const newVerseNums = [verseNum];
      if (_selectedVerseNums.length) {
        if (verseNum > maxSelectedVerseNum) {
          for (let vn = maxSelectedVerseNum; vn <= verseNum; vn++) {
            newVerseNums.push(vn);
          }
        } else {
          for (let vn = verseNum; vn <= minSelectedVerseNum; vn++) {
            newVerseNums.push(vn);
          }
        }
      }
      setSelectedVerseNums((curr) => [...new Set([...curr, ...newVerseNums])].sort((a, b) => a - b));
    }
  };

  return [
    _selectedVerseNums,
    onVersePress,
    () => setSelectedVerseNums([]),
    (totalNumVerses) => setSelectedVerseNums([...Array(totalNumVerses)].map((_, index) => index + 1)),
  ];
}

function useTimeRemaining(wrinkle, isPaused) {
  const _getSecondsUntilNow = useCallback((timestamp) => {
    if (!timestamp) {
      return 0;
    }

    return (new Date(timestamp).valueOf() - Date.now()) / 1000;
  }, []);

  const [_secondsRemaining, setSecondsRemaining] = useState(0);

  useEffect(() => {
    setSecondsRemaining(_getSecondsUntilNow(wrinkle?.date_next_srs_level));
  }, [wrinkle?.date_next_srs_level, _getSecondsUntilNow]);

  const _prettyTimeDiff = (numSeconds) => {
    if (numSeconds <= 0) {
      return ''; // button text should never be null, but when we level up, the old wrinkle date_last_passed value will be displayed until the next render
    }
    const days = Math.floor(numSeconds / 86400);
    const hours = Math.floor((numSeconds % 86400) / 3600);
    const minutes = Math.floor((numSeconds % 3600) / 60);
    const seconds = Math.floor((numSeconds % 60) % 60);

    if (days) {
      return `level up in ${days} days`;
    }

    return `${hours || ''}${hours ? ':' : ''}${`${minutes}`.padStart(2, '0')}:${`${seconds}`.padStart(2, '0')}`;
  };

  useEffect(() => {
    if (!isPaused && wrinkle?.date_next_srs_level && _secondsRemaining >= 0 && _secondsRemaining < 86400) {
      if (_secondsRemaining === 0) {
        setSecondsRemaining(_getSecondsUntilNow(wrinkle?.date_next_srs_level));
      }
      const timer = setInterval(() => setSecondsRemaining(_getSecondsUntilNow(wrinkle?.date_next_srs_level)), 1000);
      return () => clearInterval(timer);
    }
  }, [isPaused, wrinkle?.date_next_srs_level, _secondsRemaining, _getSecondsUntilNow]);

  return _prettyTimeDiff(_secondsRemaining);
}

function isWrinkleTestOpen(wrinkle) { // @TODO: there should be a wrinkle model/class on the front end, and this should be a class method
  return new Date(wrinkle?.date_next_srs_level).valueOf() <= Date.now();
}

function getGoalProgress(goalId) { // @TODO: in theory, it seems like this shouldn't be performant enough for the front end to do, but in practice, i think it's fine // @TODO: now that we have modules and assignments, i think this should come from the back end
  const goal = store.getState().goals[goalId];

  if (!goal) {
    return [0, 0, 0];
  }

  let numCompleted = 0;
  let numInProgress = 0;
  let numUnstarted = 0;

  if (goal.goal_type === 'group') {
    goal.modules?.forEach((module) => { // @TODO: maybe this should be normalized by length (memorizing 100 verses is going to be a lot more than reading 10 for example. even memorizing 10 is mroe than reading 100) // @TODO: shouldn't need the null check here, but might require redux migration to avoid
      module.assignments.forEach((assignment) => {
        if (assignment.is_completed) {
          numCompleted++;
        } else if (assignment.num_peanuts_earned) {
          // @TODO: technically, this could be inaccurate depending on how "in-progrss" is defined for must_memorize; this is by peanuts only, not by wrinkle progress. good enough for now but something to keep in mind for the future
          numInProgress++;
        } else {
          numUnstarted++;
        }
      });
    });
  } else {
    goal.modules?.forEach((module) => { // @TODO: maybe this should be normalized by length (memorizing 100 verses is going to be a lot more than reading 10 for example. even memorizing 10 is mroe than reading 100) // @TODO: shouldn't need the null check here, but might require redux migration to avoid
      module.assignments.forEach((assignment) => {
        const passage = assignment.assignment_object.passage;
        if (!passage && !assignment.is_completed) {
          // this is a lecture or reading (not a verse memory)
          numUnstarted++;
        } else {
          const passageVerseApiIds = [];
          const book = store.getState().books.find((b) => b.id === passage.book_id);
          for (let verseNum = passage.start_verse_number; verseNum <= passage.end_verse_number; verseNum++) {
            passageVerseApiIds.push(getVerseApiId(book.api_id, passage.chapter_number, verseNum));
          }

          const wrinkles = store.getState().wrinkles;
          for (const verseApiId of passageVerseApiIds) {
            const wrinkle = wrinkles[verseApiId]?.find((w) => w.translation_id === passage.translation_id);
            if (!wrinkle) {
              // @TODO: i don't understand why some of these won't have any wrinkle and why increment numUnstarted in the `else` block below gets hit other times
              numUnstarted++;
              continue;
            }

            if (wrinkles[verseApiId].some((w) => w.translation_id === passage.translation_id)) {
              if (wrinkle.level_number >= 3) {
                numCompleted++;
              } else if (wrinkle.level_number > 0) {
                numInProgress++;
              } else {
                numUnstarted++;
              }
            }
          }
        }
      });
    });
  }

  return [numCompleted, numInProgress, numUnstarted];
}

function isNewAppVersionWithReleaseNotesAvailable() { // @TODO: move to commonUtilities
  return store.getState().whatsNewNotes.some((n) => {
    const releaseDatePlusOneDay = dateFnsAdd(new Date(n.release_date), {days: 1});
    return (
      (!commonUtilities.isSecondOlderOrSameAsFirst(Globals.appVersionNumber, n.app_version)) && // note for a newer app version // @TODO: this is always true (difference in logic between showing NewVersion (not on most recent version) and showing badge on WhatsNew (on current version but have unread notes))
      (new Date(releaseDatePlusOneDay).getTime() < new Date().getTime()) // give up to 24 hours or grace period for update to propogate
    );
  });
}

function prettyAvailablityDateRange(startDate, endDate) {
  const formattedStart = dateFnsFormat(new Date(startDate ?? new Date()), 'MMM d, yyyy');
  const formattedEnd = dateFnsFormat(new Date(endDate ?? new Date()), 'MMM d, yyyy');

  if (!startDate && !endDate) {
    return 'Available any time';
  } else if (startDate && !endDate) {
    return `Available from ${formattedStart}`;
  } else if (!startDate && endDate) {
    return `Due by ${formattedEnd}`;
  }

  return `Available ${formattedStart} - ${formattedEnd}`;
}

function saveLesson({
  userId,
  passageId,
  millisecondsSpent,
  peanutsEarned,
  difficulty,
  numberOfQuestions,
  numberOfVersesReviewed,
  minigameIds,
  goalId,
  testPassedVerseNums,
  assignmentId,
  adHocPassage,
  streakRepairDate,
  passageOfTheDayId,
  numberOfSkippedQuestions,
}) { // @TODO: throw error if any of these are missing/named incorectly
  store.dispatch(apiSaveLesson(
    userId,
    uuidv4(),
    goalId,
    passageId, // @TODO: would be nice to save an array of passages in the database, but this will require schema changes
    assignmentId,
    difficulty,
    millisecondsSpent,
    peanutsEarned,
    numberOfQuestions,
    numberOfVersesReviewed,
    minigameIds,
    streakRepairDate,
    adHocPassage,
    testPassedVerseNums,
    Date.now(),
    passageOfTheDayId,
    dateFnsFormat(new Date(), 'yyyy-MM-dd'),
    undefined, // no error yet
    numberOfSkippedQuestions,
  ));

  // try to save previously completed lessons that failed to upload as well
  // @TODO: should i move this retry into actions?
  Object.values(store.getState().completedLessons).forEach((lesson) => { // @TODO: this should maybe be run on app open...not just on each lesson-save attempt?
    store.dispatch(apiSaveLesson(
      userId,
      lesson.uuid,
      lesson.goalId,
      lesson.passageId,
      lesson.assignmentId,
      lesson.difficulty,
      lesson.millisecondsSpent,
      lesson.peanutsEarned,
      lesson.numberOfQuestions,
      lesson.numberOfVerses,
      lesson.minigameIds,
      lesson.repairStreakDate,
      lesson.adHocPassage,
      lesson.testPassedVerseNums,
      lesson.dateCompleted,
      lesson.passageOfTheDayId,
      lesson.streakDateTz,
      lesson.error,
      lesson.numberOfSkippedQuestions,
    ));
  });
}

const isVerseWrinkled = (wrinkles, verseApiId, translationId) => { // @TODO: need this function?
  const wrinkleVariations = wrinkles[verseApiId] || [];
  return wrinkleVariations.some((wrinkle) => {
    if (wrinkle.level_number < Globals.srsLevelWrinkleIsEarnedAt) {
      return false;
    } else if (translationId && wrinkle.translation_id !== translationId) {
      return false;
    }

    return true;
  });
};

const isVerseWrinkleInProgress = (wrinkles, verseApiId, translationId) => { // @TODO: can memoize return of this for same args // @TODO: most of this can be abstracted with isVerseWrinkled // @TODO: need this function?
  const wrinkleVariations = wrinkles[verseApiId] || [];
  return wrinkleVariations.some((wrinkle) => {
    if (wrinkle.level_number >= Globals.srsLevelWrinkleIsEarnedAt || wrinkle.level_number === 0) {
      return false;
    } else if (translationId && wrinkle.translation_id !== translationId) {
      return false;
    }

    return true;
  });
};

const isVerseWrinkleUnstarted = (wrinkles, verseApiId, translationId) => { // @TODO: can memoize return of this for same args // @TODO: most of this can be abstracted with isVerseWrinkled // @TODO: need this function?
  const wrinkleVariations = wrinkles[verseApiId] || [];
  return wrinkleVariations.some((wrinkle) => {
    if (!wrinkle.level_number === 0) {
      return false;
    } else if (translationId && wrinkle.translation_id !== translationId) {
      return false;
    }

    return true;
  });
};

const isChapterWrinkled = (wrinkles, book, chapterNum) => { // @TODO: can memoize return of this for same args // @TODO: need this function?
  for (let i = 1; i <= book.chapters_and_verses[chapterNum]; i++) {
    if (!isVerseWrinkled(wrinkles, getVerseApiId(book.api_id, chapterNum, i), true)) {
      return false;
    }
  }

  return true;
};

const isBookWrinkled = (wrinkles, book) => { // @TODO: can memoize return of this for same args // @TODO: need this function?
  for (let i = 1; i <= Object.keys(book.chapters_and_verses).length; i++) {
    if (!isChapterWrinkled(wrinkles, book, i)) {
      return false;
    }
  }

  return true;
};

function getReferenceMatches(searchText) {
  function _parseReference(inputString) {
    const regex = /^(\d?\s?[a-zA-Z\s]+)(?:\s+(\d+))?(?:[\s:]+(\d+))?(?:[-](\d+))?$/;
    const match = inputString.trim().match(regex);

    if (!match) {
      return null; // No match, return null
    }

    const bookName = match[1].trim();
    const chapterNumber = match[2] ? parseInt(match[2], 10) : null;
    const startVerseNumber = match[3] ? parseInt(match[3], 10) : null;
    const endVerseNumber = match[4] ? parseInt(match[4], 10) : null;

    return {
      bookName,
      chapterNumber,
      startVerseNumber,
      endVerseNumber,
    };
  }

  const parsedReference = _parseReference(searchText);
  if (!parsedReference) {
    return [];
  }

  return store.getState().books.filter((book) => {
    let bookAlias = [book.name];
    if (book.id === 19) { // Psalm
      bookAlias = ['Psalm', 'Psalms'];
    } else if (book.id === 22) { // Song of Solomon
      bookAlias = ['Song of Songs', 'Songs of Songs', 'Songs of Solomon', 'Song of Solomon'];
    }

    return bookAlias.some((alias) => alias.toLocaleLowerCase().includes(parsedReference.bookName.toLocaleLowerCase()));
  }).map((book) => {
    const numVersesInChapter = book.chapters_and_verses[parsedReference.chapterNumber];
    if (parsedReference.chapterNumber && !numVersesInChapter) {
      // chapter doesn't exist in book
      return null;
    } else if (
      (parsedReference.startVerseNumber && ((parsedReference.startVerseNumber < 1) || (parsedReference.startVerseNumber > numVersesInChapter))) ||
      (parsedReference.endVerseNumber && ((parsedReference.endVerseNumber < 1) || (parsedReference.endVerseNumber > numVersesInChapter))) ||
      (parsedReference.startVerseNumber && parsedReference.endVerseNumber && (parsedReference.startVerseNumber > parsedReference.endVerseNumber))
    ) {
      // given verse(s) don't exist in this chapter, or the order doesn't make sense
      return null;
    }

    return {
      book_id: book.id,
      chapter_number: parsedReference.chapterNumber,
      start_verse_number: parsedReference.startVerseNumber,
      end_verse_number: parsedReference.endVerseNumber,
    };
  }).filter((reference) => reference);
}


export default oakGlobalObject({
  camelCaseToHumanReadable: commonUtilities.camelCaseToHumanReadable,
  isIphoneWithCutout: commonUtilities.isIphoneWithCutout,
  isSecondOlderOrSameAsFirst: commonUtilities.isSecondOlderOrSameAsFirst,
  linkUserAndCredential: commonUtilities.linkUserAndCredential,
  memoAreEqual: commonUtilities.memoAreEqual,
  useBackButton: commonUtilities.useBackButton,
  useStyles: commonUtilities.useStyles,
  useI18n: commonUtilities.useI18n,
  getHeaderHeight: commonUtilities.getHeaderHeight,
  rebuildEStyleSheet: commonUtilities.rebuildEStyleSheet,

  // @TODO: getPassageObjectTODO: platformUtilities.getPassageObjectTODO,
  prettyDiff: platformUtilities.prettyDiff,
  uploadPhoto: platformUtilities.uploadPhoto,
  scheduleStreakReminders: platformUtilities.scheduleStreakReminders,
  scheduleDailyGoalsReminders: platformUtilities.scheduleDailyGoalsReminders,
  logout: platformUtilities.logout,

  useGetVersesForPassages,
  useSelectVerses,
  useTimeRemaining,
  useGetWrinkleForVerseApiId,
  useIsAdmin,

  getBookName,
  getBookFromApiId,
  getTranslation,
  getChapterById,
  getChapterByBookIdAndNumber,
  getBookFromChapterId,
  getBookById,
  // @TODO: getVerseIdFromTranslationIdBookIdChapterNumberAndVerseId,
  getVerseApiId,

  prettyPassageName,

  humanReadableScore,
  humanReadableTime,
  humanReadableTimeExact,
  humanReadableTimeDashboard,
  humanReadableNumber,
  humanReadableBytes,
  prettyCurrency,

  capitalizeFirstLetter,

  createNewPassage,

  removeVerseFormatting,

  getGoalProgress,

  isNewAppVersionWithReleaseNotesAvailable,

  prettyAvailablityDateRange,

  useGetWrinkles,
  usePreferredTranslation,

  isWrinkleTestOpen,

  saveLesson,

  isVerseWrinkled,
  isVerseWrinkleInProgress,
  isVerseWrinkleUnstarted,
  isChapterWrinkled,
  isBookWrinkled,

  getReferenceMatches,
});
