/*
 * Ryan O'Dowd
 * 2024-09-27
 * © Copyright 2024 Oakwood Software Consulting, Inc. All Rights Reserved.
 */
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tooltip,
} from '@mui/material';
import LinearProgress from '../../../../../common/components/OakLinearProgress';
import OakTable from '../../../../../common/components/OakTable';
import PropTypes from 'prop-types';
import React from 'react';
import {
  format as dateFnsFormat,
} from 'date-fns';
import styles from './styles';
import {
  useSelector,
} from 'react-redux';

const AssignmentProgressDialog = (props) => {

  const goalProgress = useSelector((state) => state.goalProgress[props.classroomGoal.goal_id]) || {};
  const classroomStudents = useSelector((state) => state.classroomStudents[props.classroomGoal.classroom_id] || []);

  const studentTableRows = Object.entries(goalProgress).map(([studentId, {assignments}]) => { // @TODO: this could probably be passed in from ClassroomGoal table data and filtered instead of calculating again
    const thisAssignment = assignments[props.assignment.id] || {};
    const numCompleted = thisAssignment.num_lessons_completed || 0;
    const numLessonsRequired = props.assignment.assignment_object.num_lessons_to_complete || 1;
    const progressPercent = Math.floor(numCompleted / numLessonsRequired * 100);
    const classroomStudent = classroomStudents.find((cs) => +cs.user_id === +studentId || +cs.user?.id === +studentId);

    return [ // @TODO: update ids
      {
        id: `${studentId}_display_name`,
        content: (
          <Tooltip title={classroomStudent?.user?.display_name}>
            <span>
              {classroomStudent?.display_name || classroomStudent?.user?.display_name}
            </span>
          </Tooltip>
        ),
      },
      // @TODO: {id: `${studentId}_date_started`, content: thisAssignment.date_started ? <Tooltip title={dateFnsFormat(thisAssignment.date_started, 'MMM d, yyyy, h:mm a')}><span>{dateFnsFormat(thisAssignment.date_started, 'MMM d')}</span></Tooltip> : '-'},
      // @TODO: {id: `${studentId}_date_completed`, content: thisAssignment.date_completed ? <Tooltip title={dateFnsFormat(thisAssignment.date_completed, 'MMM d, yyyy, h:mm a')}><span>{dateFnsFormat(thisAssignment.date_completed, 'MMM d')}</span></Tooltip> : '-'},
      // @TODO: date last worked on?
      {
        id: `${studentId}_progress`,
        content: (
          <Tooltip title={`${numCompleted} of ${numLessonsRequired} lessons completed`}>
            <span>
              <LinearProgress progressPercent={progressPercent} />
            </span>
          </Tooltip>
        ),
      },
    ];
  });

  return (
    <Dialog
      style={styles.container}
      maxWidth='lg'
      open={true}
      onClose={() => props.onClose()} // @TODO: ask about unsaved changes
      disableRestoreFocus={true}// https://github.com/mui/material-ui/issues/33004#issuecomment-1473299089
    >
      <DialogTitle id='alert-dialog-title'>
        <span style={styles.mainTitle}>{props.assignment.name}</span>
      </DialogTitle>
      <DialogContent style={styles.innerContainer}>
        <OakTable
          tableTitle='Student progress'
          open={true}
          disableAccordion={true}
          headerRowData={[// @TODO: allow for sorting based on this
            'Name',
            // @TODO: 'Date started',
            // @TODO: 'Date completed',
            'Progress',
          ]}// @TODO: some of these should be named better and/or have tooltips
          rowData={studentTableRows}
          // @TODO: onRowPress={(row) => setSelectedStudentId(+row[0].id.split('_')[0])}// @TODO: way too hacky
          filtersJsx={null
            // @TODO: show/hide completed, etc.
          }
        />
      </DialogContent>
      <DialogActions>
        <Button
          color='primary'
          variant='contained'
          onClick={() => props.onClose()}
        >
          Done
        </Button>
      </DialogActions>
    </Dialog>
  );
};

AssignmentProgressDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  assignment: PropTypes.object.isRequired, // @TODO: shape
  classroomGoal: PropTypes.object.isRequired, // @TODO: shape
};

export default AssignmentProgressDialog;
