/*
 * Ryan O'Dowd
 * 2024-01-24
 * © Copyright 2024 Oakwood Software Consulting, Inc.  All Rights Reserved.
 */
import {
  Card,
  CardContent,
  Tooltip,
} from '@mui/material';
import React, {
  useMemo,
} from 'react';
import Globals from '../../../Globals';
import {
  Link,
} from 'react-router-dom';
import PropTypes from 'prop-types';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import VerifiedIcon from '@mui/icons-material/Verified';
import styles from './styles';
import utilities from '../../../utilities';

const GoalPreview = (props) => { // @TODO: abstract this with MyCampaigns/CampaignPreview
  const statusIcon = useMemo(() => {
    if (props.classroomGoalMetadata.status === 'draft') {
      return <ReportProblemIcon style={{...styles.icon, ...styles.draftIcon}} />;
    }

    return <VerifiedIcon style={{...styles.icon, ...styles.publishedIcon}} />;
  }, [props.classroomGoalMetadata.status]);

  if (!props.classroomGoalMetadata || !props.goal) {
    return 'Loading...'; // @TODO: better loading
  }

  return (
    // @TODO: there should be a visual indication of active/inactive campaigns...maybe a button to toggle visibility too
    <Link
      style={styles.container}
      to={`/schools/${props.schoolId}/classrooms/${props.classroomId}/classroom_goals/${props.classroomGoalMetadata.id}`}
    >
      <Card
        sx={styles.campaignCard}
        onClick={() => {
          // @TODO: edit dialog
        }}
      >
        <CardContent style={{...styles.cardContent, ...{backgroundColor: props.classroomGoalMetadata.status === 'draft' ? Globals.colors.warningBg : Globals.colors.primaryExtraDark}}}>
          <span style={styles.nameAndStatus}>
            <h3 style={styles.goalName}>{props.goal.title}</h3>
            <Tooltip title={props.classroomGoalMetadata.status}>{statusIcon}</Tooltip>
          </span>
          <span style={styles.goalDates}>{utilities.prettyAvailablityDateRange(props.goal.date_available, props.goal.date_deadline)}</span>
          {/* @TODO: student progress...e.g. 4/12 students complete? */}
          {/* @TODO:
          <p>{props.description}</p>
          */}
          <div style={styles.goalMetadataWrapper}>
            <span style={styles.goalMetadata}># modules: {props.goal.modules.length}</span>
            <span style={styles.goalMetadata}># assignments: {props.goal.modules.reduce((total, module) => total + module.assignments.length, 0)}</span>
            <span style={styles.goalMetadata}># students: {props.classroomGoalMetadata.student_count}</span>
          </div>
        </CardContent>
      </Card>
    </Link>
  );
};

GoalPreview.propTypes = {
  // @TODO: add proptypes
  classroomId: PropTypes.number.isRequired,
  schoolId: PropTypes.number.isRequired,
  // @TODO: start_datetime: PropTypes.string.isRequired,
  // @TODO: end_datetime: PropTypes.string.isRequired,
  // @TODO: is_active: PropTypes.bool.isRequired, // @TODO: not used yet...maybe can be inferred by start and end date though instead of passed in explicitly
  classroomGoalMetadata: PropTypes.object.isRequired, // @TODO: shape
  goal: PropTypes.object.isRequired, // @TODO: shape
};

export default GoalPreview;
