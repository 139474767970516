/*
 * Ryan O'Dowd
 * 2022-07-17
 * © Copyright 2022 Oakwood Software Consulting, Inc.  All Rights Reserved.
 */
// @TODO: import 'react-multi-email/style.css';
import {
  // @TODO: Accordion,
  // @TODO: AccordionDetails,
  // @TODO: AccordionSummary,
  Button,
  // @TODO: Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  // @TODO: FormControlLabel,
  // @TODO: IconButton,
  // @TODO: Menu,
  // @TODO: MenuItem,
  // @TODO: Paper,
  Stack,
  Tooltip,
} from '@mui/material';
/* @TODO:
import {
  Area,
  AreaChart,
  CartesianGrid,
  Legend,
  Tooltip as RechartsTooltip,
  XAxis,
  YAxis,
} from 'recharts';
*/
import React, {
  useEffect,
  useState,
} from 'react';
import {
  fetchParticipants,
  fetchPledges,
  // @TODO: inviteParticipants,
} from '../../../actions';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
// @TODO: import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Globals from '../../../Globals';
import LinearProgress from '../../../common/components/OakLinearProgress';
// @TODO: import MoreVertIcon from '@mui/icons-material/MoreVert';
import OakTable from '../../../common/components/OakTable';
import PartnerLogo from '../../../components/PartnerLogo';
import PropTypes from 'prop-types';
/* @TODO:
import {
  ReactMultiEmail,
} from 'react-multi-email'; // @TODO: may require similar patch from old version patches/react-multi-email+0.5.3.patch (removed on 5/29/2023)
*/
import {
  format as dateFnsFormat,
} from 'date-fns';
// @TODO: import isEmail from 'validator/lib/isEmail';
import styles from './styles';
import utilities from '../../../utilities';

const Campaign = (props) => {
  const dispatch = useDispatch();
  const i18n = utilities.useI18n();

  // @TODO: const [_showParticipantInvitations, setShowParticipantInvitations] = useState(false);
  // @TODO: const [_openActionsAnchor, setOpenActionsAnchor] = useState(null);
  // @TODO: const [_openActionsId, setOpenActionsId] = useState(null);
  const [_isParticipantInvitationDialogOpen, setIsParticipantInvitationDialogOpen] = useState(false);
  const [_isSponsorInvitationDialogOpen, setIsSponsorInvitationDialogOpen] = useState(false);
  // @TODO: const [_participantInivitationNamesAndEmails, setParticipantInivitationNamesAndEmails] = useState([]);

  const organizationWrinklyUrl = `https://wrink.ly/organizations/${props.organization.slug}`;

  /* @TODO:
  const handleMenuClose = () => {
    setOpenActionsAnchor(null);
    setOpenActionsId(null);
  };
  */

  useEffect(() => {
    dispatch(fetchParticipants(props.organization.id, props.campaign_id));
    dispatch(fetchPledges(props.organization.id, props.campaign_id));
  }, [dispatch, props.campaign_id, props.organization.id]);

  const pledgeData = useSelector((state) => state.pledges[props.campaign_id] || []);
  const pledgeTableRows = pledgeData.map((d) => {
    const wrinkleCap = Math.floor(d.max_donation_cents / d.cents_per_wrinkle); // @TODO: is floor best here? could sponsor half of the next wrinkle, for example
    const progressPercent = Math.min(100, Math.floor(d.num_wrinkles_earned_for_campaign / wrinkleCap * 100));

    return [
      {id: `${d.id}_display_name`, content: d.sponsor.display_name},
      {id: `${d.id}_cents_per_wrinkle`, content: utilities.prettyCurrency(d.cents_per_wrinkle)},
      {id: `${d.id}_cap`, content: <span style={styles.caps}><span style={styles.moneyCap}>{utilities.prettyCurrency(d.max_donation_cents)}</span><span style={styles.wrinkleText}>({wrinkleCap})</span></span>},
      {id: `${d.id}_cap_so_far`, content: <span style={styles.caps}><span style={styles.moneySoFar}>{utilities.prettyCurrency(Math.min(d.max_donation_cents, d.num_wrinkles_earned_for_campaign * d.cents_per_wrinkle))}</span><span style={styles.wrinkleText}>({Math.min(wrinkleCap, d.num_wrinkles_earned_for_campaign)})</span></span>},
      {
        id: `${d.id}_progress_percent`,
        content: (
          <span style={styles.progressWrapper}>
            {/* @TODO: replace with tooltip?
            {`${progressPercent}%`}
            */}
            <LinearProgress progressPercent={progressPercent} />
          </span>
        ),
        styles: styles.progressCell,
      },
    ];
  });

  const sumTotalSoFarCents = pledgeData.reduce((acc, row) => acc + Math.min(row.num_wrinkles_earned_for_campaign * row.cents_per_wrinkle, row.max_donation_cents), 0); // @TODO: add "pledge to variable name
  const sumMaxPotentialCents = pledgeData.reduce((acc, row) => acc + (row.max_donation_cents || 0), 0); // @TODO: add "pledge to variable name
  const sumProgressPercent = sumMaxPotentialCents ? Math.floor(Math.min(100, sumTotalSoFarCents / sumMaxPotentialCents * 100)) : 0; // @TODO: add "pledge to variable name

  const pledgeTableTotalsRow = [
    {id: 'totals', content: 'Totals'},
    {id: '-', content: '-'},
    {id: 'max_potential', content: utilities.prettyCurrency(sumMaxPotentialCents)},
    {id: 'total_so_far', content: utilities.prettyCurrency(sumTotalSoFarCents)},
    {
      id: '',
      content: (
        <span style={styles.progressWrapper}>
          {`${sumProgressPercent}%`}
          <Stack sx={styles.infinityProgress} spacing={2}>
            <LinearProgress
              color={sumProgressPercent === 100 ? 'inherit' : 'primary'}
              variant='determinate'
              style={styles.progressBar}
              value={sumProgressPercent}
            />
          </Stack>
        </span>
      ),
    },
  ];

  const participantData = useSelector((state) => state.participants[props.campaign_id] || []);
  const numTotalParticipantWrinkles = participantData.reduce((acc, d) => acc + (d.progress?.num_campaign_wrinkles_earned || 0), 0) || 0;
  // @TODO: const numTotalParticipantPeanuts = participantData.reduce((acc, d) => acc + (d.progress?.num_peanuts || 0), 0) || 0;
  const participantTableRows = participantData.filter((d) => {
    /* @TODO:
    if (_showParticipantInvitations) {
      return true;
    }
    */

    return d.user_id;
  }).map((d) => {
    const contributionPercent = numTotalParticipantWrinkles ? Math.min(100, Math.floor((d.progress?.num_campaign_wrinkles_earned || 0) / numTotalParticipantWrinkles * 100)) : 0;

    return [
      {
        id: `${d.id}_invitee`,
        content: (
          <Tooltip title={d.invitation_email ? `Invitation sent to ${d.invitation_email} (${d.invitation_name})` : 'Participant joined campaign via public code on website.'}>
            <span>{d.user?.display_name || d.invitation_name}</span>
          </Tooltip>
        ),
      },
      // @TODO: on date hover, show time
      {id: `${d.id}_date_joined`, content: d.date_joined ? <span>{dateFnsFormat(new Date(d.date_joined), 'MMMM d', {locale: i18n('locale')})}</span> : <span style={styles.wrinkleText}>{d.date_most_recent_invitation_sent ? `(${dateFnsFormat(new Date(d.date_most_recent_invitation_sent), 'MMMM d', {locale: i18n('locale')})})` : '-'}</span>},
      {id: `${d.id}_num_wrinkles`, content: <span style={styles.caps}><span>{d.progress?.num_campaign_wrinkles_earned || '-'}</span><span style={styles.wrinkleText}>{d.progress?.num_campaign_wrinkles_in_progress ? `(${d.progress?.num_campaign_wrinkles_in_progress})` : '-'}</span></span>},
      // @TODO: {id: `${d.id}_num_peanuts`, content: d.progress?.num_peanuts},
      // @TODO: {id: `${d.id}_wrinkles_earned`, content: d.progress?.num_campaign_wrinkles_earned ? utilities.prettyCurrency(d.progress.num_campaign_wrinkles_earned * 45) : '-'}, // @TODO: {id: `$d.id}_earnings`, content: d.earnings ? utilities.prettyCurrency(d.earnings) : '-'}, // @TODO: this 45 shouldn't be hardcoded...figure out how much this person has earned for the campaign
      {
        id: `${d.id}_participant_wrinkles`,
        content: ( // @TODO: this column seems worthless if there are more than a few dozen participants
          (numTotalParticipantWrinkles && d.user) ? (
            <span style={styles.progressWrapper}>
              {`${contributionPercent}%`}
              <Stack sx={styles.infinityProgress} spacing={2}>
                <LinearProgress
                  color={contributionPercent === 100 ? 'inherit' : 'primary'}
                  variant='determinate'
                  style={styles.progressBar}
                  value={contributionPercent}
                />
              </Stack>
            </span>
          ) : (
            '-'
          )
        ),
        styles: styles.progressCell,
      },
      /* @TODO:
      {
        id: `${d.id}_overflow_menu`,
        content: (
          <>
            <IconButton
              aria-haspopup='true'
              onClick={(event) => {
                setOpenActionsAnchor(event.currentTarget); // @TODO: this will be in the wrong position until this row is its own component i think
                setOpenActionsId(d.id);
              }}
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              id='basic-menu'
              anchorEl={_openActionsAnchor}
              open={_openActionsId === d.id}
              onClose={handleMenuClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              <MenuItem onClick={handleMenuClose}>Resend invitation</MenuItem>{/* @TODO: only show if this is a pending invite, and email and code are both not null /}{/* @TODO: ask for confirmation /}
              <MenuItem onClick={handleMenuClose}>Remove participant</MenuItem>{/* @TODO: this should be red (dangeous operation) /}{/* @TODO: call this "cancel invitation" if its a pending invite /}{/* @TODO: ask for confirmation /}
            </Menu>
          </>
        ),
      },
      */
    ];
  });
  const participantTableTotalsRow = [
    {id: 'totals', content: 'Totals'},
    {id: '-', content: '-'},
    {id: 'numTotalParticipantWrinkles', content: numTotalParticipantWrinkles},
    // @TODO: {id: 'numTotalParticipantPeanuts', content: numTotalParticipantPeanuts},
    // @TODO: {id: 'totalEArnings', content: utilities.prettyCurrency(participantData.reduce((acc, d) => acc + d.earnings, 0) || 0)}, // @TODO: {id: 'earnings', content: utilities.prettyCurrency(participantData.reduce((acc, d) => acc + d.earnings, 0) || 0)},
    {id: 'blankForContribution', content: ''},
    // @TODO: {id: 'blankForMenu', content: ''}, // @TODO: add back when menu is added back to row
  ];

  return (
    <div style={styles.container}>
      <div style={styles.sectionWrapper}>
        <span>
          <h2 style={styles.campaignName}>{props.name}</h2>
          <span style={styles.startAndEndDates}>{`${dateFnsFormat(new Date(props.start_datetime), 'MMMM d, yyyy', {locale: i18n('locale')})}-${dateFnsFormat(new Date(props.end_datetime), 'MMMM d, yyyy', {locale: i18n('locale')})}`}</span>{/* @TODO: abstract the format string */}
        </span>
        <span style={styles.logoWrapper}>
          <PartnerLogo
            imgSrc={props.organization.logo_url}
            orgUrl={props.organization.website_url}
          />
        </span>
      </div>
      <div style={styles.textWrapper}>
        <p style={styles.text}>{props.organization.description}</p>
        <p style={styles.text}>{props.description}</p>
      </div>
      <OakTable
        tableTitle='Sponsors and pledges'
        open={true}
        headerRowData={[
          'Name',
          <span key={0} style={styles.headerMultiline}>Pledge amount<span key={1} style={{...styles.wrinkleText, ...styles.blackText}}>(per wrinkle)</span></span>,
          <span key={2} style={styles.headerMultiline}>Max donation <span key={3} style={styles.wrinkleText}>(wrinkles)</span></span>,
          <span key={4} style={styles.headerMultiline}>Total so far <span key={5} style={styles.wrinkleText}>(wrinkles)</span></span>,
          'Progress',
        ]}// @TODO: some of these should be named better and/or have tooltips
        rowData={pledgeTableRows}
        totalsRowData={pledgeTableTotalsRow}
        filtersJsx={
          <Button
            variant='contained'
            size='small'
            color='secondary'
            onClick={() => setIsSponsorInvitationDialogOpen(true)}
          >
            Invite sponsors
          </Button>
        }
      />
      <OakTable
        tableTitle='Participants'
        open={true}
        headerRowData={[
          'Name',
          <span key={0} style={styles.headerMultiline}>Campaign join date<span key={1} style={styles.wrinkleText}>(Invitation date)</span></span>,
          <span key={2} style={styles.headerMultiline}>Wrinkles earned<span key={3} style={styles.wrinkleText}>(in progress)</span></span>,
          // @TODO: 'Peanuts',
          // @TODO: 'Funds raised',
          'Contribution',
          // @TODO: '', // @TODO: add back when menu returns
        ]}// @TODO: some of these should be named better and/or have tooltips
        rowData={participantTableRows}
        totalsRowData={participantTableTotalsRow}
        filtersJsx={
          <>
            {/* @TODO: hiding for now until campaigns need this feature
            <FormControlLabel
              control={
                <Checkbox
                  checked={_showParticipantInvitations}
                  onChange={() => setShowParticipantInvitations(!_showParticipantInvitations)}
                  sx={{
                    color: Globals.colors.primaryExtraLight,
                    '&.Mui-checked': {
                      color: Globals.colors.accent,
                    },
                  }}
                />
              }
              label='Show invitations'
            />
            */}
            <Button
              variant='contained'
              size='small'
              color='secondary'
              onClick={() => {
                setIsParticipantInvitationDialogOpen(true);
                // @TODO: open dialog to invite
                /* @TODO:
                <p>@TODO: share with sponsors (enter email addresses/content and send directly?)</p>
                <p>@TODO: paste email addresses of participants (include code to enter in the app to join)</p>{/* @TODO: limit to 100 (?) emails at a time /}
                <p>@TODO: see status of invites for each participant invited</p>
                */
              }}
              // @TODO: share with sponsors (enter email addresses/content and send directly?)</p>
              // @TODO: paste email addresses of participants (include code to enter in the app to join)</p>{/* @TODO: limit to 100 (?) emails at a time */}
            >
              Invite participants
            </Button>
          </>
        }
      />

      {/* @TODO:
      <Paper style={styles.chartWrapper}>
        <Accordion
          style={styles.chartInner}
          defaultExpanded={false}
          // @TODO: defaultExpanded={true}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />} >
            <h3 style={styles.h3}>Progress/potential</h3>{/* @TODO: better name /}
          </AccordionSummary>
          <AccordionDetails>
            <AreaChart
              style={styles.chart}
              width={900}// @TODO: should be 100%
              height={400}
              data={[
                // @TODO: dynamic data
                {wrinkles: 1, 'Potential funds': 10000, 'Earned funds': 10000},
                {wrinkles: 2, 'Potential funds': 10000, 'Earned funds': 10000},
                {wrinkles: 3, 'Potential funds': 5023, 'Earned funds': 5023},
                {wrinkles: 4, 'Potential funds': 3977, 'Earned funds': 3977},
                {wrinkles: 5, 'Potential funds': 3004},
                {wrinkles: 6, 'Potential funds': 2996},
                {wrinkles: 7, 'Potential funds': 2054},
                {wrinkles: 8, 'Potential funds': 1746},
                {wrinkles: 9, 'Potential funds': 1300},
                {wrinkles: 10, 'Potential funds': 400},
                {wrinkles: 11, 'Potential funds': 400},
                {wrinkles: 12, 'Potential funds': 400},
              ]}
              margin={{
                top: 8,
                right: 8,
                left: 8,
                bottom: 8,
              }}
            >
              <YAxis
                tickFormatter={(cents) => utilities.prettyCurrency(cents).split('.')[0]}
              />
              <XAxis
                tickFormatter={(numWrinkles) => `${numWrinkles} wrinkles`}
              />
              <RechartsTooltip
                formatter={(label, payload) => {
                  return utilities.prettyCurrency(label); // @TODO: would really prefer this to be the cummulative amount
                }}
                labelFormatter={(label, payload) => {
                  return <span style={styles.tooltipWrinkles}>Wrinkles: {label}</span>;
                }}
              />
              <CartesianGrid
                strokeDashArray='4'
              />
              <Legend />
              {/* @TODO: would like to reorder the items in the legend but the z-index is off /}
              <Area
                dataKey='Potential funds'// @TODO: enum
                stroke={Globals.colors.primaryDark}
                fill={Globals.colors.primaryLight}
              />
              <Area
                dataKey='Earned funds'// @TODO: enum
                stroke={Globals.colors.accentDark}
                fill={Globals.colors.accent}
              />
            </AreaChart>
            <p>Participants have earned TODO wrinkles so far for a total of $TODO.TODO.  The next wrinkle earned will raise an additional $TODO.TODO in funds to bring the total to $TODO.TODO.</p>
            <p>*Please note that it takes at least 72 hours to earn a wrinkle, so progress may not show up for the first few days of a campaign.</p>
          </AccordionDetails>
        </Accordion>
      </Paper>
      */}

      <Dialog
        open={_isParticipantInvitationDialogOpen}
        style={styles.dialog}
        aria-labelledby='alert-dialog-title-todo'
        aria-describedby='alert-dialog-description-todo'
        // @TODO: fullScreen={Globals.isMobile}
        onClose={() => setIsParticipantInvitationDialogOpen(false)}
      >
        <DialogTitle id='alert-dialog-title-todo'>
          Invite participants
        </DialogTitle>
        <DialogContent style={styles.dialogContent}>
          {props.public_code && (
            <>
              <h4 style={styles.dialogSubSectionHeaderText}>Public inivitation</h4>
              <p>Participants can join by entering code <span style={styles.joinCode}>{props.public_code}</span> on the Fundraising page in the mobile app.</p>
            </>
          )}
          {/* @TODO: hiding for now until campaigns need this
          <h4 style={styles.dialogSubSectionHeaderText}>Private invitations</h4>
          <p>To send individual invitations, use the form below.  Names must be entered in the format <code style={styles.code}>FirstName LastName {'<email-address>'}</code>. (Be sure to include the <code style={styles.code}>{'<>'}</code> around the email address.)  Participants should be separated by a comma (<code style={styles.code}>,</code>) or semicolon (<code style={styles.code}>;</code>).</p>
          {/* @TODO: show email preview /}
          {/* @TODO: optional personal note that user can attach /}
          <ReactMultiEmail
            style={styles.emails}
            placeholder='Name1 <email-address-1>; Name2 <email-address-2>'
            emails={_participantInivitationNamesAndEmails}
            onChange={(namesAndEmails) => setParticipantInivitationNamesAndEmails(namesAndEmails)}
            validateEmail={(nameAndEmail) => {
              return isEmail(nameAndEmail, {require_display_name: true});
            }}
            getLabel={(nameAndEmail, index, removeEmail) => {
              let style = styles.newEmail;
              let tooltipText = '';
              if (participantData.filter((p) => !p.user_id).map((p) => `${p.invitation_name} <${p.invitation_email}`).includes(nameAndEmail)) {
                style = styles.alreadyInvitedEmail;
                tooltipText = 'An invitation has already been sent to this nameAndEmail address, so a reminder will be sent instead.';
              } else if (participantData.filter((p) => p.user_id).map((p) => p.invitation_email).includes(nameAndEmail)) {
                style = styles.alreadyAcceptedEmail;
                tooltipText = 'An invitation has already been accepted by this email address, so it will be skipped.';
              }
              return (
                <Tooltip key={`${index}-${nameAndEmail}`} title={tooltipText}>
                  <div data-tag key={`${index}-${nameAndEmail}`} style={style}>
                    {nameAndEmail}
                    <span data-tag-handle onClick={() => removeEmail(index)}>
                      ×
                    </span>
                  </div>
                </Tooltip>
              );
            }}
          />
          <p style={styles.legend}>Invitations will be sent to all <span style={{...styles.legendText, ...styles.newEmail}}>new addresses</span>, reminders will be sent to <span style={{...styles.legendText, ...styles.alreadyInvitedEmail}}>existing addresses</span>, and <span style={{...styles.legendText, ...styles.alreadyAcceptedEmail}}>accepted invitations</span> will be skipped.</p>{/* @TODO: only show legend if some are yellow or red (and even then, only show if present) /}
          */}
        </DialogContent>
        <DialogActions>
          <Button
            color='primary'
            variant='text'
            autoFocus={false}
            onClick={() => setIsParticipantInvitationDialogOpen(false)}
          >
            Close
          </Button>
          {/* @TODO: hiding for now until campaigns need this feature
          <Button
            color='primary'
            variant='contained'
            autoFocus={false}
            disabled={!_participantInivitationNamesAndEmails.length}
            onClick={() => {
              dispatch(inviteParticipants(props.organization.id, props.campaign_id, _participantInivitationNamesAndEmails));
              setIsParticipantInvitationDialogOpen(false);
              // @TODO: clear text input
            }}
          >
            Send invitations
          </Button>
          */}
        </DialogActions>
      </Dialog>
      <Dialog
        open={_isSponsorInvitationDialogOpen}
        style={styles.dialog}
        aria-labelledby='alert-dialog-title-todo'
        aria-describedby='alert-dialog-description-todo'
        fullScreen={Globals.isMobile}
        onClose={() => setIsSponsorInvitationDialogOpen(false)}
      >
        <DialogTitle id='alert-dialog-title-todo'>
          Invite sponsors
        </DialogTitle>
        <DialogContent style={styles.dialogContent}>
          <p>To invite donors to sponsor this campaign, please share <code style={styles.code}>{organizationWrinklyUrl} <ContentCopyIcon style={styles.copyIcon} onClick={() => navigator.clipboard.writeText(organizationWrinklyUrl)} /></code>{' and have them click "Join campaign".'}</p>
          <p>Sponsors will be asked to enter their name, email address, a pledge amount per verse (as well as a maximum amount), and their credit-/debit-card information.</p>
          <p>At the end of the campaign, their cards will be charged automatically according to their pledge amounts and number of verses memorized by participants.</p>
        </DialogContent>
        <DialogActions>
          <Button
            color='primary'
            variant='text'
            autoFocus={false}
            onClick={() => setIsSponsorInvitationDialogOpen(false)}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

Campaign.propTypes = {
  // @TODO: add proptypes
  campaign_id: PropTypes.number.isRequired,
  description: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  start_datetime: PropTypes.string.isRequired,
  end_datetime: PropTypes.string.isRequired,
  organization: PropTypes.object.isRequired,

  public_code: PropTypes.string,
};

export default Campaign;
