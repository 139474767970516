/*
 * Ryan O'Dowd
 * 2023-01-21
 * © Copyright 2023 Oakwood Software Consulting, Inc.  All Rights Reserved.
 */
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material';
import React, {
  useState,
} from 'react';
import {
  addI18nNote,
  updateI18nValue,
} from '../../../actions';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import EditIcon from '@mui/icons-material/Edit';
import Globals from '../../../Globals';
import Highlighter from 'react-highlight-words';
import Note from './Note';
import PropTypes from 'prop-types';
import SendIcon from '@mui/icons-material/Send';
import SpeakerNotesIcon from 'mdi-react/SpeakerNotesIcon';
import TextField from '@mui/material/TextField';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import ThumbDownOutlinedIcon from '@mui/icons-material/ThumbDownAltOutlined';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbUpOutlinedIcon from '@mui/icons-material/ThumbUpAltOutlined';
import commonStyles from '../../../commonStyles';
import styles from './styles';
import utilities from '../../../utilities';

const Translation = (props) => {
  const dispatch = useDispatch();

  const i18nObjectsForThisKey = useSelector((state) => {
    return state.i18n[props.locale.code]?.i18n?.filter((i) => i.key === props.i18nKey);
  });

  const [_isNotesOpen, setIsNotesOpen] = useState(false);
  let notes = useSelector((state) => {
    return i18nObjectsForThisKey.flatMap((i) => {
      return state.i18nNotes[i.id] || [];
    });
  });

  i18nObjectsForThisKey.forEach((i) => {
    notes.push({
      id: `i18n_change_${i.id}`,
      created_at: i.created_at,
      updated_at: i.updated_at,
      user_id: Globals.wrinklyBotId,
      i18n_id: i.id,
      upvoted: null,
      parent_note_id: null,
      notes: i.value,
    });
  });
  notes = notes.sort((a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime());

  const [_newNoteText, setNewNoteText] = useState('');
  const [_updatedTextValue, setUpdatedTextValue] = useState('');
  const [_isEditable, setIsEditable] = useState(false);

  const isAdmin = utilities.useIsAdmin();

  const _saveNote = () => {
    dispatch(addI18nNote(props.i18nId, null, _newNoteText));
    setNewNoteText('');
  };

  return (
    <div
      style={{
        ...styles.container,
        ...(props.mostRecentVote === 1 ? styles.upvotedContainer : {}),
        ...(props.mostRecentVote === 0 ? styles.downvotedContainer : {}),
        ...(props.isGeneralFeedback ? styles.generalFeedbackContainer : {}),
      }}
    >
      <div style={styles.aboveFold}>
        <div
          style={styles.translations}
          onClick={() => setIsNotesOpen(!_isNotesOpen)}
        >
          {props.isGeneralFeedback
            ? (
              <React.Fragment>
                <span style={styles.translationRow}>
                  <Highlighter
                    style={styles.generalFeedbackTitle}
                    highlightStyle={styles.highlightedText}
                    searchWords={props.searchTerms}
                    autoEscape={true}// @TODO: use this?
                    textToHighlight={props.englishValue}
                    caseSensitive={false}
                  />
                </span>
                <span style={styles.translationRow}>
                  <Highlighter
                    highlightStyle={styles.highlightedText}
                    searchWords={props.searchTerms}
                    autoEscape={true}// @TODO: use this?
                    textToHighlight={"Have notes that don't apply to a single word/phrase below?  Additional feedback can be provided here."}
                    caseSensitive={false}
                  />
                </span>
              </React.Fragment>
            )
            : (
              <React.Fragment>
                {isAdmin &&
                  <span style={styles.translationRow}>
                    <span style={styles.locale}>key:</span>
                    <Highlighter
                      highlightStyle={styles.highlightedText}
                      searchWords={props.searchTerms}
                      autoEscape={true}// @TODO: use this?
                      textToHighlight={props.i18nKey}
                      caseSensitive={false}
                    />
                  </span>
                }
                <span style={styles.translationRow}>
                  <span style={styles.locale}>en:</span>
                  <Highlighter
                    highlightStyle={styles.highlightedText}
                    searchWords={props.searchTerms}
                    autoEscape={true}// @TODO: use this?
                    textToHighlight={props.englishValue}
                    caseSensitive={false}
                  />
                </span>
                <span style={styles.translationRow}>
                  <span style={styles.locale}>{props.locale.code}:</span>
                  <Highlighter
                    highlightStyle={styles.highlightedText}
                    searchWords={props.searchTerms}
                    autoEscape={true}// @TODO: use this?
                    textToHighlight={props.localeValue}
                    caseSensitive={false}
                  />
                  {isAdmin &&
                    <IconButton
                      style={styles.updateIcon}
                      size='small'
                      onClick={(event) => {
                        event.stopPropagation();
                        setUpdatedTextValue(props.localeValue);
                        setIsEditable(true);
                      }}
                      edge='end'
                    >
                      <EditIcon fontSize='10px' />
                    </IconButton>
                  }
                </span>
              </React.Fragment>
            )
          }
        </div>

        <div style={styles.buttons}>
          {/* @TODO: if rodowd, show vote counts */}
          {!props.isGeneralFeedback &&
            <React.Fragment>
              <span>
                <IconButton
                  aria-controls='thumbs-up'
                  aria-haspopup='true'
                  disabled={props.mostRecentVote === 1}
                  onClick={() => {
                    dispatch(addI18nNote(props.i18nId, 1));
                    // @TODO: updates to translations are going to have to be new rows in db to prevent losing data
                  }}
                >
                  {[0, 1].includes(props.mostRecentVote)
                    ? (
                      <ThumbUpIcon
                        sx={{
                          color: props.mostRecentVote === 1 ? Globals.colors.accentDark : Globals.colors.lightGray,
                        }}
                      />
                    )
                    : (
                      <ThumbUpOutlinedIcon
                        sx={{
                          color: Globals.colors.gray,
                        }}
                      />
                    )
                  }
                </IconButton>
              </span>
              <span>
                <IconButton
                  aria-controls='thumbs-down'
                  aria-haspopup='true'
                  disabled={props.mostRecentVote === 0}
                  onClick={() => {
                    dispatch(addI18nNote(props.i18nId, 0));
                    setIsNotesOpen(true);
                  }}
                >
                  {[0, 1].includes(props.mostRecentVote)
                    ? (
                      <ThumbDownIcon
                        sx={{
                          color: props.mostRecentVote === 0 ? Globals.colors.incorrect : Globals.colors.lightGray,
                        }}
                      />
                    )
                    : (
                      <ThumbDownOutlinedIcon
                        sx={{
                          color: Globals.colors.gray,
                        }}
                      />
                    )
                  }
                </IconButton>
              </span>
            </React.Fragment>
          }
          <span>
            <IconButton
              aria-controls='notes'
              aria-haspopup='true'
              onClick={() => setIsNotesOpen(!_isNotesOpen)}
            >{/* @TODO: add badge for how many notes are there */}
              <SpeakerNotesIcon color={Globals.colors.primaryLight} />
            </IconButton>
          </span>
          {/* @TODO: if rodowd, ability to edit in place or replace with new text */}
          {/* @TODO: if rodowd, ability to disable (for ones that aren't used anymore) */}
          {/* @TODO: if props.status !== 'unmarked': option to change vote */}
          {/* @TODO: if props.status === 'incorrect': option to change explanation text */}
        </div>
      </div>

      {_isNotesOpen &&
        <div style={styles.notesWrapper}>
          {notes.filter((n) => n.notes).map((n) => {
            return <Note key={n.id} {...n} />;
          })}
          <div style={styles.newNoteWrapper}>
            <TextField
              style={commonStyles.searchBar}
              value={_newNoteText}
              onChange={(e) => setNewNoteText(e.target.value)}
              onKeyDown={(e) => {
                if (e.code === 'Enter' && e.metaKey) {
                  _saveNote();
                }
              }}
              fullWidth
              placeholder=''
              multiline={true}
              autoFocus={true}
              autoCapitalize='none'
              label='New note'
              variant='standard'
            />
            <span style={styles.saveButtonWrapper}>
              <IconButton
                aria-label='save note'
                disabled={!_newNoteText}
                onClick={_saveNote}
                edge='end'
              >
                <SendIcon
                  sx={{
                    color: _newNoteText ? Globals.colors.primary : Globals.colors.lightGray,
                  }}
                />
              </IconButton>
            </span>
          </div>
        </div>
      }

      {isAdmin && _isEditable &&
        <Dialog
          open={true}
          onClose={() => setIsEditable(false)}
        >
          <DialogTitle id='alert-dialog-title-todo'>
            Update translation
          </DialogTitle>
          <DialogContent style={styles.updateDialogContent}>
            <span style={styles.translationRow}>
              <span style={styles.locale}>key:</span>
              <span>{props.i18nKey}</span>
            </span>
            <span style={styles.translationRow}>
              <span style={styles.locale}>en:</span>
              <span>{props.englishValue}</span>
            </span>
            <span style={styles.translationRow}>
              <span style={styles.locale}>{props.locale.code}:</span>
              <span>{props.localeValue}</span>
            </span>
            <TextField
              style={styles.updatedText}
              value={_updatedTextValue}
              onChange={(e) => setUpdatedTextValue(e.target.value)}
              placeholder=''
              multiline={true}
              autoFocus={true}
              autoCapitalize='none'
              label='Updated value'
              variant='standard'
            />
          </DialogContent>
          <DialogActions>
            <Button
              color='primary'
              variant='text'
              autoFocus={false}
              onClick={() => setIsEditable(false)}
            >
              Cancel
            </Button>
            <Button
              color='primary'
              variant='contained'
              disabled={props.localeValue === _updatedTextValue}
              autoFocus={false}
              onClick={() => {
                dispatch(updateI18nValue(props.i18nId, _updatedTextValue));
                setIsEditable(false);
                setIsNotesOpen(true);
              }}
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>
      }
    </div>
  );
};

Translation.propTypes = {
  englishValue: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired, // @TODO: remove func and object when it's on back end?
  i18nId: PropTypes.number.isRequired,
  i18nKey: PropTypes.string.isRequired,
  mostRecentVote: PropTypes.number.isRequired,
  locale: PropTypes.object.isRequired,
  localeValue: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired, // @TODO: remove func and object when it's on back end?
  searchTerms: PropTypes.array.isRequired,

  isGeneralFeedback: PropTypes.bool,
  status: PropTypes.oneOf(['correct', 'incorrect', 'unmarked']),
};

export default Translation;
