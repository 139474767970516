/*
 * Ryan O'Dowd
 * 2022-02-27
 *
 * © Copyright 2022 Oakwood Software Consulting, Inc.  All Rights Reserved.
*/
import PlatformGlobals from './PlatformGlobals';
import React from 'react';
import oakGlobalObject from './common/utilities/oakGlobalObject';

export const firebase = PlatformGlobals.importedFirebase;

let IOS_CLIENT_ID = '';
let WEB_CLIENT_ID = '';
let BUNDLE_ID = '';
let APPLE_REDIRECT_URI = 'https://wrinkly-dev.firebaseapp.com/__/auth/handler';
let STRIPE_KEY = 'pk_test_51LKVgwIYLz4DlAmRgCZwAj1Ju8nRCjl8eooGHhe11jjjBm1eY16fEoTjOdRiPVNZACJFNF3QEhftkF4OCCf2xyVF00LCScICdP';
if (PlatformGlobals.env === 'local') {
  IOS_CLIENT_ID = '895606346021-rk787ret9i9bob9rufg0be24rd6v1kt8.apps.googleusercontent.com';
  WEB_CLIENT_ID = '895606346021-fgdg41rnkplnag7nrvvak2lp2sjmf5om.apps.googleusercontent.com';
  BUNDLE_ID = 'com.oakwoodsc.wrinkly.dev';
} else if (PlatformGlobals.env === 'dev') {
  IOS_CLIENT_ID = '895606346021-rk787ret9i9bob9rufg0be24rd6v1kt8.apps.googleusercontent.com';
  WEB_CLIENT_ID = '895606346021-fgdg41rnkplnag7nrvvak2lp2sjmf5om.apps.googleusercontent.com';
  BUNDLE_ID = 'com.oakwoodsc.wrinkly.dev';
} else if (PlatformGlobals.env === 'prod') {
  IOS_CLIENT_ID = '182142003913-o7aotuinbuoepulkdcq3bacc9knhonvh.apps.googleusercontent.com';
  WEB_CLIENT_ID = '182142003913-1mnrr2akh9ph3a3o56kir78q6rtlq5sm.apps.googleusercontent.com';
  BUNDLE_ID = 'com.oakwoodsc.wrinkly';
  APPLE_REDIRECT_URI = 'https://wrinkly-prod.firebaseapp.com/__/auth/handler';
  STRIPE_KEY = 'pk_live_51LKVgwIYLz4DlAmRL1CKev5pdPXLoLdLxMWKfrShI9PWWYaKg6doJMWsyrqVgtQEaRhzUqy8WduniYxWQyWU22A2000Vop0hO4';
}

let apiUrl = 'http://localhost:5010'; // local env for web only
if (PlatformGlobals.platform === 'android') {
  apiUrl = 'http://192.168.0.120:5010'; // cherry
}
if (PlatformGlobals.platform === 'ios') {
  apiUrl = 'http://192.168.0.120:5010'; // cherry
}
if (PlatformGlobals.env === 'prod') {
  apiUrl = 'https://api.wrink.ly';
}

const themes = { // @TODO: update all of these; also, add accents, text contrast, and others? for each
  blue: {
    primaryExtraLight: '#dfffff',
    primaryLight: '#33a0c5',
    primary: '#0089ab',
    primaryDark: '#005f86',
    primaryExtraDark: '#002d3d',
  },
  purple: {
    primaryExtraLight: '#e6a2ff',
    primaryLight: '#a200ff',
    primary: '#7d00e6',
    primaryDark: '#5c00b3',
    primaryExtraDark: '#3d0089',
  },
  yellow: {
    primaryExtraLight: '#ffffcc',
    primaryLight: '#ffff33',
    primary: '#ffff00',
    primaryDark: '#cccc00',
    primaryExtraDark: '#999900',
  },
  // @TODO: Christmas
  // @TODO: fall
  // @TODO: winter
  // @TODO: easter
  red: {
    primaryExtraLight: '#ffdddd',
    primaryLight: '#ff9999',
    primary: '#ff6666',
    primaryDark: '#ff3333',
    primaryExtraDark: '#ff0000',
  },
  dark: {
    primaryExtraLight: '#bbbbbb',
    primaryLight: '#888888',
    primary: '#444444',
    primaryDark: '#222222',
    primaryExtraDark: '#000000',
  },
  light: {
    primaryExtraLight: '#ffffff',
    primaryLight: '#eeeeee',
    primary: '#cccccc',
    primaryDark: '#999999',
    primaryExtraDark: '#676767',
  },
};

const theme = 'blue';

const Globals = {
  env: PlatformGlobals.env,
  platform: PlatformGlobals.platform,
  appVersionNumber: PlatformGlobals.appVersionNumber,
  appName: 'wrinkly',
  apiUrl,
  webUrl: PlatformGlobals.env === 'prod' ? 'https://wrink.ly' : 'http://localhost:3010',

  colors: oakGlobalObject({
    primaryExtraLight: themes[theme].primaryExtraLight,
    primaryLight: themes[theme].primaryLight,
    primary: themes[theme].primary,
    primaryDark: themes[theme].primaryDark,
    primaryExtraDark: themes[theme].primaryExtraDark,
    accentLight: '#e7ff83',
    accent: '#c6ff00',
    accentDark: '#8ab200',
    success: '#477158',
    qqq: '#81cff3', // @TODO: rename (used for passage gradient normalization spectrum and i think something else too)
    brickTitle: '#b1dff3', // @TODO: look good in dark mode?
    incorrect: '#FF4241', // @TODO: update
    incorrectText: '#4422ff', // @TODO: update, especially for dark mode
    inContextHightlight: '#ffccaa',
    badge: '#ff4242', // @TODO: '#c6ff00', // @TODO: '#aaaaff', // @TODO: is this what i want for badge color?
    warnIncorrect: '#FFD700', // @TODO: update
    alert: '#FF4242',
    alertLight: '#FF9292',
    alertDark: '#cF2222',
    white: '#ffffff',
    black: '#000000',
    gray: '#757575',
    lightGray: '#cccccc', // @TODO: copy and call unstarted
    extraLightGray: '#eeeeee',
    disabled: '#bdbdbd',
    bgLight: '#EEEEEE',
    bgDark: '#1b1b1b',
    complete: '#FFD700', // @TODO: rename to inProgress, and have accent color assigned to complete
    completeBg: '#444444', // @TODO: better color?
    completeBgDark: '#222222', // @TODO: better color?
    darkText: '#212121',
    myNote: '#ccffdd',
    otherNote: '#bbeeff',
    warningBg: '#af4F70',
    // @TODO: highlighted: '#ffde64',

    lightTheme: oakGlobalObject({
      text: '#212121',
      invertedText: '#ffffff',
      background: '#dfffff',
      appBackground: '#dfffff',
      primaryText: '#005f56', // @TODO: update
      accentText: '#8ab200', // @TODO: update
      sectionHeaderBackground: '#005f86dd',
      searchBarWrapperBackground: '#0089ab',
    }),
    darkTheme: oakGlobalObject({
      text: '#EEEEEE',
      invertedText: '#212121',
      background: '#002d3d',
      appBackground: '#002d3d',
      primaryText: '#33a095', // @TODO: update
      accentText: '#c6ff00', // @TODO: update
      sectionHeaderBackground: '#002d3ddd',
      searchBarWrapperBackground: '#005f86',
    }),
  }),

  themePrimary: { // @TODO: update
    50: '#e1ebf3',
    100: '#b3cde1',
    200: '#80abcd',
    300: '#4d89b9',
    400: '#2770aa',
    500: '#01579b',
    600: '#014f93',
    700: '#014689',
    800: '#013c7f',
    900: '#002c6d',
    A100: '#9cbbff',
    A200: '#6998ff',
    A400: '#3674ff',
    A700: '#1d63ff',
    contrastDefaultColor: 'light',
  },
  themeAccent: { // @TODO: update
    A100: '#b8cace',
    A200: '#89a7ae',
    A400: '#366974',
    A700: '#0e3f4a',
    contrastDefaultColor: 'light',
  },
  themeError: { // @TODO: update
    50: '#ffeaea',
    100: '#ffcbcb',
    200: '#ffa9a9',
    300: '#ff8686',
    400: '#ff6c6c',
    500: '#ff5252',
    600: '#ff4b4b',
    700: '#ff4141',
    800: '#ff3838',
    900: '#ff2828',
    A100: '#ffffff',
    A200: '#ffffff',
    A400: '#ffd5d5',
    A700: '#ffbcbc',
    contrastDefaultColor: 'dark',
  },

  fontFamilies: { // @TODO: add these to material/paper theme as well
    primary: ['ios', 'web'].includes(PlatformGlobals.platform) ? 'Ubuntu' : 'Ubuntu-Regular',
    secondary: PlatformGlobals.platform === 'android' ? 'Montserrat-ExtraBold' : 'Montserrat', // @TODO: not sure this is working on android (see quick lesson button text)
  },

  appStoreUrl: 'https://itunes.apple.com/us/app/id1615885499?mt=8&ls=1',
  playStoreUrl: 'https://play.google.com/store/apps/details?id=com.oakwoodsc.wrinkly',
  iosClientId: IOS_CLIENT_ID,
  webClientId: WEB_CLIENT_ID,
  bundleId: BUNDLE_ID,
  appleRedirectUri: APPLE_REDIRECT_URI,

  isMobile: PlatformGlobals.isMobile,
  mediaQuerySizes: oakGlobalObject({
    xs: 600,
    sm: 960,
    md: 1280,
    lg: 1920,
    // xl > 1920
  }),

  revenueCatApiKey: PlatformGlobals.platform === 'ios' ? 'appl_HiSgiAQHXmegXmwSKdOjRVATmaF' : 'TODO',
  profilePictureSize: 1000,

  appContext: React.createContext(),

  peanutsNeededToRepairStreak: 30,
  numDaysStreakIsRepairable: 10,

  srsLevelWrinkleIsEarnedAt: 3,

  stripeKey: STRIPE_KEY,

  adminAuthIds: PlatformGlobals.env === 'prod' // @TODO: this should only be used in utilities/useIsAdmin...should we move this into that function?
    ? [
      'geUqjDXiOCVOtJeVBEc42BIXtno1', // rodowd prod
      'WkdHncP1Q5MQaiyy41wgG1ZFAnn2', // wrinkly_support prod
    ]
    : [
      'qDZUc6nY29RQKVysCoDCPp3BDBu2', // rodowd dev
      'P40emDW3MHNO0Fqco1in39yJXpl1', // wrinkly_support dev
    ],

  supportedLocales: ['en', 'es'], // @TODO: get from redux instead of globals? would prefer to remove this...

  supportEmail: 'support@wrink.ly',

  wrinklyBotId: 1,

  fontScales: {
    small: 0.8,
    medium: 1.0,
    large: 1.4,
    extraLarge: 1.8,
  },

  maxInnerWidth: 1200,
};

export default Globals;
