/**
 * Ryan O'Dowd
 * 2022-12-26
 * © Copyright 2022 Oakwood Software Consulting, Inc. All Rights Reserved.
 */
import Globals, {
  firebase,
} from '../Globals';
import {
  RSAA,
  getJSON,
} from 'redux-api-middleware';
import circularDepUtilities from '../utilities/circularDepUtilities';
import {
  v4 as uuidv4,
} from 'uuid';

export const SET_CHAT_MESSAGES = 'SET_CHAT_MESSAGES';
export const FETCH_REQUEST = 'FETCH_REQUEST';
export const FETCH_ERROR = 'FETCH_ERROR';
export const REMOVE_FETCH = 'REMOVE_FETCH';
export const UPDATE_FETCH = 'UPDATE_FETCH';
export const UPDATE_LOADING_STATE = 'UPDATE_LOADING_STATE';
export const NOT_LOADING = 'NOT_LOADING';
export const SET_SIGN_IN_INFO = 'SET_SIGN_IN_INFO';
export const SET_WHATS_NEW_LAST_READ_TIMESTAMP = 'SET_WHATS_NEW_LAST_READ_TIMESTAMP';
export const SET_WHATS_NEW_LAST_READ_VERSION = 'SET_WHATS_NEW_LAST_READ_VERSION';
export const SET_WHATS_NEW_NOTES = 'SET_WHATS_NEW_NOTES';
export const SET_FEEDBACK_DRAFT_TEXT = 'SET_FEEDBACK_DRAFT_TEXT';
export const SET_LIGHT_DARK_MODE = 'SET_LIGHT_DARK_MODE';
export const LOGOUT = 'LOGOUT';
export const SET_OAK_USER = 'SET_OAK_USER';

// navigation routes
export const CUSTOM_AUTH_FORM_ROUTE = 'CustomAuthForm';

// fetch status
export const FETCH_REQUEST_OBJECT = {
  status: 'IN_FLIGHT',
};
export const FETCH_SUCCESS_OBJECT = {
  status: 'SUCCESS',
};
export const FETCH_ERROR_OBJECT = {
  status: 'ERROR',
  message: '<no errror provided...check reducer for FETCH_ERROR>',
};

// methods
export const GET = 'GET';
export const POST = 'POST';
export const PATCH = 'PATCH';
export const PUT = 'PUT';
export const DELETE = 'DELETE';

export const getFetchTypes = (endpoint, successType, skipMessage, errorCallback, actionCreatorName) => {
  const requestId = uuidv4();
  return [
    {
      type: FETCH_REQUEST,
      payload: {endpoint},
      meta: {
        requestId,
        endpoint,
        actionCreatorName,
      },
    },
    {
      type: successType,
      payload: (action, state, res) => {
        return res.json().then((data) => data);
      },
      meta: (action, state, res) => {
        delete action['@@redux-api-middleware/RSAA'];
        if (res) {
          return {
            responseStatus: res.status,
            responseStatusText: res.statusText,
            requestId,
            endpoint,
            actionCreatorName,
            ...action,
          };
        } else {
          return {
            status: 'Network request failed',
          };
        }
      },
    },
    {
      type: FETCH_ERROR,
      payload: (action, state, res) => {
        const defaultMessage = `${res.status} - ${res.statusText}`;
        // @TODO: make call to crashlytics with res object (include request if possible too...at least endpoint)
        if (res) {
          return getJSON(res).then((json) => {
            if (json.message) {
              return json;
            }
            return {
              message: defaultMessage,
              ...json,
            };
          }).catch(() => {
            // @TODO: toast this...
            return {
              message: defaultMessage,
            };
          });
        } else {
          return {
            message: '',
          };
        }
      },
      meta: {
        requestId,
        endpoint,
        skipMessage,
        errorCallback,
      },
    },
  ];
};

export const getRequestHeaders = async (ignoreAuth = false) => {
  const userMasqueradingAs = Globals.platform === 'web' ? sessionStorage.getItem('masqueradeAsUserId') : null; // can't get from redux because of race condition or circular imports or something

  const headers = {
    'X-version': Globals.appVersionNumber,
    'X-platform': Globals.platform,
    'X-app-name': Globals.appName,
  };

  if (userMasqueradingAs) {
    headers['X-masquerade-user-id'] = userMasqueradingAs;
  }

  if (!ignoreAuth) {
    try {
      const jwt = await firebase.auth().currentUser?.getIdToken(); // @TODO: currentUser is null if refreshing page...race condition...how to fix this? (web only)
      // @TODO: const jwt = await firebase.auth().currentUser?.getIdToken(); // @TODO: currentUser is null if refreshing page...race condition...how to fix this? (web only)
      // @TODO: if expired, refresh token
      if (jwt) {
        headers.Authorization = jwt;
      }
    } catch (error) {
      console.log(error); /* eslint-disable-line no-console */ // @TODO: record error in crashlytics?
      switch (error.code) {
      case 'auth/network-request-failed': {
        break;
      }
      /* @TODO: might not be safe to ignore this case...could be that user needs to be logged out and re sign in: "The user's credential is no longer valid. The user must sign in again."
      case 'auth/user-token-expired': {
        break;
      }
      */
      /* @TODO: this should only be the case during logout. i don't love swallowing this error, but it's really the only time it should happen, and there's not necessisarily a need to be notified
      case 'auth/no-current-user': {
        break;
      }
      */
      default:
        circularDepUtilities.recordError(error);
        break;
      }
    }
  }

  return headers;
};

export function logout() {
  circularDepUtilities.logout();
  firebase.auth().signOut();

  return {
    type: LOGOUT,
  };
}

export function updateLoadingState(resource, isLoading, metadata) {
  return {
    type: UPDATE_LOADING_STATE,
    resource,
    isLoading,
    metadata,
  };
}

export function fetchChatMessages() {
  const endpoint = `${Globals.apiUrl}/chats`;
  return async (dispatch) => {
    dispatch({
      [RSAA]: {
        endpoint,
        method: GET,
        headers: await getRequestHeaders(),
        types: getFetchTypes(endpoint, SET_CHAT_MESSAGES),
      },
    });
  };
}

export function sendChatMessage(messageBody) { // @TODO: add support for attachments
  const formData = new FormData();
  formData.append('message_body', messageBody);
  const endpoint = `${Globals.apiUrl}/chats`;
  return async (dispatch) => {
    dispatch({
      [RSAA]: {
        endpoint,
        method: POST,
        body: formData,
        headers: await getRequestHeaders(),
        types: getFetchTypes(endpoint, SET_CHAT_MESSAGES),
      },
    });
  };
}

export function setMessageReaction(threadUserId, chatId, reactionName) { // @TODO: combine with one in acorn src/actions
  const formData = new FormData();
  formData.append('thread_user_id', threadUserId);
  formData.append('reaction_name', reactionName);
  const endpoint = `${Globals.apiUrl}/chats/${chatId}/reaction`;
  return async (dispatch) => {
    dispatch({
      [RSAA]: {
        endpoint,
        method: PATCH,
        body: formData,
        headers: await getRequestHeaders(),
        types: getFetchTypes(endpoint, SET_CHAT_MESSAGES),
      },
    });
  };
}

export function markAllMessagesRead() {
  const endpoint = `${Globals.apiUrl}/chats/all_read`;
  return async (dispatch) => {
    dispatch({
      [RSAA]: {
        endpoint,
        method: POST,
        headers: await getRequestHeaders(),
        types: getFetchTypes(endpoint, SET_CHAT_MESSAGES),
      },
    });
  };
}

export function setWhatsNewLastReadTimestamp(timestamp) {
  return {
    type: SET_WHATS_NEW_LAST_READ_TIMESTAMP,
    timestamp,
  };
}

export function setWhatsNewLastReadVersion(version) {
  return {
    type: SET_WHATS_NEW_LAST_READ_VERSION,
    version,
  };
}

export function sendMessage(firstName, lastName, emailAddress, message) { // @TODO: rename to sendConetactFormMessage?
  const formData = new FormData();
  formData.append('first_name', firstName);
  formData.append('last_name', lastName);
  formData.append('email', emailAddress);
  formData.append('message', message);
  const endpoint = `${Globals.apiUrl}/contact`;
  return async (dispatch) => {
    dispatch({ // @TODO: if this fails, need to save this somewhere to retry again when we're back online (or at least alert user)
      [RSAA]: {
        endpoint,
        method: POST,
        body: formData,
        headers: await getRequestHeaders(true),
        types: getFetchTypes(endpoint, ''),
      },
    });
  };
}

export function fetchWhatsNew() {
  const endpoint = `${Globals.apiUrl}/whats_new`;
  return async (dispatch) => {
    dispatch({
      [RSAA]: {
        endpoint,
        method: GET,
        headers: await getRequestHeaders(),
        types: getFetchTypes(endpoint, SET_WHATS_NEW_NOTES),
      },
    });
  };
}

export function setFeedbackChatDraftText(feedbackChatDraftText) {
  return {
    type: SET_FEEDBACK_DRAFT_TEXT,
    feedbackChatDraftText,
  };
}

export function saveFcmToken(fcmToken, deviceId, platform) {
  const ACTION_CREATOR_NAME = 'saveFcmToken';
  const formData = new FormData();
  formData.append('fcm_token', fcmToken);
  formData.append('device_id', deviceId);
  formData.append('platform', platform);
  const endpoint = `${Globals.apiUrl}/user/fcm_tokens`;
  return async (dispatch) => {
    dispatch(updateLoadingState(ACTION_CREATOR_NAME, true));
    await dispatch({
      [RSAA]: {
        endpoint,
        method: PUT,
        body: formData,
        headers: await getRequestHeaders(),
        types: getFetchTypes(endpoint, ''),
      },
    });
    dispatch(updateLoadingState(ACTION_CREATOR_NAME, false));
  };
}

export function saveInfoToRedux(info) {
  return {
    type: SET_SIGN_IN_INFO,
    email: info.email,
    phoneNumber: info.phoneNumber,
    firstName: info.firstName,
    lastName: info.lastName,
  };
}

export function setLightDarkMode(lightDarkMode) {
  return {
    type: SET_LIGHT_DARK_MODE,
    lightDarkMode,
  };
}

export function apiDeleteAccount() {
  const endpoint = `${Globals.apiUrl}/delete_account`; // @TODO: make sure this works for wrinkly and tibbits both
  return async (dispatch) => {
    await dispatch({
      [RSAA]: {
        endpoint,
        method: POST,
        headers: await getRequestHeaders(),
        types: getFetchTypes(endpoint, ''),
      },
    });
    dispatch(logout());
  };
}
