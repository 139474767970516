/*
 * Ryan O'Dowd
 * 2022-02-27
 *
 * © Copyright 2022 Oakwood Software Consulting, Inc.  All Rights Reserved.
 */
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
} from 'react-router-dom';
import React, {
  useEffect,
  useState,
} from 'react';
import {
  fetchI18n,
  fetchMyClassroomsTeacher,
  fetchOrganizationCampaigns,
  fetchOrganizations,
  // @TODO: fetchParticipantCampaigns,
  fetchUser,
} from '../../actions';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import Admin from '../Admin';
import Articles from '../Articles';
import Classroom from '../Classroom';
import ClassroomGoal from '../ClassroomGoal';
import Fundraising from '../Fundraising';
import Help from '../../containers/Help';
import I18n from '../I18n';
import LandingPage from '../LandingPage';
import MasqueradeIndicator from '../../components/MasqueradeIndicator';
import MyCampaigns from '../MyCampaigns';
// @TODO: import Old from '../Old';
import Organization from '../Organization';
import PropTypes from 'prop-types';
// @TODO: import School from '../School';
import Schools from '../Schools';
import YearInReview from '../YearInReview';
import {
  firebase,
} from '../../Globals';
import utilities from '../../utilities';

const PrivateRoute = (props) => {
  const user = useSelector((state) => state.oakUser);

  if (!user) {
    return (
      <Navigate
        to={props.unauthenticatedRedirectUrl || '/'}
        state={{
          isAuthOpen: true,
          redirectUrl: location.pathname,
        }}
      />
    );
  }

  return props.component;
};

PrivateRoute.propTypes = {
  component: PropTypes.object.isRequired,

  unauthenticatedRedirectUrl: PropTypes.string,
};

const App = () => {
  const dispatch = useDispatch();

  const isAdmin = utilities.useIsAdmin();

  const [_authChecked, setAuthChecked] = useState(false);

  useEffect(() => {
    const authUnsubscribe = firebase.auth().onAuthStateChanged(async (authUser) => {
      setAuthChecked(true);
      if (authUser) {
        dispatch(fetchUser());
        // @TODO: dispatch(fetchParticipantCampaigns()); // @TODO: don't think this is needed on web
        dispatch(fetchOrganizationCampaigns());
        dispatch(fetchOrganizations());
        dispatch(fetchMyClassroomsTeacher());
        // @TODO: redirect to page they were trying to log into?
        // @TODO: firebase.crashlytics().setUserId(authUser.uid);
      }

      dispatch(fetchI18n('en', true));

      return () => authUnsubscribe();
    });
  }, [dispatch]);

  if (!_authChecked) {
    // @TODO: this could be better...maybe a spinner. it's here so that no components are loaded until auth is determined; still don't like that there's a delay caused by returning null here
    return null;
  }

  return (
    <React.Fragment>
      <MasqueradeIndicator />
      <BrowserRouter>
        <Routes>
          <Route path='/finish-email-sign-in' element={<LandingPage />} />
          <Route path='/help' element={<Help />} />
          <Route path='/fundraising' element={<Fundraising />} />
          <Route path='/schools' element={<Schools />} />
          <Route path='/articles' element={<Articles />} />
          <Route path='/my-year/:token' element={<YearInReview />} />
          {/* @TODO:
          <Route path='/schools/:schoolId' element={<School />} />{/* @TODO: maybe go back to slugs intead of db ids? /}
          */}
          <Route path='/schools/:schoolId/classrooms/:classroomId' element={<PrivateRoute component={<Classroom />} />} />
          <Route path='/schools/:schoolId/classrooms/:classroomId/classroom_goals/:classroomGoalId' element={<PrivateRoute component={<ClassroomGoal />} />} />
          <Route path='/organizations/:organizationSlug' element={<Organization />} />
          <Route path='/campaigns' element={<PrivateRoute component={<MyCampaigns />} />}>
            <Route path=':campaignSlug' element={<MyCampaigns />} />
          </Route>
          <Route path='/i18n/:localeCode' element={<I18n />} />
          <Route path='/i18n' element={<Navigate to='/i18n/es' />} />
          {/* @TODO:
          <Route path='/old' element={<Old />} />
          */}
          {isAdmin && <Route path='/admin' element={<Admin />} />}
          <Route path='/' element={<LandingPage />} />
          <Route path='*' element={<Navigate to='/' />} />
        </Routes>
      </BrowserRouter>
    </React.Fragment>
  );
};

export default App;
