/*
 * Ryan O'Dowd
 * 2024-04-14
 * © Copyright 2024 Oakwood Software Consulting, Inc.  All Rights Reserved.
 */
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  TextField,
} from '@mui/material';
import React, {
  useEffect,
  useState,
} from 'react';
import {
  addNewPassagesOfTheDay,
  fetchAllPassagesOfTheDay,
  fetchBookNames,
  fetchBooks,
} from '../../../actions';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import CPassage from '../../../classes/Passage';
import Globals from '../../../Globals';
import OakTable from '../../../common/components/OakTable';
import PassageDisplay from '../../../components/PassageDisplay';
import {
  format as dateFnsFormat,
} from 'date-fns';
import styles from './styles';
import utilities from '../../../utilities';

const PassagesOfTheDayTable = () => {
  const dispatch = useDispatch();

  const [_isAddPassageDialogOpen, setIsAddPassageDialogOpen] = useState(false);
  const [_showAll, setShowAll] = useState(false);
  const [_passagesText, setPassageText] = useState('');
  const [_hasShownWarning, setHasShownWarning] = useState(false);

  useEffect(() => {
    dispatch(fetchBooks());
    dispatch(fetchBookNames());
    dispatch(fetchAllPassagesOfTheDay());
  }, [dispatch]);

  const passagesOfTheDay = useSelector((state) => state.allPassagesOfTheDay);
  const tableRows = Object.values(passagesOfTheDay).filter((p) => _showAll ? true : new Date(p.date_active) >= new Date()).map((p) => {
    const passage = new CPassage(-1, 3, p.book_id, p.chapter_number, p.start_verse_number, p.end_verse_number);

    return [
      {id: `${p.id}_date_active`, content: dateFnsFormat(new Date(p.date_active), 'MMMM d, yyyy')},
      {id: `${p.id}_passage`, content: utilities.prettyPassageName(new CPassage(-1, 3, p.book_id, p.chapter_number, p.start_verse_number, p.end_verse_number))},
      {
        id: `${p.id}_passage_text`,
        content: (
          <PassageDisplay
            passages={[passage]}
            hideAddToGoal={true}
            hideTest={true}
            hideLesson={true}
            hideLicensing={true}
            showFormatting={false}
            showFootnotes={false}
            showHeadings={false}
            doesTranslationAffectWrinkleStatus={false}
            verseNumbersOnly={false}
            alwaysShowSelectionMenu={false}
            showWrinkleBadges={false}
            isPressDisabled={true}
          />
        ),
      },
    ];
  });

  if (!_hasShownWarning && tableRows.length <= 14) {
    window.alert(`Please add more Passages Of The Day.  Only ${tableRows.length} remaining.`);
    setHasShownWarning(true);
  }

  return (
    <div style={styles.container}>
      <div style={styles.sectionWrapper}>
        <FormControlLabel
          control={
            <Checkbox
              checked={_showAll}
              onChange={(e) => setShowAll(e.target.checked)}
              sx={{
                color: Globals.colors.primaryExtraLight,
                '&.Mui-checked': {
                  color: Globals.colors.accent,
                },
              }}
            />
          }
          label='Show all'
        />
      </div>
      <OakTable
        tableTitle='Passages'
        open={true}
        headerRowData={[
          'Date',
          'Passage reference',
          'Passage text',
        ]}
        rowData={tableRows}// @TODO: shouldn't be clickable (or if they are, make individually updatable)
        filtersJsx={
          <Button
            variant='contained'
            size='small'
            color='secondary'
            onClick={() => setIsAddPassageDialogOpen(true)}
          >
            Add passages
          </Button>
        }
      />

      <Dialog
        open={_isAddPassageDialogOpen}
        style={styles.dialog}
        aria-labelledby='alert-dialog-title-todo'
        aria-describedby='alert-dialog-description-todo'
        fullScreen={Globals.isMobile}
        onClose={() => setIsAddPassageDialogOpen(false)}
      >
        <DialogTitle id='alert-dialog-title-todo'>
          Add passages
        </DialogTitle>
        <DialogContent style={styles.dialogContent}>
          <TextField
            value={_passagesText}
            onChange={(e) => setPassageText(e.target.value)}
            placeholder=''
            fullWidth
            label='Passages'
            variant='standard'
            helperText='Comma-separated: e.g. `John 3:16,Romans 8:9-10`'
          />
        </DialogContent>
        <DialogActions>
          <Button
            color='primary'
            variant='text'
            autoFocus={false}
            onClick={() => setIsAddPassageDialogOpen(false)}
          >
            Close
          </Button>
          <Button
            color='secondary'
            variant='contained'
            onClick={() => {
              // @TODO: form validation
              dispatch(addNewPassagesOfTheDay(_passagesText));
              setIsAddPassageDialogOpen(false);
              setPassageText('');
            }}
          >
            Save passages
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default PassagesOfTheDayTable;
