/**
 * Ryan O'Dowd
 * 2022-12-19
 * © Copyright 2022 Oakwood Software Consulting, Inc. All Rights Reserved.
 */
import {
  store,
} from '../Store';
import utilities from '../utilities';

export default class Passage { // @TODO: throw error if attribute/method doesn't exist
  constructor(id, translationId, bookId, chapterNumber, startVerseNumber, endVerseNumber) { // @TODO: can there be another constructor sofr passing in a passage object (from the back end)?
    this.id = +id;
    this.translationId = +translationId;
    this.bookId = +bookId;
    this.chapterNumber = chapterNumber && +chapterNumber;
    this.startVerseNumber = startVerseNumber && +startVerseNumber;
    this.endVerseNumber = startVerseNumber && +endVerseNumber;

    this.translation_id = +translationId; // @TODO: better casing fix than this duplication
    this.book_id = +bookId; // @TODO: better casing fix than this duplication
    this.chapter_number = chapterNumber && +chapterNumber; // @TODO: better casing fix than this duplication
    this.start_verse_number = startVerseNumber && +startVerseNumber; // @TODO: better casing fix than this duplication
    this.end_verse_number = startVerseNumber && +endVerseNumber; // @TODO: better casing fix than this duplication
  }

  static createFromObject(p) {
    // useful for transforming passages sent from the back end into this javascript class object
    return new Passage(
      p.id,
      p.translation_id || p.translationId,
      p.book_id || p.bookId,
      p.chapter_number || p.chapterNumber,
      p.start_verse_number || p.startVerseNumber,
      p.end_verse_number || p.endVerseNumber,
    );
  }

  clone() {
    return new Passage(
      this.id,
      this.translationId,
      this.bookId,
      this.chapterNumber,
      this.startVerseNumber,
      this.endVerseNumber,
    );
  }

  /* @TODO: // @TODO: can i remove this?
  getVerses() {
    const verses = [];
    for (let verseNum = this.startVerseNumber; verseNum <= this.endVerseNumber; verseNum++) {
      const verse = store.Bibles?.[this.translationId]?.books?.[this.bookId]?.verses?.[verseNum];
      if (verse === undefined || verse === null) { // @TODO: is this sufficient?
        // @TODO: store.dispatch(fetchVerses(this.translationId, this.bookId, verseNum));
      }

      verses.push(verse);
    }

    return verses;
  }
  */

  prettyPassageName() {
    const verses = !this.startVerseNumber ? '' : `${this.startVerseNumber}${(this.endVerseNumber && this.startVerseNumber !== this.endVerseNumber) ? `-${this.endVerseNumber}` : ''}`;
    return `${utilities.getBookName(this.translationId, this.bookId)}${this.chapterNumber ? ` ${this.chapterNumber}` : ''}${verses ? `:${verses}` : ''}`;
  }

  getTranslation() {
    return utilities.getTranslation(this.translationId);
  }

  abbreviatedPassageName() {
    return `${utilities.getBookName(this.translationId, this.bookId).replace(' ', '').slice(0, 3)} ${this.chapterNumber}:${this.startVerseNumber}${this.startVerseNumber !== this.endVerseNumber ? `-${this.endVerseNumber}` : ''}`;
  }

  getBook() {
    return store.getState().books.find((b) => b.id === this.book_id);
  }

  getChapter() {
    return store.getState().chapters.find((c) => c.book_id === this.book_id && c.chapter_number === this.chapter_number);
  }

  isValid() { // @TODO: should set error message in here so user knows why it's invalid
    // @TODO: check to make sure the reference exists (too high of a chapter/verse number)
    // @TODO: start_verse_number should be before or equal to end_verse_number
    // @TODO: amke sure this isn't an empty verse (e.g. Matthew 18:11)
    return this.translationId && this.bookId && this.chapterNumber && this.startVerseNumber && this.endVerseNumber;
  }

  getWrinkles() { // @TODO: memoize?
    /* @TODO: can this be a hook so that it's reactive?
     * see note in VerseMemory.js: `const _w = useSelector((state) => state.wrinkles); // @TODO: this is hacky...only listening to this to trigger a re-render of this component when wrinkles change (because the passage prop doesn't change, there's no re-render after successfully leveling up a verse...can't repro on simulator)`
     */
    const passageWrinkles = [];
    const globalStore = store.getState();
    const book = globalStore.books.find((b) => b.id === this.book_id);
    for (let verseNum = this.start_verse_number; verseNum <= this.end_verse_number; verseNum++) {
      const translationWrinkle = (globalStore.wrinkles[utilities.getVerseApiId(book.api_id, this.chapter_number, verseNum)]?.filter((w) => w.translation_id === this.translation_id) || []).pop();
      if (translationWrinkle) {
        passageWrinkles.push(translationWrinkle);
      }
    }
    return passageWrinkles;
  }
}
