/**
 * Ryan O'Dowd
 * 2024-10-02
 * © Copyright 2024 Oakwood Software Consulting, Inc. All Rights Reserved.
 */
import Globals from '../../../Globals';

// @TODO: remove unused
const styles = {
  headerText: { // @TODO: abstract with edit dialogs
    display: 'flex',
    fontSize: 16,
    fontFamily: Globals.fontFamilies.secondary,
    fontWeight: 'bold',
    color: Globals.colors.primaryDark,
  },
  nameWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 8,
    marginLeft: 32, // @TODO: abstract with edit dialogs
    marginRight: 32, // @TODO: abstract with edit dialogs
  },
  translationWrapper: { // @TODO: abstract with nameWrapper
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 8,
    marginLeft: 32, // @TODO: abstract with edit dialogs
    marginRight: 32, // @TODO: abstract with edit dialogs
  },
  publishedStatusWrapper: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 32,
  },

  adminOptionsWrapper: {
    borderStyle: 'solid',
    borderWidth: 3,
    borderColor: Globals.colors.primaryDark,
    borderRadius: 8,
    backgroundColor: Globals.colors.primaryExtraLight,
    padding: 12,
  },
  adminOptionsInner: {
    padding: 12,
  },
};

export default styles;
