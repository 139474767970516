/*
 * Ryan O'Dowd
 * 2024-10-02
 * © Copyright 2024 Oakwood Software Consulting, Inc. All Rights Reserved.
 */
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material';
import React, {
  useState,
} from 'react';
import {
  adminAddSchool,
  adminUpdateSchool,
} from '../../../actions';
import PropTypes from 'prop-types';
import styles from './styles';
import {
  useDispatch,
} from 'react-redux';

const NewOrEditSchoolDialog = (props) => {
  const dispatch = useDispatch();

  const [_schoolName, setSchoolName] = useState(props.school?.name || '');

  return (
    <Dialog
      style={styles.container}
      maxWidth='lg'
      open={true}
      onClose={() => props.onClose()} // @TODO: ask about unsaved changes
      disableRestoreFocus={true}// https://github.com/mui/material-ui/issues/33004#issuecomment-1473299089
    >
      <DialogTitle id='alert-dialog-title'>
        <span style={styles.mainTitle}>Edit goal</span>
      </DialogTitle>
      <DialogContent style={styles.innerContainer}>
        <span style={styles.headerText}>School name</span>
        <span style={styles.nameWrapper}>
          <TextField
            value={_schoolName}
            autoFocus={true}
            fullWidth
            onChange={(e) => setSchoolName(e.target.value)}
            placeholder=''
            label='School name'
            variant='standard'
          />
        </span>
      </DialogContent>
      <DialogActions>
        <Button
          color='primary'// @TODO: dangerous?
          onClick={() => props.onClose()}
        >
          Cancel
        </Button>
        <Button
          color='primary'
          variant='contained'
          onClick={() => {
            if (props.school) {
              dispatch(adminUpdateSchool(props.school.id, _schoolName, props.onClose));
            } else {
              dispatch(adminAddSchool(_schoolName, props.onClose));
            }
          }}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

NewOrEditSchoolDialog.propTypes = {
  onClose: PropTypes.func.isRequired,

  school: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }),
};

export default NewOrEditSchoolDialog;
