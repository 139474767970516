/**
 * Ryan O'Dowd
 * 2024-02-08
 * © Copyright 2024 Oakwood Software Consulting, Inc. All Rights Reserved.
 */
import PropTypes from 'prop-types';
import React from 'react';
import Text from '../../../../common/components/OakText';
import styles from './styles';

const Lecture = (props) => {

  return (
    <div style={styles.container}>
      <Text style={styles.lectureText}>{props.text}</Text>{/* @TODO: needs to be editable */}{/* @TODO: doesn't honor newlines */}
    </div>
  );
};

Lecture.propTypes = {
  id: PropTypes.number.isRequired,
  created_at: PropTypes.string.isRequired,
  updated_at: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired, // @TODO: node? should allow for html or other markup
};

export default Lecture;
