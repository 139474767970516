/**
 * Ryan O'Dowd
 * 2021-12-21
 * © Copyright 2021 Oakwood Software Consulting, Inc. All Rights Reserved.
 */
import React, {
  useState,
} from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import ReactDOMServer from 'react-dom/server';
import TextField from '@mui/material/TextField';
import WebPageStub from '../WebPageStub';
import faqs from './faqs';
import queryString from 'query-string';
import styles from './styles';

const Help = (props) => {
  const queryParams = queryString.parse(window.location.search, {ignoreQueryPrefix: true});

  const [_searchText, setSearchText] = useState(queryParams.q || '');

  // @TODO: const oakUser = useSelector((state) => state.oakUser);

  let filteredSections = faqs(null, true, null, null, null, 'div', 'p', 'span', 'span').sections.filter((section) => !section.ignoreInFaq);
  if (_searchText) {
    filteredSections = [];
    const searchTerms = _searchText.toLocaleLowerCase().split(' ');
    faqs(null, true, null, null, null, 'div', 'p', 'span', 'span').sections.filter((section) => !section.ignoreInFaq).forEach((section) => { // @TODO: combine logic with native code (custom hook for useFaqSearch??)
      const filteredSection = {...section};
      filteredSection.data = filteredSection.data.filter((faq) => {
        return searchTerms.every((searchTerm) => {
          if (filteredSection.title.toLocaleLowerCase().includes(searchTerm)) {
            return true;
          }

          const questionText = ReactDOMServer.renderToStaticMarkup(faq.question); // @TODO: getting tons of warnings here...
          const answerText = ReactDOMServer.renderToStaticMarkup(faq.answer); // @TODO: getting tons of warnings here...
          if (questionText.toLocaleLowerCase().includes(searchTerm)) {
            return true;
          }

          if (answerText.toLocaleLowerCase().includes(searchTerm)) {
            return true;
          }

          return false;
        });
      });
      if (filteredSection.data.length) {
        filteredSections.push(filteredSection);
      }
    });
  }

  return (
    <WebPageStub>
      <div style={styles.innerContainer}>
        <div style={styles.innerWithPadding}>
          <div style={styles.searchBarWrapperWeb}>{/* @TODO: abstract with faqs */}
            <TextField
              style={styles.searchBarWeb}
              value={_searchText}
              onChange={(e) => {
                // @TODO: this should work but is causing refresh: window.location.search = '';
                setSearchText(e.target.value);
              }}
              placeholder=''
              fullWidth
              autoFocus={true}
              autoCapitalize='none'
              label='Search all FAQs'
              variant='standard'
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='clear search text'
                      onClick={() => {
                        // @TODO: this should work but is causing refresh: window.location.search = '';
                        setSearchText('');
                      }}
                      edge='end'
                    >
                      <CloseIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>
          {!filteredSections.length &&
            <p style={styles.emptyScreenText}>{/* @TODO: move to commonStyles */}
              {`No search results for "${_searchText}"`}
            </p>
          }
          {filteredSections.map((section) => {
            return (
              <React.Fragment key={section.title}>
                <span id={section.title.toLocaleLowerCase().replace(' ', '_')} style={styles.sectionHeader}>
                  {section.title}
                </span>
                {section.data.map((faq) => {
                  return (
                    <Accordion key={faq.question} style={styles.faqWrapper}>
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <span style={styles.question}>
                          {faq.question}
                        </span>
                      </AccordionSummary>
                      <AccordionDetails>
                        <span style={styles.faqBody}>
                          {faq.answer}
                        </span>
                      </AccordionDetails>
                    </Accordion>
                  );
                })}
              </React.Fragment>
            );
          })}
        </div>
      </div>
    </WebPageStub>
  );
};

Help.propTypes = {};

export default Help;
