/*
 * Ryan O'Dowd
 * 2024-10-05
 * © Copyright 2024 Oakwood Software Consulting, Inc.  All Rights Reserved.
 */
import Globals from '../../Globals';

const BORDER_THICKNESS = 12;
const PADDING = BORDER_THICKNESS / 2;

const styles = {
  container: {
    position: 'fixed',
    width: `calc(100vw - ${BORDER_THICKNESS * 2}px)`,
    height: `calc(100vh - ${BORDER_THICKNESS * 2}px)`,
    borderStyle: 'solid',
    borderWidth: BORDER_THICKNESS,
    borderColor: Globals.colors.accent,
    zIndex: 100,
    opacity: 0.8,
    pointerEvents: 'none',
  },
  bottomSection: {
    position: 'absolute',
    bottom: 0,
    display: 'flex',
    width: `calc(100% - ${PADDING * 2}px)`,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: Globals.colors.accent,
    paddingTop: BORDER_THICKNESS,
    paddingRight: PADDING,
    paddingBottom: 0,
    paddingLeft: PADDING,
    pointerEvents: 'initial',
  },
  masqueradeText: {
    flexGrow: 1,
    color: Globals.colors.primaryExtraDark,
    fontFamily: Globals.fontFamilies.secondary,
  },
  name: {
    fontWeight: 'bold',
    marginLeft: 8,
  },
  stopButton: {
    backgroundColor: Globals.colors.primaryExtraDark,
    color: Globals.colors.primaryExtraLight,
    /* @TODO: move this to OakButton/index.web.js and styles.web.js...it's cool but doesn't fit here.  will have to update colors there
    '&:hover': {
      backgroundColor: Globals.colors.primaryExtraLight,
      color: Globals.colors.primaryExtraDark,
    },
    */
  },
};

export default styles;
