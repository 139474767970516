/*
 * Ryan O'Dowd
 * 2024-10-05
 * © Copyright 2024 Oakwood Software Consulting, Inc.  All Rights Reserved.
 */
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  Button,
} from '@mui/material';
import React from 'react';
import {
  stopMasquerading,
} from '../../actions';
import styles from './styles';

const MasqueradeIndicator = () => {
  const dispatch = useDispatch();

  const userMasqueradingAs = useSelector((state) => state.userMasqueradingAs);

  if (!userMasqueradingAs) {
    return null;
  }

  return (
    <div style={styles.container}>
      <div style={styles.bottomSection}>
        <span style={styles.masqueradeText}>Masquerading as: <span style={styles.name}>{userMasqueradingAs.display_name}</span></span>
        <Button
          color='primary'
          variant='contained'
          autoFocus={false}
          onClick={() => dispatch(stopMasquerading())}
          sx={styles.stopButton}
        >
          Stop masquerading
        </Button>
      </div>
    </div>
  );
};

export default MasqueradeIndicator;
