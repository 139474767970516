/**
 * Ryan O'Dowd
 * 2021-01-03
 * © Copyright 2020 Oakwood Software Consulting, Inc. All Rights Reserved.
 */
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import DownloadLinks from '../../components/DownloadLinks';
import Globals from '../../Globals';
// @TODO: import LinearGradient from 'react-native-linear-gradient';
import PropTypes from 'prop-types';
import React from 'react';
import {
  format as dateFnsFormat,
} from 'date-fns';
// @TODO: import {
// @TODO:   ALL_APPS,
// @TODO: } from './Globals';
import styles from './styles';
import {
  useSelector,
} from 'react-redux';
import utilities from '../../utilities';

const About = (props) => {
  const i18n = utilities.useI18n();

  const msSpent = useSelector((state) => state.webStatsMsSpent);
  const versesReviewed = useSelector((state) => state.webStatsNumVersesReviewed);

  /* @TODO:
  const _getShareOptions = () => {
    return [
      {
        name: 'Share',
        onPress: () => {
          // @TODO: analytics
          // @TODO: update text (and copy to oakwoodsc.com too)...put in Globals.js
          /* @TODO:
          Share.share({
            message: i18n('shareOptionsMessage'),
            url: @TODO,
            title: i18n('shareOptionsTitle'),
          }, {
            // Android only:
            dialogTitle: i18n('shareWrinkly'),
          });
          /
        },
        iconName: 'share',
      },
      {
        name: 'Copy Link',
        onPress: () => {
          // @TODO: indicate that the text is copied (toast or something...)
          // @TODO: analytics
          // @TODO: Clipboard.setString(storeLink);
        },
        iconName: 'link-variant',
      },
      {
        name: 'Rate',
        onPress: () => {
          // @TODO: Linking.openURL(storeLink);
        },
        // @TODO: analytics
        iconName: 'star',
        color: '#FFC125',
      },
    ];
  };
  */

  /* @TODO: abstract share and explore to remove duplicate code
  const shareButtons = _getShareOptions().map((shareOption) => {
    return (
      <React.Fragment key={shareOption.name}>
        {shareOption.verticalDividerBefore && <span style={styles.verticalDivider} />}
        <span style={styles.buttonAndTextWrapper}>
          {/* @TODO:
          <IconButton
            style={styles.buttonImageWrapper}
            onClick={shareOption.onPress}
          >
            {/ @TODO: share via facebook, twitter, etc. but just copy platform-agnostic url to clipboard so they can share manually (have message about how we don't want to spam) /}
            {shareOption.image
              ? (
                <Image
                  style={styles.buttonImage}
                  resizeMode='cover'
                  source={shareOption.image}
                />
              ) : (
                <MaterialCommunityIcon
                  style={[styles.buttonImage, styles.buttonIcon, shareOption.color && {color: shareOption.color}]}
                  name={shareOption.iconName}
                />
              )
            }
          </IconButton>
          /}
          <p
            style={styles.buttonNameText}
            numberOfLines={1}
          >
            {shareOption.name}
          </p>
        </span>
        {shareOption.verticalDividerAfter && <span style={styles.verticalDivider} />}
      </React.Fragment>
    );
  });
  */

  /* @TODO:
  const otherAppButtons = ALL_APPS('web').map((app) => {
    let buttonChildren = null;
    if (!app.isEmpty) {
      buttonChildren = (app.image
        ? (
          <Image
            style={styles.buttonImage}
            resizeMode='cover'
            source={app.image}
          />
        ) : (
          <MaterialCommunityIcon
            style={[styles.buttonImage, styles.buttonIcon]}
            name={app.iconName}
          />
        )
      );
    }

    return (
      <React.Fragment key={app.name}>
        {app.verticalDividerBefore && <span style={styles.verticalDivider} />}
        <span style={[styles.buttonAndTextWrapper, app.isEmpty && styles.buttonAndTextWrapperEmpty]}>
          {/* @TODO:
          <TouchableOpacity
            style={styles.buttonImageWrapper}
            onPress={() => {/* @TODO: !app.isEmpty && Linking.openURL(app.link) /}}
          >
            {buttonChildren}
          </TouchableOpacity>
          <p
            style={styles.buttonNameText}
            numberOfLines={1}
          >
            {!app.isEmpty && app.name}
          </p>
          /}
        </span>
        {app.verticalDividerAfter && <span style={styles.verticalDivider} />}
      </React.Fragment>
    );
  });
  */

  return (
    <Dialog
      open={true}
      style={styles.dialog}
      aria-labelledby='alert-dialog-title-todo'
      aria-describedby='alert-dialog-description-todo'
      fullScreen={Globals.isMobile}
      onClose={() => props.onClose()}
      maxWidth='md'
    >
      <DialogTitle id='alert-dialog-title-todo'>
        {i18n('aboutUs')}
      </DialogTitle>
      <DialogContent>
        {/* @TODO: faqs/tutorial */}
        <div style={styles.aboutContainer}>
          <h3 style={{...styles.headerText, ...styles.firstHeader}}>{i18n('whoWeAre')}</h3>
          <div style={styles.imageTextWrapper}>
            <span>
              <img src='/img/oakwood_logo.png' alt='oakwood logo' style={{...styles.logo, ...styles.rightBorder}} />
              {i18n('whoWeAreBody')}
            </span>
          </div>
        </div>

        <div style={styles.aboutContainer}>
          <h3 style={{...styles.headerText, ...styles.firstHeader}}>{i18n('originStoryTitle')}</h3>
          <div style={styles.imageTextWrapper}>
            <span style={styles.foo}>
              <img src='/img/wrinkly_logo.png' alt='wrinkly logo' style={{...styles.logo, ...styles.leftBorder}} />
              {i18n('originStoryBody')}
            </span>
          </div>
        </div>

        <div style={styles.aboutContainer}>
          <h3 style={{...styles.headerText, ...styles.firstHeader}}>Mission and Vision</h3>
          <span><span style={styles.accentTitle}>Mission: </span>Increase engagement with God’s Word through memorization.</span>
          <span><span style={styles.accentTitle}>Vision: </span>Improve Bible access, literacy, and fluency with intentionality, joy, and excellence.</span>
          <br />
        </div>

        <div style={styles.aboutContainer}>
          <h3 style={{...styles.headerText, ...styles.firstHeader}}>{i18n('impact')}</h3>
          {i18n(
            'impactBody',
            <span style={styles.statText}>{utilities.humanReadableTime(msSpent)}</span>,
            <span style={styles.statText}>{utilities.humanReadableNumber(versesReviewed)} {i18n('verses').toLocaleLowerCase()}</span>,
          )}
        </div>

        <div style={styles.aboutContainer}>
          <h3 style={styles.headerText}>{i18n('support')}</h3>
          <p style={styles.aboutText}>{i18n('supportBodyOne')}</p>
          <p style={styles.aboutText}>{i18n('supportBodyTwo', 'App Store or Play Store')}</p>{/* @TODO: i18n "App Store or Play Store" */}
        </div>

        <div style={styles.aboutContainer}>
          <h3 style={styles.headerText}>{i18n('wrinklyMobileDownloads')}</h3>
          <DownloadLinks />
        </div>

        {/* @TODO:
        <div>
          <h3 style={styles.headerText}>Share</h3>
          <div
            style={styles.actions}
            horizontal={true}
          >
            {/* @TODO: shouldn't ahve to specify width here... /}
            {/* @TODO: implement
            <div style={{width: 3 * 68, flexDirection: 'row'}}>
              {shareButtons}
            </div>
            /}
          </div>
        </div>
        */}

        {/* @TODO: add "tip the developers" section */}

        <h3 style={styles.headerText}>{i18n('info')}</h3>
        <div style={styles.otherInfoWrapper}>
          <span>{i18n('appVersion')}: {Globals.appVersionNumber}</span>
          <span>{'\u00A9'} {dateFnsFormat(new Date(), 'yyyy', {locale: i18n('locale')})} Oakwood Software Consulting, Inc.</span>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          color='primary'
          variant='contained'
          autoFocus={false}
          onClick={() => props.onClose()}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

About.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default About;
