/*
 * Ryan O'Dowd
 * 2022-08-09
 * © Copyright 2022 Oakwood Software Consulting, Inc.  All Rights Reserved.
 */
import Globals from '../../Globals';

const styles = {
  outerContainer: { // @TODO: abstract with home
    flex: 1,
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'left',
    backgroundColor: Globals.colors.white,
    color: Globals.colors.primaryExtraDark,
  },
  tabsWrapper: {
    backgroundColor: Globals.colors.primaryExtraDark,
  },
  tab: {
    color: Globals.colors.primaryExtraLight,
  },
  container: {
    flex: 1,
    width: '100%',
    minHeight: 'calc(100vh - 56px - 35px)', // @TODO: header and footer heights; check mobile; don't hardcode
    justifyContent: 'center',
    alignItems: 'center',
    margin: 'auto',
    // @TODO: marginBottom: 64,
  },
  imagesWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    justifyContent: 'space-around',
  },
  image: {
    borderRadius: 32,
    borderStyle: 'solid',
    borderWidth: 5,
    borderColor: Globals.colors.primaryLight,
    width: 205,
    margin: 16,
  },
  totalsHeader: {
    fontSize: 20,
    fontWeight: 'bold',
    color: Globals.colors.accentDark,
    marginBottom: 12,
  },
  totalsWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
    gap: 16,
  },
  statWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  statLabel: {
    color: Globals.colors.primaryExtraLight,
    marginRight: 8,
  },
  statText: {
    color: Globals.colors.accent,
    fontWeight: 'bold',
  },
  downloadLinks: {
    display: 'flex',
    justifyContent: 'flex-end',
  },

  tableWrapper: {
    padding: 8,
    margin: 8,
    color: Globals.colors.primaryExtraLight,
    backgroundImage: `linear-gradient(
      ${Globals.colors.primary} 0%,
      ${Globals.colors.primaryDark} 100%
    )`,
  },
  section: {
    display: 'flex',
    justifyContent: 'center',
    padding: 32,
  },
  titleAndAction: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  sectionInner: {
    width: '100%',
    maxWidth: 1000,
  },
  rightSection: {
    textAlign: 'right',
    backgroundImage: `linear-gradient(
      ${Globals.colors.primary} 0%,
      ${Globals.colors.primaryDark} 100%
    )`,
  },
  contactWrapper: {
    minWidth: '50%',
    flex: 1,
  },
  downloadCtaWrapper: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 32,
    paddingBottom: 100,
  },

  headerCell: {
    fontWeight: 'bold',
  },
  totalCell: {
    fontWeight: 'bold',
  },
  progressWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
  },
  progressBar: {
    width: 100,
  },
  infinityProgress: {
    fontSize: 20,
    color: Globals.colors.complete,
  },
  progressCell: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },

  chartSection: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    // @TODO: gradient not working in production
    backgroundImage: `linear-gradient(
      ${Globals.colors.primary} 0%,
      ${Globals.colors.primaryDark} 100%
    )`,
    marginBottom: 8,
  },
  chartInner: {
    color: Globals.colors.primaryExtraLight,
    backgroundColor: Globals.colors.white,
  },
  tickText: {
    fontSize: 14,
  },
  h3: {
    margin: 0,
  },
  chartWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 32,
    paddingBottom: 12,
    marginBottom: 32,
    // @TODO: elevation
  },
  chart: {
    backgroundColor: Globals.colors.primaryExtraDark,
    borderRadius: 4,
  },
  tooltip: {
    backgroundColor: Globals.colors.primaryExtraDark,
  },
  chartTooltip: {
    backgroundColor: Globals.colors.primaryDark,
    color: Globals.colors.primaryDark,
  },
  tooltipDate: {
    color: Globals.colors.white,
    backgroundColor: Globals.colors.primaryExtraDark,
  },

  // @TODO: abstract footer stuff
  footerWrapper: {
    backgroundColor: Globals.colors.primaryExtraDark,
    color: Globals.colors.white,
  },
  footerInner: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 8,
  },
  footerImage: {
    width: 32,
    height: 32,
    marginRight: 8,
  },
  footerButton: {
    marginLeft: 8,
  },

  newGoalFormWrapper: {
    backgroundColor: Globals.colors.white,
    padding: 16,
    color: Globals.colors.primaryExtraDark,
  },
};

export default styles;
