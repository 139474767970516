/**
 * Ryan O'Dowd
 * 2024-10-02
 * © Copyright 2024 Oakwood Software Consulting, Inc. All Rights Reserved.
 */
import Globals from '../../../Globals';

const styles = {
  headerText: { // @TODO: abstract with edit dialogs
    display: 'flex',
    fontSize: 16,
    fontFamily: Globals.fontFamilies.secondary,
    fontWeight: 'bold',
    color: Globals.colors.primaryDark,
  },
  fieldsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: 8,
    marginLeft: 32, // @TODO: abstract with edit dialogs
    marginRight: 32, // @TODO: abstract with edit dialogs
  },
  errorMessage: {
    color: Globals.colors.alert,
  },
};

export default styles;
